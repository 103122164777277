import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { faClone, faPencil } from '@fortawesome/free-solid-svg-icons';
import { BsTrashFill } from 'react-icons/bs';
import Swal from 'sweetalert2';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SortIcon from '../../components/SortIcon';
import { editCRMUser, deleteCRMUser } from '../../redux/crmUser/crmUserActions';
import { ModalFilters } from '../../components';
import {
  formatDate, prettyCutOutputByCellWidth, valueOutputCutLength, sortColumnByOrderConfig, 
} from '../helper';
import { TooltipComponent } from '../../components/TooltipComponent/TooltipComponent';
import { SortWrapper, useTableSorting } from '../../helpers/sortHelper';
import styles from './styles.module.css';

const SalesRoles = {
  1: 'Sales',
  2: 'Retention',
  3: 'Both',
};

export const CRMUsersSchema = ({
  fullname,
  searchEmail,
  agentRoles,
  rolesToShow, 
  setRolesToShow,
  crmUsersFilters, 
  setCrmUsersFilters,
  columnConfig,
  setFullname,
  setSearchEmail,
  socketIdToCRMUserId,
  handleSort, 
  salesRoleFilterPreset,
  setSalesRoleFilterPreset,
  ibdeskToShow,
  ibdeskFilterPreset,
  setIbdeskFilterPreset,
  idFilter,
  setIdFilter,
}) => {
  const dispatch = useDispatch();
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);

  const isUserCanBlockCrmUsers = permissionName && permissionName.includes('block_crm_user');
  const isUserCanEditCrmUsers = permissionName && permissionName.includes('edit_crm_user');
  const isUserCanDeleteCrmUsers = permissionName && permissionName.includes('delete_crm_user');

  const userAction = async (id, userActionType) => {
    Swal.fire({
      title: `Are you sure you want to ${userActionType && userActionType === 'block' ? 'block' : 'unblock'}?`,
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `${userActionType && userActionType === 'block' ? 'Block' : 'Unblock'}`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = { status: !(userActionType && userActionType === 'block') };
    
        return Promise.resolve(
          dispatch(editCRMUser(id, data, false, false)),
        )
          .then(
            () => {
              //   console.log('inisde');
              const loginData = localStorage.getItem('user');
              const udata = JSON.parse(loginData);
              const rid = udata?.roleId;
              const uid = udata?._id;
            },
          ).catch((error) => {
            // console.log(error, 'getSubCRMUsers');
          });
      }
      return null;
    });
  };

  const deleteAction = async (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed === true) {
        await dispatch(deleteCRMUser(id));
      }
    });
  };

  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };

  const omitColumn = (columnName) => {
    let omit = false;
  
    columnConfig.forEach((column) => {
      if (column.name === columnName) {
        omit = !column.selected;
      }
    });
  
    return omit;
  };

  const { sortFields, getSortIcon } = useTableSorting(handleSort);

  const columns = [
    {
      name: (
        <div className="d-flex align-items-start flex-column" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('customId')}>
            <span>User ID</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('customId')} />
            </button>
          </SortWrapper>
          <input
            type="text"
            name="id"
            autoComplete="off"
            value={idFilter || ''}
            placeholder="Search"
            className="email-templates-search-bar-filter"
            onChange={({ target: { value } }) => setIdFilter(value)}
          />
        </div>
      ),
      minWidth: '110px',
      omit: omitColumn('User ID'),
      label: 'User ID',
      reorder: true,
      cell: ({ customId }) => (
        <CopyToClipboard text={customId}>
          <span>
            <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className={styles.marginRight} />
            {customId}
          </span>
        </CopyToClipboard>
      ),
    },
    {
      name: (
        <div className={`d-flex align-items-start flex-column ${styles.wrapperInput}`}>
          <SortWrapper handleSort={() => sortFields('firstName lastName')}>
            <div className="d-flex flex-row">
              <span>Full Name</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('firstName lastName')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            name="fullname"
            autoComplete="off"
            value={fullname ?? ''}
            onChange={({ target: { value } }) => setFullname(value)}
          />
        </div>
      ),
      omit: omitColumn('Full Name'),
      label: 'Full Name',
      reorder: true,
      minWidth: '200px',
      cell: ({ firstName, lastName, _id }) => {
        const fullName = `${firstName} ${lastName}`;
        return (
          <div>
            {
              fullName.length >= valueOutputCutLength.fullName
                ? (
                  <TooltipComponent
                    title={fullName}
                    // eslint-disable-next-line react/no-children-prop
                    children={(
                      <Link to={`/edit-admin/${_id}`}>
                        <span data-tip={fullName} className="full-name-text">
                          { prettyCutOutputByCellWidth(fullName, 'fullName') }
                        </span>
                      </Link>
                    )}
                  />
                )
                : (
                  <Link to={`/edit-admin/${_id}`}>
                    <span data-tip={fullName} className="full-name-text">
                      {fullName}
                    </span>
                  </Link>
                )
            }
          </div>
        );
      },
    },
    {
      name: (
        <div className={`d-flex align-items-start flex-column ${styles.wrapperInput}`}>
          <SortWrapper handleSort={() => sortFields('email')}>
            <div className="d-flex flex-row">
              <span>Email</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('email')} />
              </button>
            </div>
          </SortWrapper>
          <input
            type="text"
            className="leads-search-bar-filter"
            placeholder="Search"
            name="email"
            autoComplete="off"
            value={searchEmail ?? ''}
            onChange={({ target: { value } }) => setSearchEmail(value)}
          />
        </div>
      ),
      label: 'Email',
      reorder: true,
      omit: omitColumn('Email'),
      minWidth: '185px',
      cell: ({ email }) => (
        email.length >= valueOutputCutLength.email 
          ? (
            <TooltipComponent
              title={email}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <div>
                  <CopyToClipboard text={email}>
                    <span style={{ margin: '5px', cursor: 'pointer', whiteSpace: 'nowrap' }} data-tip={email}>
                      <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                      {prettyCutOutputByCellWidth(email, 'email')}
                    </span>
                  </CopyToClipboard>
                </div>
                )}
            />
          )
          : (
            <CopyToClipboard text={email}>
              <span style={{ margin: '5px', cursor: 'pointer', whiteSpace: 'nowrap' }} data-tip={email}>
                <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                {email}
              </span>
            </CopyToClipboard>
          )
      ), 
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('userRole.name')}>
            <div className="d-flex flex-row">
              <span>Role</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('userRole.name')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Role" 
            data={agentRoles}
            filters={rolesToShow}
            setFilters={setRolesToShow}
            searchParams={{ id: '_id', params: ['_id'], optionsName: ['name'] }}
            usersFilters={crmUsersFilters}
            setUserFilters={setCrmUsersFilters}
          />
        </div>
      ),
      label: 'Role',
      reorder: true,
      minWidth: '110px',
      omit: omitColumn('Role'),
      cell: ({ userRole }) => {
        const userRoleName = userRole?.name;
        return (
          userRoleName.length >= valueOutputCutLength.userRole
            ? (
              <TooltipComponent
                title={userRoleName}
                // eslint-disable-next-line react/no-children-prop
                children={(
                  <span data-tip={userRoleName}>{userRoleName}</span>
              )}
              />
            )
            : (
              <span data-tip={userRoleName}>{userRoleName}</span>
            )
        );
      },
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('salesRole')}>
            <div className="d-flex flex-row">
              <span>Sales role</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('salesRole')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Sales Role"
            filterName="salesRole"
            data={Object.entries(SalesRoles).map(([key, value]) => ({ _id: key, name: value }))}
            filters={salesRoleFilterPreset}
            setFilters={setSalesRoleFilterPreset}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={crmUsersFilters}
            setUserFilters={setCrmUsersFilters}
          />
        </div>
      ),
      minWidth: '110px',
      label: 'Sales Role',
      reorder: true,
      omit: omitColumn('Sales Role'),
      cell: ({ salesRole }) => (<span data-tip={SalesRoles[salesRole]}>{SalesRoles[salesRole]}</span>),
    },
    {
      name: (
        <div className="d-flex flex-row search-input-des p-2 pl-0" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('ibdeskUser.fullName')}>
            <div className="d-flex flex-row">
              <span>Desk/IB</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('ibdeskUser.fullName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Desk/IB"
            filterName="ibdesk"
            data={ibdeskToShow}
            filters={ibdeskFilterPreset}
            setFilters={setIbdeskFilterPreset}
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={crmUsersFilters}
            setUserFilters={setCrmUsersFilters}
            addNonSelected
          />
        </div>
      ),
      minWidth: '170px',
      label: 'Desk/IB',
      reorder: true,
      omit: omitColumn('Desk/IB'),
      selector: (row) => (
        <div>
          {
              row?.uplineCRMUsers?.length ? (
                row.uplineCRMUsers.find((uplineCrmUser) => uplineCrmUser.role === 'IBDesk')?.fullName ?? '-'
              ) : '-'
            }
        </div>
      ),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('lastLoginAt')}>
          <div className="d-flex flex-row">
            <span>Last Online</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('lastLoginAt')} />
            </button>
          </div>
        </SortWrapper>
      ),
      label: 'Last Online',
      reorder: true,
      omit: omitColumn('Last Online'),
      minWidth: '176px',
      cell: ({ lastLoginAt }) => (
        <span style={{ margin: '5px' }} data-tip={lastLoginAt}>
          {lastLoginAt
            ? prettyCutOutputByCellWidth(formatDate(new Date(lastLoginAt)), 'lastLoginAt')
            : '-'}
        </span>  
      ),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('createdAt')}>
          <div className="d-flex flex-row">
            <span>Registration Date</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('createdAt')} />
            </button>
          </div>
        </SortWrapper>
      ),
      omit: omitColumn('Registration Date'),
      label: 'Registration Date',
      reorder: true,
      minWidth: '176px',
      cell: ({ createdAt }) => (
        <span style={{ margin: '5px' }} data-tip={createdAt}>
          {createdAt
            ? prettyCutOutputByCellWidth(formatDate(new Date(createdAt)), 'registerDate')
            : '-'}
        </span>
      ),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('lastLoginAt')}>
          <div className="d-flex flex-row">
            <span>Online</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('lastLoginAt')} />
            </button>
          </div>
        </SortWrapper>
      ),
      omit: omitColumn('Online'),
      label: 'Online',
      reorder: true,
      minWidth: '80px',
      cell: ({ _id }) => (
        Object.values(socketIdToCRMUserId).includes(_id)
          ? <span className="online">Online</span>
          : <span className="offline">Offline</span>
      ),
    },
    {
      name: 'Actions',
      minWidth: '240px',
      omit: omitColumn('Actions'),
      label: 'Actions',
      reorder: true,
      cell: ({ _id, status }) => (
        <>
          {isUserCanEditCrmUsers && (
          <Link to={`/edit-admin/${_id}`} className="btn btn-success btn-sm me-1 p-1">
            <FontAwesomeIcon icon={faPencil} />
          </Link>
          )}
          {isUserCanDeleteCrmUsers && (
          <button type="button" className="btn btn-danger btn-sm me-1 p-1" onClick={() => deleteAction(_id)}>
            <BsTrashFill role="button" size={18} />
          </button>
          )}
          {isUserCanBlockCrmUsers && (
            status
              ? <button type="button" className="btn btn-warning btn-sm me-1 p-1" onClick={() => userAction(_id, 'block')}>Block</button>
              : <button type="button" className="btn btn-warning btn-sm me-1 p-1" onClick={() => userAction(_id, 'unBlock')}>Unblock</button>
          )}
        </>
      ),
    },
  ];

  return sortColumnByOrderConfig(columns, columnConfig);
};
