import React from 'react';
import { useSelector } from 'react-redux';
import {
  startOfWeek, startOfMonth, endOfMonth, endOfWeek, isSameDay, 
} from 'date-fns';
import { monthData } from '../../helpers/calendar';
import styles from './styles.module.css';

export function MiniCalendar({ selectedDate, setSelectedDate }) {
  const currentDate = new Date();
    
  const start = startOfWeek(startOfMonth(selectedDate));
  const end = endOfWeek(endOfMonth(selectedDate));

  const events = useSelector((state) => state.events);
  
  const collectedMonthData = monthData(start, end, currentDate, selectedDate, events);
  const firstWeek = collectedMonthData[0] ?? [];

  return (
    <div className={styles.miniCalendar}>
      <div className={styles.calendarHeader}>
        {firstWeek.map(({ dayOfWeek }, key = 0) => <span key={key} className={styles.calendarHeaderCell}>{dayOfWeek}</span>)}
      </div>
      {monthData(start, end, currentDate, selectedDate, events).map((week, key = 0) => (
        <div className={styles.week} key={`week-${key}`}>
          {week.map((day, key = 0) => {
            const {
              isCurrentMonth, isToday, dayNumber, date, 
            } = day;
            return (
              <span 
                key={key}
                className={styles.day}
                style={{ 
                  opacity: isCurrentMonth ? '100%' : '20%',
                  backgroundColor: isSameDay(selectedDate, date) ? (isToday ? '#316AFF' : 'gray') : '', 
                  boxShadow: isToday ? 'rgba(0, 0, 0, 0.35) 0px 5px 15px' : '',
                }}
                onClick={() => setSelectedDate(date)}
              >
                {dayNumber}
              </span>
            );
          })}
        </div>
      ))}
    </div>
  );
};
