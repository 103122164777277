import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RefreshIcon from '@mui/icons-material/Refresh';
import FilterListIcon from '@mui/icons-material/FilterList';
import Analysis from './Analysis/Analysis';
import AffiliatesFTD from './AffiliatesFTD/AffiliatesFTD';
import FunnelFTD from './FunnelFTD/FunnelFTD';
import CountriesFTD from './CountriesFTD/CountriesFTD';
import StatusFTD from './StatusFTD/StatusFTD';
import styles from './AffiliatesDashboard.module.css';
import { selectStatusOption } from '../../redux/affiliatesDashboard/affiliatesDashboardSelectors';
import { AffiliateDashboardFilter } from './AffiliateDashboardFilter/AffiliateDashboardFilter';
import {
  fetchAffiliatesDashboardAnalysis, fetchFTDByCountry, fetchFTDByStatus, fetchByAllStatuses, 
} from '../../redux/affiliatesDashboard/affiliatesDashboardActions';

const affiliateDashboardStorage = localStorage.getItem('Affiliate_Dashboard/filters');

function AffiliatesDashboard() {
  const dispatch = useDispatch();

  const [filterData, setFilterData] = useState(JSON.parse(affiliateDashboardStorage) ?? {});
  const [amountOfFilters, setAmountOfFilters] = useState(0);
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const statusOption = useSelector(selectStatusOption);

  function getFilteredObjectLength(obj, keysToExclude) {
    return Object.keys(obj).reduce((count, key) => (keysToExclude[key] === obj[key] ? count : count + 1), 0);
  }

  useEffect(() => {
    setAmountOfFilters(getFilteredObjectLength(
      filterData, 
      {
        period: 'All',
        managerStatus: 3,
        brand: 'all',
      },
    ));
  }, [filterData]);

  const isFilterSelected = useMemo(() => amountOfFilters !== 0, [amountOfFilters]);
  let filerAdaptiveBtnClass = styles.filterAdaptiveBtn;

  if (isFilterSelected) {
    filerAdaptiveBtnClass += ` ${styles.activeFilterAdaptiveBtn}`;
  }

  const handleFilterBtn = () => setIsFilterPanelOpen(true);

  const refreshFilterHandler = () => {
    dispatch(fetchAffiliatesDashboardAnalysis(filterData));
    dispatch(fetchFTDByCountry(filterData));
    dispatch(statusOption === 'All Statuses' ? fetchByAllStatuses(filterData) : fetchFTDByStatus(filterData));
  };

  useEffect(() => {
    const fetchDashboardData = () => {
      if (statusOption === 'All Statuses') {
        dispatch(fetchByAllStatuses(filterData));
      } else {
        dispatch(fetchFTDByStatus(filterData));
      }
      dispatch(fetchAffiliatesDashboardAnalysis(filterData));
      dispatch(fetchFTDByCountry(filterData));
    };
  
    fetchDashboardData();
  }, [statusOption, filterData]);

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className={styles.wrapper}>
        <div className={styles.dashboardWrapper}>
          <div className={styles.title}>
            Affiliates Dashboard
          </div>
          <div className={styles.adaptiveFilterWrapper}>
            <button type="button" className={filerAdaptiveBtnClass} onClick={handleFilterBtn}>
              <FilterListIcon sx={{ height: '18px', color: '#C4C6D0' }} />
              <span>Filter</span>
              {
                isFilterSelected && (
                  <div className={styles.filterAdaptiveAmountIcon}>
                    <span>{amountOfFilters}</span>
                  </div>
                )
              }
            </button>
            <button
              type="button"
              className={styles.filterAdaptiveReloadBtn}
              onClick={refreshFilterHandler}
            >
              <RefreshIcon sx={{ color: '#C4C6D0' }} />
            </button>
          </div>
          <div className={styles.item}>
            <StatusFTD />
          </div>
          <div className={styles.item}>
            <Analysis />
          </div>
          <div className={styles.item}>
            <AffiliatesFTD filterData={filterData} setFilterData={setFilterData} />
          </div>
          <div className={styles.item}>
            <FunnelFTD filterData={filterData} setFilterData={setFilterData} />
          </div>
          <div className={styles.item}>
            <CountriesFTD />
          </div>
        </div>
        <AffiliateDashboardFilter
          isOpen={isFilterPanelOpen}
          setIsOpen={setIsFilterPanelOpen}
          filterObject={filterData}
          setFilterObject={setFilterData}
        />
      </div>
    </div>
  );
}

export default AffiliatesDashboard;
