export function addOpacityToHex(hex, opacity) {
  // Remove the `#` if it exists
  hex = hex.replace('#', '');

  // Convert short hex format (#abc) to full format (#aabbcc)
  if (hex.length === 3) {
    hex = hex.split('').map((char) => char + char).join('');
  }

  // Ensure the hex is valid
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }

  // Parse the hex color
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Clamp opacity between 0 and 1
  if (opacity < 0 || opacity > 1) {
    throw new Error('Opacity must be between 0 and 1.');
  }

  // Convert opacity to 8-bit hex value
  const alpha = Math.round(opacity * 255).toString(16).padStart(2, '0');

  // Return hex color with alpha
  return `#${hex}${alpha}`;
}
