import {
  GET_CFD_ORDERS, 
  UPDATE_CFD_ORDER, 
  REVERT_CFD_ORDER, 
  STOP_CFD_ORDER, 
  MASS_CFD_ORDER_CREATION, 
  MASS_CFD_ORDER_STOP, 
  MASS_CFD_ORDER_EDIT,
  MASS_REVERT_CFD_ORDER,
  All_CLOSED_ORDERS_SUM, 
  All_OPEN_ORDERS,
  OPEN_CFD_ORDER,
  CLEAR_RESULTS,
  GET_CFD_ORDERS_HISTORY,
} from './cfdOrderTypes';

const initialState = {
  massCreationResults: [],
  massEditResults: [],
  orders: [],
  ordersHistory: [],
  totalCountHistory: 0,
  totalCount: 0,
  allOrdersSum: 0,
  openOrders: [],
  results: [],
};

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_RESULTS:
      return {
        ...state,
        results: [],
      };
    case MASS_REVERT_CFD_ORDER:
      return {
        ...state,
        totalCountHistory: state.totalCountHistory.filter(({ _id }) => !action.payload.orderIds.includes(_id)),
        results: action.payload.results,
      };
    case All_OPEN_ORDERS:
      return {
        ...state,
        openOrders: action.payload,
      };
    case All_CLOSED_ORDERS_SUM:
      return {
        ...state,
        allOrdersSum: action.payload,
      };
    case MASS_CFD_ORDER_STOP:
      return {
        ...state,
        orders: state.orders.filter(({ _id }) => !action.payload.ids.includes(_id)),
      };
    case MASS_CFD_ORDER_CREATION:
      return {
        ...state,
        massCreationResults: action.payload,
      };
    case MASS_CFD_ORDER_EDIT:
      return {
        ...state,
        massEditResults: action.payload.results,
        orders: state.orders.map((order) => {
          const updatedOrder = action.payload.orders.find(({ _id }) => _id === order._id);

          return updatedOrder || order;
        }),
      };
    case GET_CFD_ORDERS:
      return action.payload;
    case GET_CFD_ORDERS_HISTORY:
      return {
        ...state,
        ordersHistory: action.payload.orders,
        totalCountHistory: action.payload.totalCount,
      };
    case UPDATE_CFD_ORDER:
      return {
        totalCount: state.totalCount,
        orders: state.orders.map((order) => {
          if (order._id === action.payload._id) return action.payload;

          return order;
        }),
        ordersHistory: state.ordersHistory.map((order) => {
          if (order._id === action.payload._id) return action.payload;

          return order;
        }),
      };
    case REVERT_CFD_ORDER:
      return {
        totalCountHistory: Number(state.totalCountHistory) - 1,
        ordersHistory: state.ordersHistory.filter((order) => order._id !== action.payload._id),
      };

    case STOP_CFD_ORDER: 
      return {
        totalCount: Number(state.totalCount) - 1,
        orders: state.orders.filter((order) => order._id !== action.payload._id),
      };

    case OPEN_CFD_ORDER: 
      return {
        totalCount: Number(state.totalCount) + 1,
        orders: [action.payload, ...state.orders],
      };
    default:
      return state;
  }
};
