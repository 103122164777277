import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  startOfWeek, startOfMonth, endOfMonth, endOfWeek, 
} from 'date-fns';
import { monthData } from '../../helpers/calendar';
import { CalendarCell } from '../CalendarCell/CalendarCell';
import { getEventsByDateRange } from '../../../../redux/event/eventActions';
import styles from './styles.module.css';
import { CalendarHeader } from '../CalendarHeader/CalendarHeader';

export function MonthCalendar({
  selectedDate, setShowEditEventModal, setFocusedEvent, focusedEvent, sortQuery,
}) {
  const dispatch = useDispatch();
  const currentDate = new Date();
    
  const start = startOfWeek(startOfMonth(selectedDate));
  const end = endOfWeek(endOfMonth(selectedDate));

  const [draggableEvent, setDraggableEvent] = useState();
  const events = useSelector((state) => state.events);
  const uid = useSelector((state) => state.crmUser.crmUserInfo._id);

  useEffect(() => {
    if (uid && end && start && sortQuery) {
      dispatch(getEventsByDateRange(
        { 
          id: uid, 
          end: new Date(end).valueOf(), 
          start: new Date(start).valueOf(), 
          sortQuery,
        },
      ));
    }
  }, [uid, sortQuery]);

  const collectedMonthData = monthData(start, end, currentDate, selectedDate, events);
    
  return (
    <div className={styles.calendar}>
      <CalendarHeader data={collectedMonthData[0] ?? []} />

      <div className={styles.calendarBody}>
        {collectedMonthData.map((week, key = 0) => (
          <div className={styles.week} key={`week-${key}`}>
            {week.map((day, key = 0) => (
              <CalendarCell 
                key={`day-${key}`}
                day={day}
                setShowEditEventModal={setShowEditEventModal}
                setFocusedEvent={setFocusedEvent}
                focusedEvent={focusedEvent}
                setDraggableEvent={setDraggableEvent}
                draggableEvent={draggableEvent}
                calendarView="month"
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
