import React, {
  useEffect, useState, useContext, useRef, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'react-data-table-component';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { toast } from 'react-toastify';
import { useDebounce } from '../../hooks/useDebounce';
import { getWhitelistIPTableData, exportFilteredDataToFile } from '../../redux/security/whitelistIPActions';
import { AddWhitelistIPModal } from './components';
import { ModalContext } from '../../context';
import { generateSortParams } from '../../helpers/sortHelper';
import { WhiteListIPSchema } from './components/WhiteListIPSchema';
import { DatatableFilters, DatatableColumns, DownloadFile } from '../../components';
import { createCRMFilter, deleteCRMFilter, updateCRMFilter } from '../../redux/crmUser/crmUserActions';
import { whitelistColumns } from '../../columnsDefaultConfig';
import { setNewOrderedColumns } from '../../DataTableSchemas/helper';

function WhitelistIP() {
  const dispatch = useDispatch();
  const { showModal } = useContext(ModalContext);
  const { pathname } = useLocation();

  const [columnConfig, setColumnConfig] = useState(whitelistColumns);
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [filters, setFilters] = useState({});
  const [sortParams, setSortParams] = useState(JSON.stringify({ createdAt: -1 }));
  const [nameFilter, setNameFilter] = useState(null);
  const [crmUserNameFilter, setCrmUserNameFilter] = useState(null);
  const [ipFilter, setIpFilter] = useState(null);
  const [page, setPage] = useState(1);

  const { totalCount, whitelist } = useSelector((state) => state.whitelistIP);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);
  const isUserCanAddIP = permissionName && !!permissionName.length && permissionName.includes('add_whitelist_ip');

  const filterIdStorageName = 'DataTable_whitelist/filters_id'; 
  const paginationStorageName = 'DataTable_whitelist/pagination';
  const paginationStorageJSON = localStorage.getItem(paginationStorageName);
  const filtersStorageName = 'DataTable_whitelist/filters';
  const filtersStorageJSON = localStorage.getItem(filtersStorageName);
  const columnsStorageName = 'DataTable_whitelist/columns';
  const columnsStorageJSON = localStorage.getItem(columnsStorageName);

  const datatableFiltersRef = useRef(null);

  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const debounceCallback = ({ value, key }) => {
    if (value !== null && (value.length >= 0) && filters[key] !== value) {
      setFilters((prev) => ({ ...prev, [key]: value }));
    }
  };

  useDebounce(nameFilter, 1000, (value) => debounceCallback({ value, key: 'name' }));
  useDebounce(crmUserNameFilter, 1000, (value) => debounceCallback({ value, key: 'crmUserName' }));
  useDebounce(ipFilter, 1000, (value) => debounceCallback({ value, key: 'ip' }));

  const setStoredColumnsData = () => {
    if (columnsStorageJSON) {
      const columns = JSON.parse(columnsStorageJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  const setStoredFilterData = () => {
    const filters = JSON.parse(filtersStorageJSON) ?? {};
    setFilters(filters);

    setNameFilter(filters.name || '');
    setCrmUserNameFilter(filters.fullName || '');
    setIpFilter(filters.ip || '');
  };

  const setStoredPagination = () => {
    if (paginationStorageJSON) {
      const filterRows = JSON.parse(paginationStorageJSON);
      setRowsPerPage(filterRows.limit || 15);
      setPage(filterRows.page || 1);
    }

    setIsPaginationDT(true);
  };

  const getAllStoredData = () => {
    setStoredColumnsData();
    setStoredFilterData();
    setStoredPagination();
  };

  const handleSort = async (sortField, sortDirection) => {
    const sort = generateSortParams(sortField, sortDirection);
    setSortParams(sort);
  };

  const storeColumnConfig = (config) => {
    setColumnConfig(config);
    localStorage.setItem(columnsStorageName, JSON.stringify(config));
  };

  useEffect(() => {
    getAllStoredData();
  }, []);

  useEffect(async () => {
    if (!isPaginationDT) return;
    localStorage.setItem(filtersStorageName, JSON.stringify(filters));
    setTableLoading(true);

    await dispatch(getWhitelistIPTableData({
      page, 
      limit: rowsPerPage, 
      query: filters,
      sort: sortParams,
    }));

    setTableLoading(false);
  }, [page, rowsPerPage, isPaginationDT, filters, sortParams]);

  const handleClear = () => {
    setNameFilter('');
    setCrmUserNameFilter('');
    setIpFilter('');
    localStorage.removeItem(filtersStorageName);
    localStorage.removeItem(filterIdStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  const handleRefresh = async () => {
    setTableLoading(true);
    await dispatch(getWhitelistIPTableData({
      page, 
      limit: rowsPerPage, 
      query: filters,
      sort: sortParams,
    }));
    setTableLoading(false);
  };

  const handlePageChange = (page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: rowsPerPage, page }));
    setPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage, page }));
    setRowsPerPage(currentRowsPerPage);
  };

  const setCRMFilters = (filter) => {
    const {
      fullName,
      ip,
      description,
    } = filter;

    setNameFilter(description || null);
    setCrmUserNameFilter(fullName || null);
    setIpFilter(ip || null);
    setFilters({
      name: description,
      crmUserName: fullName,
      ip,
    });
    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);
    
    const data = {
      name,
      crmUserId,
      pathname,
      ip: filters.ip || '',
      fullName: filters.crmUserName || '',
      description: filters.name || '',
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        ip: filters.ip || '',
        fullName: filters.crmUserName || '',
        description: filters.name || '',
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const handleExportStatuses = async (fileType) => {
    const columns = columnConfig.filter((obj) => obj.selected && obj.field);
    if (whitelist.length && columns.length) {
      toast.success('Data export in progress. Please wait while we prepare the file.', {
        autoClose: 2000,
      });
      exportFilteredDataToFile(filters, columns, fileType); 
    } else {
      toastError('There is nothing to download.');
    }
  };

  const columns = WhiteListIPSchema({
    columnConfig,
    nameFilter,
    setNameFilter,
    crmUserNameFilter,
    setCrmUserNameFilter,
    ipFilter,
    setIpFilter,
    handleSort,
  });

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>IP Whitelist</h3>
        <div className="action__btn-row">
          <div className="main_btn-row">
            <div className="secondary_btn-row">
              {isUserCanAddIP
                    && (
                    <button 
                      type="button"
                      className="btn-primary_light"
                      onClick={() => showModal({ 
                        bodyContent: <AddWhitelistIPModal />, 
                        headerContent: <h3>Add IP to Whitelist</h3>, 
                      })}
                    >
                      <FontAwesomeIcon icon={faPlus} size="sm" />
                      Add New
                    </button>
                    )}
            </div>
            <div className="secondary_btn-row">
              <DatatableFilters
                ref={datatableFiltersRef}
                filters={crmFilters}
                setFilters={setCRMFilters}
                createFilter={createUserCRMFilter}
                deleteFilter={deleteUserCRMFilter}
                updateFilter={updateUserCRMFilter}
                storageKey={filterIdStorageName}
                pathname={pathname}
              />
              <button type="button" className="btn-secondary_dark iconed" onClick={handleClear}>
                <CloseOutlinedIcon sx={{ fontSize: '20px' }} />
              </button>
              <button type="button" className="btn-secondary_dark iconed" onClick={handleRefresh}>
                <RefreshIcon sx={{ fontSize: '20px' }} />
              </button>
            </div>
            <div className="secondary_btn-row">
              <DatatableColumns setColumns={storeColumnConfig} columnConfig={columnConfig} defaultConfig={whitelistColumns} />
            </div>
            <div className="secondary_btn-row">
              <DownloadFile handleExport={handleExportStatuses} />
            </div>
          </div>
        </div>

        <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
          {
            isPaginationDT && (
              <DataTable
                columns={columns}
                data={whitelist}
                pagination
                paginationServer
                paginationPerPage={rowsPerPage}
                paginationDefaultPage={page}
                paginationTotalRows={totalCount}
                paginationRowsPerPageOptions={[15, 30, 50]}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                onColumnOrderChange={(newColumns) => setNewOrderedColumns(
                  newColumns,
                  columnConfig,
                  storeColumnConfig,
                )}
                persistTableHead
                highlightOnHover
                theme="solarizedd"
                progressPending={tableLoading}
                progressComponent={<div className="datatable-loader__background" />}
              />
            )
          }
        </div>
      </div>
    </div>
  );
}

export default WhitelistIP;
