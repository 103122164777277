/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { ModalFilters } from '../components';
import { 
  prettyCutOutputByCellWidth, 
  valueOutputCutLength, 
  omitColumn,
  sortColumnByOrderConfig,
} from './helper';
import { TooltipComponent } from '../components/TooltipComponent/TooltipComponent';
import { useTableSorting, SortWrapper } from '../helpers/sortHelper';
import SortIcon from '../components/SortIcon';
 
export const CfdSettingsSchema = ({
  columnConfig,
  showModal, 
  CfdSettingsModal, 
  handleSort,
  searchByAlias, 
  setSearchByAlias,
  marketTypesFilter, 
  setMarketTypesFilter,
}) => { 
  const cfdMarketTypes = useSelector((state) => state.cfdMarketTypes);

  const { sortFields, getSortIcon } = useTableSorting(handleSort);
  
  const columns = [
    {
      name: (
        <SortWrapper handleSort={() => sortFields('primary')}>
          <div className="d-flex flex-row">
            <span>Symbol</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('primary')} />
            </button>
          </div>
        </SortWrapper>
      ),
      cell: (row) => row.primary,
      omit: omitColumn(columnConfig, 'Symbol'),
      label: 'Symbol',
      reorder: true,
      minWidth: '80px',
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('alias')}>
            <div className="d-flex flex-row">
              <span>Alias</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('alias')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="clientFullname"
            value={searchByAlias}
            minLength="3"
            onChange={(e) => setSearchByAlias(e.target.value)}
          />
        </div>
      ),
      cell: (row) => row.alias,
      omit: omitColumn(columnConfig, 'Alias'),
      label: 'Alias',
      reorder: true,
      minWidth: '180px',
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('secondary')}>
          <div className="d-flex flex-row">
            <span>Base Currency</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('secondary')} />
            </button>
          </div>
        </SortWrapper>
      ),
      cell: (row) => row.secondary,
      omit: omitColumn(columnConfig, 'Base Currency'),
      label: 'Base Currency',
      reorder: true,
      minWidth: '132px',
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('type.name')}>
            <div className="d-flex flex-row">
              <span>Market</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('type.name')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Market" 
            data={cfdMarketTypes}
            filters={marketTypesFilter} 
            setFilters={setMarketTypesFilter} 
            usersFilters={{}}
            setUserFilters={() => {}}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          />
        </div>
      ),
      omit: omitColumn(columnConfig, 'Market'),
      label: 'Market',
      reorder: true,
      cell: (row) => row.type.name,
      minWidth: '130px',
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('shift')}>
          <div className="d-flex flex-row">
            <span>Shift</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('shift')} />
            </button>
          </div>
        </SortWrapper>
      ),
      selector: ({ shift }) => (
        shift?.toString().length >= valueOutputCutLength.shift
          ? (
            <TooltipComponent
              title={shift?.toString()}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(shift?.toString(), 'shift')}
                </span>
            )}
            />
          )
          : (
            shift ?? 0
          )
      ),
      minWidth: '80px',
      label: 'Shift',
      reorder: true,
      omit: omitColumn(columnConfig, 'Shift'),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('leverage')}>
          <div className="d-flex flex-row">
            <span>Leverage</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('leverage')} />
            </button>
          </div>
        </SortWrapper>
      ),
      selector: ({ leverage }) => (
        leverage.toString().length >= valueOutputCutLength.leverage
          ? (
            <TooltipComponent
              title={leverage.toString()}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(leverage.toString(), 'leverage')}
                </span>
            )}
            />
          )
          : (
            leverage
          )
      ),
      minWidth: '112px',
      label: 'Leverage',
      reorder: true,
      omit: omitColumn(columnConfig, 'Leverage'),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('lotStep')}>
          <div className="d-flex flex-row">
            <span>Lot Step</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('lotStep')} />
            </button>
          </div>
        </SortWrapper>
      ),
      selector: (row) => row.lotStep,
      minWidth: '104px',
      label: 'Lot Step',
      reorder: true,
      omit: omitColumn(columnConfig, 'Lot Step'),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('lotSize')}>
          <div className="d-flex flex-row">
            <span>Lot Size</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('lotSize')} />
            </button>
          </div>
        </SortWrapper>
      ),
      selector: (row) => row.lotSize,
      minWidth: '104px',
      label: 'Lot Size',
      reorder: true,
      omit: omitColumn(columnConfig, 'Lot Size'),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('commission')}>
          <div className="d-flex flex-row">
            <span>Commission %</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('commission')} />
            </button>
          </div>
        </SortWrapper>
      ),
      selector: (row) => row.commission,
      minWidth: '146px',
      label: 'Commission',
      reorder: true,
      omit: omitColumn(columnConfig, 'Commission'),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('swapLong')}>
          <div className="d-flex flex-row">
            <span>Swap Long %</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('swapLong')} />
            </button>
          </div>
        </SortWrapper>
      ),
      selector: (row) => row.swapLong,
      minWidth: '136px',
      label: 'Swap Long',
      reorder: true,
      omit: omitColumn(columnConfig, 'Swap Long'),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('swapShort')}>
          <div className="d-flex flex-row">
            <span>Swap Short %</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('swapShort')} />
            </button>
          </div>
        </SortWrapper>
      ),
      selector: (row) => row.swapShort,
      minWidth: '144px',
      label: 'Swap Short',
      reorder: true,
      omit: omitColumn(columnConfig, 'Swap Short'),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('spreadMax')}>
          <div className="d-flex flex-row">
            <span>Spread Max</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('spreadMax')} />
            </button>
          </div>
        </SortWrapper>
      ),
      selector: (row) => row.spreadMax ?? 0,
      width: '155px',
      label: 'Spread Max',
      reorder: true,
      omit: omitColumn(columnConfig, 'Spread Max'),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('spreadMin')}>
          <div className="d-flex flex-row">
            <span>Spread Min</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('spreadMin')} />
            </button>
          </div>
        </SortWrapper>
      ),
      selector: (row) => row.spreadMin ?? 0,
      width: '155px',
      label: 'Spread Min',
      reorder: true,
      omit: omitColumn(columnConfig, 'Spread Min'),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('maxVolume')}>
          <div className="d-flex flex-row">
            <span>Max Volume</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('maxVolume')} />
            </button>
          </div>
        </SortWrapper>
      ),
      cell: ({ maxVolume = 0 }) => maxVolume,
      minWidth: '104px',
      label: 'Max Volume',
      reorder: true,
      omit: omitColumn(columnConfig, 'Max Volume'),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('minVolume')}>
          <div className="d-flex flex-row">
            <span>Min Volume</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('minVolume')} />
            </button>
          </div>
        </SortWrapper>
      ),
      cell: ({ minVolume = 0 }) => minVolume,
      minWidth: '104px',
      label: 'Min Volume',
      reorder: true,
      omit: omitColumn(columnConfig, 'Min Volume'),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('isVisible')}>
          <div className="d-flex flex-row">
            <span>Visible</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('isVisible')} />
            </button>
          </div>
        </SortWrapper>
      ),
      cell: ({ isVisible }) => (isVisible ? 'Yes' : 'No'),
      label: 'Visible',
      reorder: true,
      omit: omitColumn(columnConfig, 'Visible'),
    },
    {
      name: 'Actions',
      minWidth: '100px',
      omit: omitColumn(columnConfig, 'Actions'),
      label: 'Actions',
      reorder: true,
      cell: (row) => (
        <div className="action-btn">
          <button
            type="button"
            className="btn btn-success btn-sm me-1 p-1"
            onClick={() => showModal({
              headerContent: <h3>Edit CFD Setting</h3>, 
              bodyContent: <CfdSettingsModal row={row} />,
            })}
          >
            <FontAwesomeIcon icon={faPencil} />
          </button>
        </div>
      ),
    },
  ];

  return sortColumnByOrderConfig(columns, columnConfig);
};
