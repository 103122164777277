import React, {
  useState, useContext, useEffect, useRef, 
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { toast } from 'react-toastify';
import { DatatableFilters, DatatableColumns, DownloadFile } from '../../components';
import { CurrenciesSchema } from './CurrenciesSchema';
import { ModalContext } from '../../context';
import AddCurrencyModal from './modals/AddCurrencyModal';
import { getCurrencyRulesTable, exportFilteredDataToFile } from '../../redux/currencyRule/currencyRuleActions';
import { getCountries } from '../../redux/users/userActions';
import { geAccountCurrencies } from '../../redux/cfdAccountCurrency/cfdAccountCurrencyActions';
import { useDebounce } from '../../hooks/useDebounce';
import { generateSortParams } from '../../helpers/sortHelper';
import { createCRMFilter, deleteCRMFilter, updateCRMFilter } from '../../redux/crmUser/crmUserActions';
import { setNewOrderedColumns } from '../../DataTableSchemas/helper';
import { currenciesRulesColumns } from '../../columnsDefaultConfig';

export default function Currencies() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { showModal } = useContext(ModalContext);

  const { totalCount, currencies } = useSelector((state) => state.currencyRule);
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);

  const [columnConfig, setColumnConfig] = useState(currenciesRulesColumns);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [tableLoading, setTableLoading] = useState(false);
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [currencyFilter, setCurrencyFilter] = useState(null);
  const [countriesFilter, setCountriesFilter] = useState(null);
  const [filters, setFilters] = useState({});
  const [sortParams, setSortParams] = useState(JSON.stringify({ createdAt: -1 }));

  const filterIdStorageName = 'DataTable_currencies-rules/filters_id'; 
  const filtersStorageName = 'DataTable_currencies-rules/filters';
  const filtersStorageJSON = localStorage.getItem(filtersStorageName);
  const paginationStorageName = 'DataTable_currencies-rules/pagination';
  const paginationStorageJSON = localStorage.getItem(paginationStorageName);
  const columnsStorageName = 'DataTable_currencies-rules/columns';
  const columnsStorageJSON = localStorage.getItem(columnsStorageName);

  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const isUserCanAddCurrency = permissionName && !!permissionName.length && permissionName.includes('add_currency');

  const datatableFiltersRef = useRef(null);

  const debounceCallback = ({ value, key, length }) => {
    if (value !== null && (value.length >= length) && filters[key] !== value) {
      setFilters((prev) => ({ ...prev, [key]: value }));
    }
  };

  useDebounce(currencyFilter, 1000, (value) => debounceCallback({ value, key: 'symbol', length: 0 }));
  useDebounce(countriesFilter, 1000, (value) => debounceCallback({ value, key: 'countries', length: 0 }));

  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const setStoredColumnsData = () => {
    if (columnsStorageJSON) {
      const columns = JSON.parse(columnsStorageJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  const setStoredFilterData = () => {
    const filters = JSON.parse(filtersStorageJSON) ?? {};
    setFilters(filters);

    setCurrencyFilter(filters.symbol || '');
    setCountriesFilter(filters.countries || '');
  };

  const setStoredPagination = () => {
    if (paginationStorageJSON) {
      const filterRows = JSON.parse(paginationStorageJSON);
      setRowsPerPage(filterRows.limit || 25);
      setPage(filterRows.page || 1);
    }

    setIsPaginationDT(true);
  };

  const getAllStoredData = () => {
    setStoredColumnsData();
    setStoredFilterData();
    setStoredPagination();
  };

  useEffect(() => {
    getAllStoredData();
    Promise.all([
      dispatch(geAccountCurrencies()),
      dispatch(getCountries()),
    ]);
  }, []);

  useEffect(async () => {
    if (!isPaginationDT) return;
    localStorage.setItem(filtersStorageName, JSON.stringify(filters));
    setTableLoading(true);

    await dispatch(getCurrencyRulesTable({
      page, 
      limit: rowsPerPage, 
      query: filters,
      sort: sortParams,
    }));

    setTableLoading(false);
  }, [page, rowsPerPage, isPaginationDT, filters, sortParams]);

  const handleSort = async (sortField, sortDirection) => {
    const sort = generateSortParams(sortField, sortDirection);
    setSortParams(sort);
  };

  const handlePageChange = (page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: rowsPerPage, page }));
    setPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage, page }));
    setRowsPerPage(currentRowsPerPage);
  };

  const storeColumnConfig = (config) => {
    setColumnConfig(config);
    localStorage.setItem(columnsStorageName, JSON.stringify(config));
  };

  const handleClear = () => {
    setCurrencyFilter('');
    setCountriesFilter('');
    localStorage.removeItem(filtersStorageName);
    localStorage.removeItem(filterIdStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  const handleExportStatuses = async (fileType) => {
    const columns = columnConfig.filter((obj) => obj.selected && obj.field);
    if (currencies.length && columns.length) {
      toast.success('Data export in progress. Please wait while we prepare the file.', {
        autoClose: 2000,
      });
      exportFilteredDataToFile(filters, columns, fileType); 
    } else {
      toastError('There is nothing to download.');
    }
  };

  const handleRefresh = async () => {
    setTableLoading(true);
    await dispatch(getCurrencyRulesTable({
      page, 
      limit: rowsPerPage, 
      query: filters,
      sort: sortParams,
    }));
    setTableLoading(false);
  };

  const setCRMFilters = (filter) => {
    const {
      symbol,
      countries,
    } = filter;

    setCurrencyFilter(symbol || null);
    setCountriesFilter(countries || null);
    setFilters({
      symbol,
      countries,
    });
    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);
    
    const data = {
      name,
      crmUserId,
      pathname,
      symbol: filters.symbol || '',
      countries: filters.countries || '',
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        symbol: filters.symbol || '',
        countries: filters.countries || '',
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const columns = CurrenciesSchema({
    columnConfig,
    handleSort,
    currencyFilter,
    setCurrencyFilter,
    countriesFilter,
    setCountriesFilter,
  });

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>Currencies</h3>
        <div className="action__btn-row">
          <div className="main_btn-row">
            <div className="secondary_btn-row">
              {isUserCanAddCurrency
                    && (
                    <button 
                      type="button"
                      className="btn-primary_light"
                      onClick={() => showModal({ 
                        bodyContent: <AddCurrencyModal />, 
                        headerContent: <h3>Add New Currency</h3>, 
                      })}
                    >
                      <FontAwesomeIcon icon={faPlus} size="sm" />
                      Add New
                    </button>
                    )}
            </div>
            <div className="secondary_btn-row">
              <DatatableFilters
                ref={datatableFiltersRef}
                filters={crmFilters}
                setFilters={setCRMFilters}
                createFilter={createUserCRMFilter}
                deleteFilter={deleteUserCRMFilter}
                updateFilter={updateUserCRMFilter}
                storageKey={filterIdStorageName}
                pathname={pathname}
              />
              <button type="button" className="btn-secondary_dark iconed" onClick={handleClear}>
                <CloseOutlinedIcon sx={{ fontSize: '20px' }} />
              </button>
              <button type="button" className="btn-secondary_dark iconed" onClick={handleRefresh}>
                <RefreshIcon sx={{ fontSize: '20px' }} />
              </button>
            </div>
            <div className="secondary_btn-row">
              <DatatableColumns setColumns={storeColumnConfig} columnConfig={columnConfig} defaultConfig={currenciesRulesColumns} />
            </div>
            <div className="secondary_btn-row">
              <DownloadFile handleExport={handleExportStatuses} />
            </div>
          </div>
        </div>
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
          {isPaginationDT && (
            <DataTable
              columns={columns}
              data={currencies}
              fixedHeader
              pagination
              paginationServer
              paginationPerPage={rowsPerPage}
              paginationTotalRows={totalCount}
              paginationRowsPerPageOptions={[25, 50, 100, 500]}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              onColumnOrderChange={(newColumns) => setNewOrderedColumns(
                newColumns,
                columnConfig,
                storeColumnConfig,
              )}
              paginationDefaultPage={page}
              persistTableHead
              highlightOnHover
              theme="solarizedd"
              progressPending={tableLoading}
              progressComponent={<div className="datatable-loader__background" />}
            />
          )}
        </div>
      </div>
    </div>
  );
}
