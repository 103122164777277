import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Form, Modal, Button, Row, Col, 
} from 'react-bootstrap';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinner, faPlus, 
} from '@fortawesome/free-solid-svg-icons';
import { showAllCurrencies } from '../../../redux/currency/currencyActions';
import {
  addCurrencyAmountToUserAccount, 
} from '../../../redux/users/userActions';
import { getCurrencyRates } from '../../../redux/currencyRate/currencyRateActions';
import { getAccountOptionsByUserId } from '../../../redux/cfdAccount/cfdAccountActions';
import ExternalTransactions from '../../Transactions/ExternalTransactions/ExternalTransactions';

function Deposit() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  
  const [show, setShow] = useState(false);
  const [addAmountInfo, setAddAmountInfo] = useState('');
  const [addComment, setAddComment] = useState('');
  const [addAmountInfoErr] = useState('');
  const [addCommentErr] = useState('');
  const [isReal, setIsReal] = useState('true');
  const [isRealErr, setIsRealErr] = useState('');
  const [isResolved, setIsResolved] = useState(1);
  const [isResolvedErr, setIsResolvedErr] = useState('');
  const [balanceType, setBalanceType] = useState(0);
  const [balanceTypeErr, setBalanceTypeErr] = useState('');
  const [accountErr, setAccountErr] = useState('');
  const [apiCall, setApiCall] = useState(false);
  const [currencyAccountSelectedErr, setCurrencyAccountSelectedErr] = useState('');
  const [selectedAccount, setSelectedAccount] = useState('');
  
  const [depositCurrency, setDepositCurrency] = useState('');
  const userData = useSelector((state) => state?.users?.user);
  const currencies = useSelector((state) => state.currency?.currencies?.allCurrencies);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const accountOptions = useSelector((state) => state.cfdAccounts.options);

  const handleShow = () => setShow(true);

  const refetchTableData = useCallback(() => {
    if (tableRef.current) {
      tableRef.current.refetchTable();
    }
  }, [tableRef]);

  useEffect(() => {
    if (currencies && currencies.length) {
      const currency = currencies.find((currency) => currency.symbol === 'USD');

      setDepositCurrency({ value: currency._id, label: `${currency.name}  (${currency.symbol})` });
    }
  }, [currencies]);

  useEffect(() => {
    const usdAccount = accountOptions.find((account) => account.label.includes('USD'));

    if (usdAccount) {
      setSelectedAccount(usdAccount.value);

      return;
    } 

    setSelectedAccount(accountOptions[0]?.value);
  }, [accountOptions]);

  const {
    register: register1, handleSubmit: handleSubmit1, control: control1, reset: reset1, formState: { errors: errors1 },
  } = useForm();

  const handleClose = () => {
    setShow(false);
    setAddAmountInfo('');
    setAddComment('');
    setIsRealErr('');
    setIsResolvedErr('');
    setBalanceTypeErr('');
    setCurrencyAccountSelectedErr('');
    setIsReal('true');
    setIsResolved(1);
    setBalanceType(0);
    setAccountErr('');
    reset1();
    setApiCall(false);
  };

  const handleCurrencyAmountSave = async (formData) => {
    let errorsOccured = false;

    if (!depositCurrency.value) {
      setCurrencyAccountSelectedErr('Select currency of transaction');
      errorsOccured = true;
    }

    if (balanceType === 3) {
      setBalanceTypeErr('Select type of transaction');
      errorsOccured = true;
    }

    if (isResolved === 3) {
      setIsResolvedErr('Select status of transaction');
      errorsOccured = true;
    }

    if (isReal === null) {
      setIsRealErr('Select if transaction is real or fake');
      errorsOccured = true;
    }

    if (!selectedAccount || !selectedAccount.length) {
      setAccountErr('Select account');
      errorsOccured = true;
    }

    if (!errorsOccured) {
      setApiCall(true);
      const data = {
        userId: id,
        currencyId: depositCurrency.value,
        amount: formData.amountForCurrency,
        additionalInfo: addAmountInfo,
        comment: addComment,
        isReal,
        isResolved,
        selectedAccount,
        balanceType: Number(balanceType) === 0,
        addedBy: localStorage.getItem('userId').slice(1, -1),
      };
      await dispatch(addCurrencyAmountToUserAccount(data)).then(() => {
        refetchTableData();
      });

      setAddAmountInfo('');
      setAddComment('');
      handleClose();
    }
  };

  const addCurrencyAmount = {
    currencyAccountSelected: {
      required: 'Please select currency',
    },
    amountForCurrency: {
      required: 'Amount is required',
      pattern: {
        value: /^-?[0-9]\d*(\.\d+)?$/,
        message: 'Only numbers and decimals are allowed',
      },
    },
  };

  useEffect(async () => {
    Promise.allSettled([
      dispatch(getCurrencyRates('USD')),
      dispatch(showAllCurrencies()),
    ]);
  }, []);

  useEffect(() => {
    if (!userData._id) return;
    
    dispatch(getAccountOptionsByUserId(userData._id));
  }, [userData]);

  const handleDepositCurrencyChange = (depositCurrencyChange) => {
    setDepositCurrency(depositCurrencyChange);
  };

  const colourStyles = {
    control: (styles, { isDisabled, isSelected }) => ({
      ...styles,
      background: isDisabled ? '#aaa' : '#374057',
      color: isDisabled ? '#aaa' : 'fff',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      border: '1px solid #374057',
      boxShadow: isSelected ? 'none' : 'none',
      borderColor: isSelected ? '#374057' : '#374057',
      '&:hover': {
        boxShadow: 'none',
      },
    }),
    input: (styles) => ({
      ...styles,
      color: '#fff',
    }),
    singleValue: (styles) => ({
      ...styles,
      color: '#fff',
    }),
    menuList: (styles) => ({
      ...styles,
      background: '#374057',
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? '#16202e'
        : isSelected
          ? '#16202e'
          : undefined,
      color: 'fff',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      zIndex: 1,
      '&:hover': {
        background: isDisabled ? '#aaa' : '#16202e',
      },
    }),
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} className="widthdrawal-modal">
        <Modal.Header closeButton>
          <Modal.Title className="text-white">ADD DEPOSIT</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit1(handleCurrencyAmountSave)} className="account-balance-currency me-0">
          <Modal.Body>
            <div className="withdrawal-data">
              <Row>
                <Col md={6}>
                  <div className="form-group me-2">
                    <label className="control-label mb-2">Amount</label>
                    <div className="input-wrapper">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter amount"
                        {...register1('amountForCurrency', addCurrencyAmount?.amountForCurrency)}
                        name="amountForCurrency"
                        defaultValue=""
                        control={control1}
                      />
                      {errors1?.amountForCurrency && <span className="errMsg">{errors1?.amountForCurrency?.message}</span>}
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group me-2">
                    <label className="control-label mb-2">Currency</label>
                    <div className="input-wrapper">
                      <Select
                        styles={colourStyles}
                        name="currencyAccountSelected"
                        value={depositCurrency}
                        options={currencies && currencies.length > 0 && currencies.map((currency) => ({
                          value: currency._id,
                          label: `${currency?.name}  (${currency?.symbol})`,
                        }))}
                        isSearchable
                        onChange={handleDepositCurrencyChange}
                      />
                      {currencyAccountSelectedErr ? (<span className="errMsg">{currencyAccountSelectedErr}</span>) : ('')}
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group me-2">
                    <label className="control-label mb-2">Account</label>
                    <div className="input-wrapper">
                      <Form.Select name="account" value={selectedAccount} onChange={({ target: { value } }) => setSelectedAccount(value)}>
                        <option value="">Select Account</option>
                        {
                              accountOptions.map(({ value, label }) => (
                                <option key={value} value={value}>{label}</option>
                              ))
                            }
                      </Form.Select>
                      {accountErr ? (<span className="errMsg">{accountErr}</span>) : ('')}
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group me-2">
                    <label className="control-label mb-2">Type</label>
                    <div className="input-wrapper">
                      <Form.Select name="balanceType" value={balanceType} onChange={(event) => setBalanceType(event.target.value)}>
                        <option value="3">Select Type</option>
                        <option value="0">Balance</option>
                        <option value="1">Credit</option>
                      </Form.Select>
                      {balanceTypeErr ? (<span className="errMsg">{balanceTypeErr}</span>) : ('')}
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group me-2">
                    <label className="control-label mb-2">Status</label>
                    <div className="input-wrapper">
                      <Form.Select name="isResolved" value={isResolved} onChange={(event) => setIsResolved(event.target.value)}>
                        <option value="3">Select Type</option>
                        <option value="0">Pending</option>
                        <option value="1">Approved</option>
                        <option value="2">Declined</option>
                      </Form.Select>
                      {isResolvedErr ? (<span className="errMsg">{isResolvedErr}</span>) : ('')}
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group me-2">
                    <label className="control-label mb-2">Real</label>
                    <div className="input-wrapper">
                      <Form.Select name="isReal" value={isReal} onChange={(event) => setIsReal(event.target.value)}>
                        <option value="3">Select Transactions </option>
                        <option value="true">Real</option>
                        <option value="false">Fake</option>
                      </Form.Select>
                      {isRealErr ? (<span className="errMsg">{isRealErr}</span>) : ('')}
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group me-2">
                    <label className="control-label mb-2">Additional Info</label>
                    <div className="input-wrapper">
                      <input type="text" className="form-control" name="addAmountInfo" value={addAmountInfo} onChange={(event) => setAddAmountInfo(event.target.value)} />
                      {addAmountInfoErr ? (<span className="errMsg">{addAmountInfoErr}</span>) : ('')}
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group me-2">
                    <label className="control-label mb-2">Comment</label>
                    <div className="input-wrapper">
                      <input
                        type="text"
                        className="form-control"
                        name="addComment"
                        value={addComment}
                        onChange={(event) => setAddComment(event.target.value)}
                      />
                      {addCommentErr ? (<span className="errMsg">{addCommentErr}</span>) : ('')}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            {apiCall
              ? (
                <Button variant="primary" style={{ padding: '8px 40px' }}>
                  <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                </Button>
              )
              : (
                <Button variant="primary" type="submit">
                  Save Changes
                </Button>
              )}

          </Modal.Footer>
        </form>
      </Modal>
      
      <ExternalTransactions
        ref={tableRef}
        userIdFromDetail={id}
        disableHeader
        secondaryButtonRow={permissionName && permissionName.length > 0 && permissionName.includes('deposit_manual')
          ? (
            <button type="button" className="btn-primary_light" onClick={handleShow}>
              <FontAwesomeIcon icon={faPlus} size="sm" />
              Add New
            </button>
          )
          : null}
      />

    </div>
  );
}

export default Deposit;
