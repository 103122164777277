export function isStatusesHasChanged(initialDataAssoc, newPriorityList) {
  const changes = [];

  initialDataAssoc.forEach((initialStatus) => {
    const movedStatus = newPriorityList.find((el) => el._id === initialStatus._id);
    if (movedStatus && initialStatus.priority !== movedStatus.priority) {
      changes.push({
        _id: initialStatus._id,
        oldPriority: initialStatus.priority,
        newPriority: movedStatus.priority,
      });
    }
  });

  return !!changes.length;
}
