import React, {
  useEffect, useState, useRef, useContext, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { getAdminBankAccounts, updateState } from '../../redux/psp/adminBankAccountActions';
import { BankAccountsPspSchema } from '../../DataTableSchemas/BankAccountsPspSchema';
import { AdminBanksColumns } from '../../columnsDefaultConfig/AdminBankColumns';
import { DatatableFilters, DatatableColumns } from '../../components';
import {
  createCRMFilter, deleteCRMFilter, getDesksIbs, updateCRMFilter, 
} from '../../redux/crmUser/crmUserActions';
import { useObjectDebounce } from '../../hooks/useDebounce';
import { ModalContext } from '../../context';
import { generateSortParams } from '../../helpers/sortHelper';
import { ModalAddAdminBankAccount } from './modals/ModalAddAdminBankAccount';
import { fiatCurrencies } from '../../redux/currency/currencyActions';
import { fetchCounties } from '../../redux/countries/countriesActions';
import { selectFiatCurrenciesSelector } from '../../redux/currency/currencySelector';
import { setNewOrderedColumns } from '../../DataTableSchemas/helper';


function AdminBankAccount() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isPaginationDT, setIsPaginationDT] = useState(false);

  const { showModal: showModalContext, hideModal: hideModalContext } = useContext(ModalContext);
  const datatableFiltersRef = useRef(null);

  const filtersStorageName = 'DataTable_adminBank/filters'; 
  const filterIdStorageName = 'DataTable_adminBank/filter_id'; 
  const columnsStorageName = 'DataTable_adminBank/columns';
  const paginationStorageName = 'DataTable_adminBank/pagination';

  const filtersJSON = localStorage.getItem(filtersStorageName);
  const columnsJSON = localStorage.getItem(columnsStorageName);
  const [columnConfig, setColumnConfig] = useState(AdminBanksColumns);
  const [bankAccountFilters, setBankAccountFilters] = useState({});
  const [tempFilters, setTempFilters] = useState({});
  const [sortParams, setSortParams] = useState(JSON.stringify({ createdAt: -1 }));
  const [assetFilters, setAssetFilters] = useState([]);
  const [includedCountriesOptions, setIncludedCountriesOptions] = useState([]);
  const [excludedCountriesOptions, setExcludedCountriesOptions] = useState([]);
  const [ibdeskFilterPreset, setIbdeskFilterPreset] = useState([]);
  const [status, setStatus] = useState([]);
  const [debounce, setDebounce] = useState(false);

  const tableLoading = useSelector((state) => state?.adminBankAccount?.isLoading);
  const adminBankAccountsCount = useSelector((state) => state?.adminBankAccount?.totalCount);
  const adminBankAccounts = useSelector((state) => state?.adminBankAccount?.adminBankAccounts);
  const adminBankAccountsFetched = useSelector((state) => state?.adminBankAccount?.adminBankAccountsfetched);
  const adminBankAccountDeleted = useSelector((state) => state?.adminBankAccount?.adminBankAccountDeleted);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);
  const allIbDesks = useSelector((state) => state.crmUser?.deskIbs);
  const countries = useSelector((state) => state.countries?.countries);
  const fiatCurrency = useSelector(selectFiatCurrenciesSelector);

  const isUserCanAddBankAccount = permissionName && permissionName.length > 0 && permissionName.includes('add_admin_bank_account');

  const handleSort = async (sortField, sortDirection) => {
    const sort = generateSortParams(sortField, sortDirection);
    setSortParams(sort);
  };

  const columns = BankAccountsPspSchema({
    permissionName,
    columnConfig,
    tempFilters,
    setTempFilters,
    showModalContext,
    handleSort,
    assetFilters,
    setAssetFilters,
    includedCountriesOptions,
    setIncludedCountriesOptions,
    excludedCountriesOptions,
    setExcludedCountriesOptions,
    status,
    setStatus,
    ibdeskFilterPreset,
    setIbdeskFilterPreset,
    allIbDesks,
    countries,
    fiatCurrency,
  });

  const setStoredPagination = () => {
    const rowsJSON = localStorage.getItem(paginationStorageName);
    if (rowsJSON) {
      const filterRows = JSON.parse(rowsJSON);
      setRowsPerPage(filterRows.limit || 25);
    }
    
    setIsPaginationDT(true);
  };

  const setStoredColumnsData = () => {
    if (columnsJSON) {
      const columns = JSON.parse(columnsJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  const refresh = () => {
    dispatch(getAdminBankAccounts(page, rowsPerPage, sortParams, bankAccountFilters));
  };

  const toggleColumn = (name) => {    
    const updatedColumns = columnConfig.map((column) => {
      if (column.name === name) {
        return { ...column, selected: !column.selected };
      }

      return column;
    });

    setColumnConfig(updatedColumns);
    localStorage.setItem(columnsStorageName, JSON.stringify(updatedColumns));
  };

  const handlePageChange = async (page) => setPage(page);

  const handleRowsPerPageChange = async (currentRowsPerPage, page) => {
    setPage(page);
    setRowsPerPage(currentRowsPerPage);
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage }));
  };

  const handleClear = () => {
    setTempFilters({});
    setBankAccountFilters({});
    setAssetFilters([]);
    setIncludedCountriesOptions([]);
    setExcludedCountriesOptions([]);
    setStatus([]);
    setIbdeskFilterPreset([]);
    localStorage.removeItem(filtersStorageName);
    localStorage.removeItem(filterIdStorageName);
    datatableFiltersRef.current.clearDrodownName();
    setDebounce(false);
  };

  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const setCRMFilters = (filters) => {
    setTempFilters({
      name: filters.fullName || '',
      iban: filters.email || '',
      accountNumber: filters.phone || '',
      bankAddress: filters.transactionId || '',
      swiftCode: filters.uid || '',
      ibdeskid: filters.ibDeskId || [],
      alias: filters.alias || '',
      beneficiary: filters.beneficiary || '',
      beneficiaryAddress: filters.beneficiaryAddress || '',
      correspondentAccount: filters.correspondentAccount || '',
      inn: filters.inn || '',
      kpp: filters.kpp || '',
      reference: filters.reference || '',
      currency: filters.currency || [],
      status: filters.status || [],
      'included countries': filters.includedCountries || [],
      'excluded countries': filters.excludedCountries || [],
    });
    localStorage.setItem(filterIdStorageName, JSON.stringify(filters._id));
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);

    const data = {
      name,
      crmUserId,
      pathname,
      fullName: filters.name || '',
      email: filters.iban || '',
      phone: filters.accountNumber || '',
      transactionId: filters.bankAddress || '',
      uid: filters.swiftCode || '',
      ibDeskId: filters.ibdeskid || [],
      alias: filters.alias || '',
      beneficiary: filters.beneficiary || '',
      beneficiaryAddress: filters.beneficiaryAddress || '',
      correspondentAccount: filters.correspondentAccount || '',
      inn: filters.inn || '',
      kpp: filters.kpp || '',
      reference: filters.reference || '',
      includedCountries: filters['included countries'] || [],
      excludedCountries: filters['excluded countries'] || [],
      currency: filters.currency || [],
      status: filters.status || [],
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toastError('Select at least one filter to complete this action.');
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);

      const data = {
        fullName: filters.name || '',
        email: filters.iban || '',
        phone: filters.accountNumber || '',
        transactionId: filters.bankAddress || '',
        uid: filters.swiftCode || '',
        ibDeskId: filters.ibdeskid || [],
        alias: filters.alias || '',
        beneficiary: filters.beneficiary || '',
        beneficiaryAddress: filters.beneficiaryAddress || '',
        correspondentAccount: filters.correspondentAccount || '',
        inn: filters.inn || '',
        kpp: filters.kpp || '',
        reference: filters.reference || '',
        includedCountries: filters['included countries'] || [],
        excludedCountries: filters['excluded countries'] || [],
        currency: filters.currency || [],
        status: filters.status || [],
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toastError('Select at least one filter to complete this action.');
    }
  };

  const setStoredFilterData = () => {
    if (filtersJSON) {
      const filters = JSON.parse(filtersJSON);
      setAssetFilters(filters.currency || []);
      setIncludedCountriesOptions(filters['included countries'] || []);
      setExcludedCountriesOptions(filters['excluded countries'] || []);
      setIbdeskFilterPreset(filters.ibdeskid || []);
      setStatus(filters.status || []);
      setTempFilters(filters || {});
    }
  };

  useEffect(() => {
    Promise.all([
      dispatch(fiatCurrencies()),
      dispatch(getDesksIbs()),
      dispatch(fetchCounties()),
    ]);
    setStoredColumnsData();
    setStoredFilterData();
    setStoredPagination();
  }, []);

  useEffect(() => {
    if (adminBankAccountsFetched || adminBankAccountDeleted) {
      dispatch(updateState());
    }
  }, [adminBankAccountsFetched, adminBankAccountDeleted]);

  useEffect(() => {
    if (debounce) {
      localStorage.setItem(filtersStorageName, JSON.stringify(bankAccountFilters));
      dispatch(getAdminBankAccounts(page, rowsPerPage, sortParams, bankAccountFilters));
    }  
  }, [debounce, page, rowsPerPage, sortParams, bankAccountFilters]);

  useObjectDebounce(tempFilters, 300, (newFilters) => {
    setBankAccountFilters((prev) => ({ ...prev, ...newFilters }));
    setDebounce(true);
  });

  const storeColumnConfig = (config) => {
    setColumnConfig(config);
    localStorage.setItem(columnsStorageName, JSON.stringify(config));
  };

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>Bank Accounts</h3>
        <div className="action__btn-row">
          <div className="main_btn-row">
            <div className="secondary_btn-row">
              {isUserCanAddBankAccount
                && (
                <button 
                  type="button"
                  className="btn-primary_light"
                  onClick={() => showModalContext({ 
                    bodyContent: <ModalAddAdminBankAccount 
                      hideModal={hideModalContext} 
                      allIbDesks={allIbDesks}
                      countries={countries}
                      fiatCurrency={fiatCurrency}
                    />, 
                    headerContent: <h3>Add Bank Account</h3>, 
                  })}
                >
                  <FontAwesomeIcon icon={faPlus} size="sm" />
                  Add Bank Account
                </button>
                )}
            </div>
            <div className="secondary_btn-row">
              {crmFilters && (
                <DatatableFilters 
                  ref={datatableFiltersRef}
                  filters={crmFilters} 
                  setFilters={setCRMFilters}
                  createFilter={createUserCRMFilter}
                  deleteFilter={deleteUserCRMFilter}
                  updateFilter={updateUserCRMFilter}
                  storageKey={filterIdStorageName}
                  pathname={pathname}
                />
              )}
              <button
                type="button"
                className="btn-secondary_dark iconed"
                onClick={handleClear}
              >
                <CloseOutlinedIcon sx={{ fontSize: '20px' }} />
              </button>

              <button 
                type="button"
                className="btn-secondary_dark iconed" 
                onClick={refresh}
              >
                <RefreshIcon sx={{ fontSize: '20px' }} />
              </button>
            </div>
            <div className="secondary_btn-row">
              <DatatableColumns setColumns={storeColumnConfig} columnConfig={columnConfig} defaultConfig={AdminBanksColumns} />
            </div>
          </div>
        </div>
        {
          isPaginationDT && (
            <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
              <DataTable
                columns={columns}
                data={adminBankAccounts}
                highlightOnHover
                pagination
                paginationServer
                paginationTotalRows={adminBankAccountsCount}
                paginationPerPage={rowsPerPage}
                paginationRowsPerPageOptions={[25, 50, 200, 500]}
                onChangeRowsPerPage={handleRowsPerPageChange}
                onChangePage={handlePageChange}
                onColumnOrderChange={(newColumns) => setNewOrderedColumns(
                  newColumns,
                  columnConfig,
                  storeColumnConfig,
                )}
                persistTableHead
                theme="solarizedd"
                progressPending={tableLoading}
                progressComponent={<div className="datatable-loader__background" />}
              />
            </div>
          )
        }
      </div>
    </div>
  );
}

export default AdminBankAccount;
