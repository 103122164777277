import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { getClientLogins, exportClientLoginToFile } from '../../redux/clientLogin/clientLoginActions';
import { ClientLoginActivityColumns } from '../../DataTableSchemas/ClientLoginActivityColumns';
import { useDebounce } from '../../hooks/useDebounce';
import { DownloadFile } from '../../components/DownloadFile';
import { generateSortParams } from '../../helpers/sortHelper';

function ClientLoginActivity({ 
  isSeparatePage = false,
  isPositionUnset = false,
  userId = null,
}) {
  const dispatch = useDispatch();
  const paginationStorageName = 'DataTable_activity/client-login_pagination';

  const [tableLoading, setTableLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [sortParams, setSortParams] = useState(JSON.stringify({ createdAt: -1 }));

  const [userFilters, setUserFilters] = useState({});
  const [clientNameFilter, setClientNameFilter] = useState(null);
  const [clientIP, setClientIP] = useState(null);
  const [clientBrowser, setClientBrowser] = useState(null);

  const allClientLogins = useSelector((state) => state?.clientLogin?.clientLogins);
      
  const setStoredPagination = () => {
    const clientLoginActivityRowsJSON = localStorage.getItem(paginationStorageName);
    if (clientLoginActivityRowsJSON) {
      const filterRows = JSON.parse(clientLoginActivityRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
    }
    
    setIsPaginationDT(true);
  };

  const getActivities = (page, rowsPerPage, userFilters, sortParams) => dispatch(getClientLogins({
    page, rowsPerPage, userFilters, userId, sortParams,
  }));
  
  const handlePageChange = (page) => setPage(page);

  const handleRowsPerPageChange = (currentRowsPerPage) => {
    setRowsPerPage(currentRowsPerPage);
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage }));
  };

  const handleExportClientLoginLogs = async (fileType) => {
    await dispatch(exportClientLoginToFile({
      page, 
      rowsPerPage, 
      filters: userFilters,
      fileType, 
      userId,
    }));
  };

  useDebounce(clientNameFilter, 1000, (value) => {
    if (value.length >= 0) {
      setUserFilters({ ...userFilters, clientName: value });
    }
  });

  useDebounce(clientIP, 1000, (value) => {
    if (value.length >= 0) {
      setUserFilters({ ...userFilters, ip: value });
    }
  });

  useDebounce(clientBrowser, 1000, (value) => {
    if (value.length >= 0) {
      setUserFilters({ ...userFilters, browser: value });
    }
  });

  const handleSort = async (sortField, sortDirection) => {
    const sort = generateSortParams(sortField, sortDirection);
    setSortParams(sort);
  };

  const loginColumns = ClientLoginActivityColumns(
    clientNameFilter, 
    setClientNameFilter,
    clientIP, 
    setClientIP,
    clientBrowser, 
    setClientBrowser,
    handleSort,
  );

  useEffect(() => {
    setStoredPagination();
  }, []);

  useEffect(async () => {
    if (isPaginationDT) {
      setTableLoading(true);
      await getActivities(page, rowsPerPage, userFilters, sortParams);
      setTableLoading(false);
    }
  }, [isPaginationDT, userFilters, page, rowsPerPage, sortParams]);

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className={!isSeparatePage ? 'content-box' : ''}>
        {
          !isSeparatePage
            && (
              <h3>Clients Logins</h3>
            )
        }
        <div className="action__btn-row">
          <DownloadFile isPositionUnset={isPositionUnset} handleExport={handleExportClientLoginLogs} />
        </div>
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
          {isPaginationDT
            && (
              <DataTable
                columns={loginColumns}
                data={allClientLogins.paginatedResult}
                pagination
                paginationServer
                highlightOnHover
                paginationPerPage={rowsPerPage}
                paginationRowsPerPageOptions={[25, 50, 100, 500]}
                paginationTotalRows={allClientLogins.total}
                onChangeRowsPerPage={handleRowsPerPageChange}
                onChangePage={handlePageChange}
                persistTableHead
                selectableRowsHighlight
                selectableRowsVisibleOnly
                theme="solarizedd"
                className="specific-field-table custome-table-scroll"
                progressPending={tableLoading}
                progressComponent={<div className="datatable-loader__background" />}
              />
            )}
        </div>
      </div>
    </div>
  );
}

export default ClientLoginActivity;
