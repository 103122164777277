import React from 'react';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { prettyCutOutputByCellWidth } from '../../DataTableSchemas/helper';

const CustomizedMenuItem = styled(MenuItem)(({ selected, color }) => ({
  color: color || '#DAE2F9',
  minHeight: '20px',
  fontFamily: 'Play',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
  letterSpacing: '0.25px',
  padding: '10px 14.5px',
  backgroundColor: selected ? '#2D3152 !important' : '#232640 !important',
  '&:hover': {
    backgroundColor: '#22253C',
    opacity: '0.92',
  },
  '&.Mui-selected': {
    backgroundColor: '#232934',
    opacity: '0.88',
    '&:hover': {
      backgroundColor: '#262C38',
      opacity: '0.92',
    },
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#222731',
    opacity: '0.88',
  },
}));

export function TableSelectComponent({
  value, onChange, options, emptyLabel,
}) {
  const selectedOption = options.find((option) => option.value === value);
  const selectedColor = selectedOption?.color ?? '#fff';

  return (
    <FormControl variant="outlined" style={{ width: '100%' }}>
      <Select
        value={value}
        onChange={onChange}
        displayEmpty
        renderValue={(selected) => {
          if (!selected) {
            return <span>{emptyLabel}</span>;
          }
          return <span style={{ color: selectedColor }}>{selectedOption?.label}</span>;
        }}
        MenuProps={{
          MenuListProps: {
            disablePadding: true,
          },
          PaperProps: {
            style: {
              maxHeight: '50vh',
            },
          },
        }}
        sx={{
          height: '32px',
          padding: '4px 8px',
          fontSize: '13px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '20px',
          letterSpacing: '0.1px',
          textAlign: 'left',
          border: 'none',
          maxWidth: '100%',
          width: '100%',
          outline: 'none',
          backgroundColor: '#1b3155ba',
          color: selectedColor,
          ' .MuiSelect-select': {
            padding: 0,
          },
          '.MuiOutlinedInput-notchedOutline': {
            outline: 'none',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
      >
        {options.map((option) => (
          <CustomizedMenuItem key={option.value} value={option.value} selected={option.value === value} color={option.color}>
            {prettyCutOutputByCellWidth(option.label, 'selectMenuItem')}
          </CustomizedMenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
