import { ENV } from '../config/config';

const columns = [
  {
    name: 'ID',
    field: 'customId',
    selected: true,
    order: 1,
  },
  {
    name: 'User Actions',
    field: '',
    selected: true,
    order: 2,
  },
  {
    name: 'Full Name',
    field: 'firstName,lastName',
    selected: true,
    order: 3,
  },
  {
    name: 'Email',
    field: 'email',
    selected: true,
    order: 4,
  },
  {
    name: 'Phone Number',
    field: 'phone',
    selected: true,
    order: 5,
  },
  {
    name: 'Country',
    field: 'country.nicename',
    selected: true,
    order: 6,
  },
  {
    name: 'Created',
    field: 'createdAt',
    selected: true,
    order: 7,
  },
  {
    name: 'Affiliate Name',
    field: 'affiliator.firstName,affiliator.lastName',
    selected: true,
    permissionName: 'affiliates',
    order: 8,
  },
  {
    name: 'Funnel Name',
    field: 'source',
    selected: true,
    order: 9,
  },
  {
    name: 'Assigned To',
    field: 'assignedToFullName',
    selected: true,
    order: 10,
  },
  {
    name: 'Status',
    field: 'salesStatus.name',
    selected: true,
    order: 11,
  },
  {
    name: 'Brand',
    field: 'brand.name',
    selected: true,
    order: 12,
  },
  {
    name: 'Balance',
    field: 'account.balance',
    selected: true,
    order: 13,
  },
  {
    name: 'Credit',
    field: 'account.credit',
    selected: true,
    order: 14,
  },
  {
    name: 'Net Deposits',
    field: 'account.netDeposits',
    selected: true,
    order: 15,
  },
  {
    name: 'First Time Deposit',
    field: 'firstTimeDeposit',
    selected: true,
    order: 16,
  },
  {
    name: 'FTD Owner',
    field: 'ftdOwner',
    selected: true,
    order: 17,
  },
  {
    name: 'STD Owner',
    field: 'stdOwner',
    selected: true,
    order: 18,
  },
  {
    name: 'Online',
    field: 'lastLoginAt,isLogin',
    selected: true,
    order: 19,
  },
  {
    name: 'Last Promotion',
    field: 'lastPromotionDate',
    selected: true,
    order: 20,
  },
  {
    name: 'Last Demotion',
    field: 'lastDemotionDate',
    selected: true,
    order: 21,
  },
  {
    name: 'Last Assignment',
    field: 'lastAssignmentDate',
    selected: true,
    order: 22,
  },
  {
    name: 'Previous Owner',
    field: 'prevAssignedToData',
    selected: true,
    order: 23,
  },
  {
    name: 'Last Login Date',
    field: 'lastLoginAt',
    selected: true,
    order: 24,
  },
  {
    name: 'Last Call',
    field: 'lastCallDate',
    selected: true,
    order: 25,
  },
  {
    name: 'Total Calls',
    field: 'totalCalls',
    selected: true,
    order: 26,
  },
  {
    name: 'Last Action',
    field: 'updatedAt',
    selected: true,
    order: 27,
  },
  {
    name: 'Last Comment Date',
    field: 'adminComments[0].createdAt',
    selected: true,
    order: 28,
  },
  {
    name: 'Last Comment',
    field: 'lastComment',
    selected: true,
    order: 29,
  },
  {
    name: 'Local Time',
    field: 'countryCode',
    selected: true,
    order: 30,
  },
  {
    name: 'Additional Info',
    field: 'additionalInfo',
    selected: true,
    order: 31,
  },
  {
    name: 'Manager',
    field: 'managerUserFullName',
    selected: true,
    order: 32,
  },
  {
    name: 'Desk/Ib',
    field: 'ibdeskUserFullName',
    selected: true,
    order: 33,
  },
  {
    name: 'Last Online',
    field: 'lastOnline',
    selected: true,
    order: 34,
  },
  {
    name: 'Second Email',
    field: 'secondEmail',
    selected: true,
    order: 35,
  },
  {
    name: 'Second Phone Number',
    field: 'secondPhone',
    selected: true,
    order: 36,
  },
  {
    name: 'Language',
    field: 'language',
    selected: true,
    order: 37,
  },
  {
    name: 'Delete User',
    field: '',
    selected: true,
    order: 38,
  },
];

export const leadsColumns = (permissions) => columns.filter((column) => !column.permissionName || permissions.includes(column.permissionName));
