import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Tree, TreeNode } from 'react-organizational-chart';
import {
  getHierarchyOrder,
  updateCRMUserState,
} from '../../redux/crmUser/crmUserActions';

const genderTree = (node) => node?.map((item) => (
  <TreeNode
    key={`node-${item?._id}`}
    label={(
      <Link
        className="manager-info-btn"
        to={`/edit-admin/${item?._id}`}
      >
        <div className="personinfo">
          <div className="textWrapper">
            <div className="name">{`${item.firstName} ${item.lastName}`}</div>
            <div className="job-title">{item?.userRole?.[0]?.name}</div>
          </div>
        </div>
      </Link>
      )}
  >
    {(() => genderTree(item?.childNodes))()}
  </TreeNode>
));

function Hierarchy() {
  const dispatch = useDispatch();
  const [, setLoader] = useState(false);
  const hierarchyOrder = useSelector((state) => state.crmUser.hierarchyOrder);
  const hierarchyOrderFetched = useSelector(
    (state) => state.crmUser.hierarchyOrderFetched,
  );

  const order = useMemo(() => {
    const order = [];

    if (hierarchyOrder.length === 1) {
      order.push(...hierarchyOrder);
    } else if (hierarchyOrder.length > 1) {
      const userDataJSON = localStorage.getItem('user');
      
      if (userDataJSON) {
        const userData = JSON.parse(userDataJSON);
        order.push({ ...userData, childNodes: hierarchyOrder });
      }
    }

    return order;
  }, [hierarchyOrder]);


  useEffect(() => {
    if (hierarchyOrderFetched) {
      dispatch(updateCRMUserState());
      setLoader(false);
    }
  }, [hierarchyOrderFetched]);

  useEffect(() => {
    setLoader(true);
    const loginData = localStorage.getItem('user');
    const data = JSON.parse(loginData);
    const uid = data?._id;

    dispatch(getHierarchyOrder(uid));
  }, []);

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="hierarchy-container">
        <div
          className="org-hierarchy"
        >
          <div className="top-manager-wrapper">
            <div className="manager">
              {order?.length > 0 ? (
                <Tree
                  label={(
                    <Link
                      className="manager-info-btn"
                      to={`/edit-admin/${order?.[0]?._id}`}
                    >
                      <div className="personinfo">
                        <div className="textWrapper">
                          <div className="name">{`${order?.[0]?.firstName} ${order?.[0]?.lastName}`}</div>
                          <div className="job-title">
                            {order?.[0]?.userRole?.[0]?.name}
                          </div>
                        </div>
                      </div>
                    </Link>
                )}
                  lineBorderRadius=""
                  lineColor="#666"
                  lineStyle=""
                  lineWidth="2px"
                  nodePadding=""
                >
                  {order?.[0]?.childNodes?.length > 0
                    ? genderTree(order?.[0]?.childNodes)
                    : null}
                </Tree>
              ) : null}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Hierarchy;
