import { SALES_ROLES } from '../../AffiliatesDashboard/utils/optionsHelper';

const conversionSalesStatusOptions = [
  { label: 'Conversion', value: SALES_ROLES.CONVERSION },
];
const retentionSalesStatusOptions = [
  { label: 'Retention', value: SALES_ROLES.RETENTION },
];

const bothSalesStatusOptions = [
  { label: 'Both', value: SALES_ROLES.BOTH },
  { label: 'Conversion', value: SALES_ROLES.CONVERSION },
  { label: 'Retention', value: SALES_ROLES.RETENTION },
];

export const getSalesStatusOptions = (
  crmUserRole = SALES_ROLES.BOTH,
) => {
  if (crmUserRole === SALES_ROLES.CONVERSION) {
    return conversionSalesStatusOptions;
  }
  if (crmUserRole === SALES_ROLES.RETENTION) {
    return retentionSalesStatusOptions;
  }

  return bothSalesStatusOptions;
};

export const countryOptions = [
  { ticker: 'Amount' },
  { ticker: 'Number' },
];

export const statusOptions = [
  { ticker: 'Leads' },
  { ticker: 'Clients' },
];
