import React, {
  useEffect, useState, useRef, useImperativeHandle, forwardRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  updateIsReal, 
  deleteTransaction, 
  exportFilteredDataToFile, 
  updateTransactionState, 
  getTransactionsByQuery,
  massDeleteTransactions,
} from '../../../redux/transactions/transactionActions';
import { resolveDepositTransaction, revertTransaction } from '../../../redux/users/userActions';
import { ExternalTransactionsSchema } from '../../../DataTableSchemas/ExternalTransactionsSchema/ExternalTransactionsSchema';
import { showAllCurrencies } from '../../../redux/currency/currencyActions';
import {
  getSubCRMUsers, createCRMFilter, deleteCRMFilter, updateCRMFilter, getDesksIbs,
} from '../../../redux/crmUser/crmUserActions';
import { useDebounce } from '../../../hooks/useDebounce';
import { getCurrencyRates } from '../../../redux/currencyRate/currencyRateActions';
import { getBrands } from '../../../redux/brand/brandActions';
import { DatatableFilters, DatatableColumns } from '../../../components';
import { depositColumns } from '../../../columnsDefaultConfig';
import { DownloadFile } from '../../../components/DownloadFile';
import { generateSortParams } from '../../../helpers/sortHelper';
import { getAffiliates } from '../../../redux/affiliate/affiliateActions';
import { setNewOrderedColumns } from '../../../DataTableSchemas/helper';

const detailDisabledColumns = ['Client ID', 'Client'];

const ExternalTransactions = forwardRef(({
  isDeleted = false, userIdFromDetail = undefined, secondaryButtonRow, disableHeader = false,
}, ref) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const paginationStorageName = `DataTable_deposits${userIdFromDetail ? '_detail' : ''}/pagination`;
  const filtersStorageName = `DataTable_deposit${userIdFromDetail ? '_detail' : ''}/filters`;
  const filterIdStorageName = `DataTable_deposits${userIdFromDetail ? '_detail' : ''}/filters_id`;
  const columnsStorageName = `DataTable_deposits${userIdFromDetail ? '_detail' : ''}/columns`;
  const depositsFiltersJSON = localStorage.getItem(filtersStorageName);
  const depositsColumnsJSON = localStorage.getItem(columnsStorageName);

  const datatableFiltersRef = useRef(null);
  
  const { paginatedData: transactionsData, totalCount } = useSelector((state) => state?.transaction?.allDeposits);

  const exportFilterdDataToFile = useSelector((state) => state.transaction.exportFilterdDataToFile);
  const currencies = useSelector((state) => state.currency?.currencies?.allCurrencies);
  const assignToShow = useSelector((state) => state.crmUser?.crmUsers);
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const allIbDesks = useSelector((state) => state.crmUser?.deskIbs);
  const allAffiliates = useSelector((state) => state.affiliate.affiliates);
  const isUserCanDeleteTransaction = permissionName.includes('delete_transaction');

  const defaultColumns = depositColumns.map((col) => (userIdFromDetail ? { ...col, selected: !detailDisabledColumns.includes(col.name), disable: detailDisabledColumns.includes(col.name) } : col));
  const [loader, setLoader] = useState(false);
  
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [columnConfig, setColumnConfig] = useState(defaultColumns);
  const [tableLoading, setTableLoading] = useState(false);

  const [transactionIdFilter, setTransactionIdFilter] = useState();
  const [uidFilter, setUidFilter] = useState();
  const [accountIdFilter, setAccountIdFilter] = useState();
  const [affiliateFilter, setAffiliateFilter] = useState([]);
  const [nameFilter, setNameFilter] = useState();
  const [emailFilter, setEmailFilter] = useState();
  const [secondEmailFilter, setSecondEmailFilter] = useState();
  const [assetFilters, setAssetFilters] = useState([]);
  const [brandsFilters, setBrandsFilters] = useState([]);
  const [managerFilters, setManagerFilters] = useState([]);
  const [ibdeskUserFilter, setIbdeskUserFilters] = useState([]);
  const [amountRange, setAmountRange] = useState(null);
  const [realFilter, setRealFilter] = useState([]);
  const [sourceFilter, setSourceFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [isAdditionalInfoModalShown, setIsAdditionalInfoModalShown] = useState('');
  const [isAdditionalCommentModalShown, setIsAdditionalCommentModalShown] = useState('');
  const [balanceTypeFilter, setBalanceTypeFilter] = useState([]);
  const [sortParams, setSortParams] = useState(JSON.stringify({ createdAt: -1 }));
  const [toggleCleared, setToggleCleared] = useState(false);

  const [timeInitiatedFilter, setTimeInitiatedFilter] = useState([]);
  const [depositsFilters, setDepositsFilters] = useState({});
  
  const debounceCallback = ({ value, key }) => {
    if ((value.length >= 0 || Array.isArray(value)) && depositsFilters[key] !== value) {
      setDepositsFilters((prev) => ({ ...prev, [key]: value }));
    }
  };

  useDebounce(transactionIdFilter, 1000, (value) => debounceCallback({ value, key: 'transactionId' }));
  useDebounce(uidFilter, 1000, (value) => debounceCallback({ value, key: 'userId' }));
  useDebounce(accountIdFilter, 1000, (value) => debounceCallback({ value, key: 'accountId' }));
  useDebounce(nameFilter, 1000, (value) => debounceCallback({ value, key: 'fullName' }));
  useDebounce(emailFilter, 1000, (value) => debounceCallback({ value, key: 'email' }));
  useDebounce(secondEmailFilter, 1000, (value) => debounceCallback({ value, key: 'secondEmail' }));
  useDebounce(amountRange, 1000, (value) => debounceCallback({ value, key: 'amount' }));

  useImperativeHandle(ref, () => ({
    refetchTable() {
      dispatch(getTransactionsByQuery({
        page, limit: rowsPerPage, query: { ...depositsFilters, userId: userIdFromDetail ?? depositsFilters.userId }, isDeleted, sortParams,
      }));
    },
  }));
  
  const refetchTable = async () => {
    await dispatch(getTransactionsByQuery({
      page, limit: rowsPerPage, query: { ...depositsFilters, userId: userIdFromDetail ?? depositsFilters.userId }, isDeleted, sortParams,
    }));
  };

  const setStoredPagination = () => {
    const depositRowsJSON = localStorage.getItem(paginationStorageName);
    if (depositRowsJSON) {
      const filterRows = JSON.parse(depositRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
      setPage(filterRows.page || 1);
    }

    setIsPaginationDT(true);
  };

  const handleClear = () => {
    setTransactionIdFilter(null);
    setUidFilter(null);
    setAccountIdFilter(null);
    setNameFilter(null);
    setEmailFilter(null);
    setSecondEmailFilter(null);
    setAssetFilters([]);
    setBrandsFilters([]);
    setManagerFilters([]);
    setAffiliateFilter([]);
    setIbdeskUserFilters([]);
    setStatusFilter([]);
    setBalanceTypeFilter([]);
    setAmountRange(null);
    setTimeInitiatedFilter([]);
    setRealFilter([]);
    setSourceFilter([]);
    setDepositsFilters({});
    setPage(1);
    setRowsPerPage(25);
    localStorage.removeItem(filtersStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  const setStoredFilterData = () => {
    if (depositsFiltersJSON) {
      const filters = JSON.parse(depositsFiltersJSON);
      setDepositsFilters(filters || {});

      setTransactionIdFilter(filters.transactionId || null);
      setUidFilter(filters.userId || null);
      setAccountIdFilter(filters.accountId || null);
      setAssetFilters(filters.currency || []);
      setBrandsFilters(filters.brand || []);
      setNameFilter(filters.fullName || null);
      setEmailFilter(filters.email || null);
      setSecondEmailFilter(filters.secondEmail || null);
      setManagerFilters(filters['assigned to'] || []);
      setAffiliateFilter(filters['affiliate name'] || []);
      setIbdeskUserFilters(filters.ibdesk || []);
      setRealFilter(filters['real/fake'] || []);
      setSourceFilter(filters.source || []);
      setAmountRange(filters.amount || null);
      setStatusFilter(filters.status || []);
      setTimeInitiatedFilter(filters['time initiated'] || []);
      setBalanceTypeFilter(filters.type || []);
    }
  };

  const setStoredColumnsData = () => {
    if (depositsColumnsJSON) {
      const columns = JSON.parse(depositsColumnsJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  const getAllStoredData = () => {
    setStoredColumnsData();
    setStoredFilterData();
    setStoredPagination();
  };

  const setDebounceAmountRange = (type, value, amountRangeValue) => {
    if (amountRangeValue && amountRangeValue.length) {
      const [minValue, maxValue] = amountRangeValue;
      if (type === 'min') {
        setAmountRange([Number(value), maxValue]);
      }
      if (type === 'max') {
        setAmountRange([minValue, Number(value)]);
      }
      if (type === 'slider') {
        setAmountRange(value);
      }
    }
  };

  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const resolveCurrentDepositTransaction = async (rowId, userId, status) => {
    Swal.fire({
      title: `Are you sure you want to ${status === 1 ? 'Approve' : 'Decline'} it?`,
      input: 'textarea',
      inputPlaceholder: 'Enter information/comment...',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed === true) {
        const userIdData = localStorage.getItem('userId');
        const loginUserId = JSON.parse(userIdData);

        const data = {
          userId,
          crmUserId: loginUserId,
          resolvedStatus: status,
          comment: result.value ? result.value : '',
        };

        return Promise.resolve(dispatch(resolveDepositTransaction(rowId, data, false)))
          .then(refetchTable);
      }
      return null;
    });
  };

  const handleRevertTransaction = async (rowId, userId) => {
    Swal.fire({
      title: 'Are you sure you want to Revert the transaction?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed === true) {
        const data = { userId };
        return Promise.resolve(dispatch(revertTransaction(rowId, data, false)))
          .then(refetchTable);
      }
      return null;
    });
  };

  const handleDeleteTransaction = async (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete the transaction?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    })
      .then((result) => {
        if (result.isConfirmed) dispatch(deleteTransaction(id));
      });
  };

  const handleAdditionalInfoModalToggle = (id) => {
    if (isAdditionalInfoModalShown === id) setIsAdditionalInfoModalShown(null);
    else setIsAdditionalInfoModalShown(id); setIsAdditionalCommentModalShown(null);
  };

  const handleAdditionalCommentModalToggle = (id) => {
    if (isAdditionalCommentModalShown === id) setIsAdditionalCommentModalShown(null);
    else setIsAdditionalCommentModalShown(id); setIsAdditionalInfoModalShown(null);
  };

  const setCRMFilters = (filter) => {
    const {
      source, affiliate, fullName, email, secondEmail, depositType, amountRange, currency, status, assignedTo, timeOptionIds, uid, transactionId, brand, type, accountId, ibdesk,
    } = filter;

    const setObject = {
      transactionId,
      userId: uid,
      email,
      secondEmail,
      fullName,
      amount: amountRange,
      currency,
      status,
      brand,
      type,
      accountId,
      ibdesk,
      source,
      'real/fake': depositType,
      'assigned to': assignedTo,
      'time initiated': timeOptionIds,
      'affiliate name': affiliate,
    };

    setAssetFilters(currency || null);
    setBrandsFilters(brand || null);
    setTransactionIdFilter(transactionId || null);
    setUidFilter(uid || null);
    setAccountIdFilter(accountId || null);
    setNameFilter(fullName || null);
    setEmailFilter(email || null);
    setSecondEmailFilter(secondEmail || null);
    setManagerFilters(assignedTo || null);
    setAffiliateFilter(affiliate || null);
    setIbdeskUserFilters(ibdesk || null);
    setRealFilter(depositType || null);
    setSourceFilter(source || null);
    setAmountRange(amountRange || null);
    setStatusFilter(status || null);
    setTimeInitiatedFilter(timeOptionIds || null);
    setDepositsFilters(setObject);
    setBalanceTypeFilter(type || null);
    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const createUserCRMFilter = async (name) => {
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(depositsFiltersJSON);
    const data = {
      name,
      crmUserId,
      pathname,
      uid: filters?.userId || '',
      accountId: filters?.accountId || '',
      transactionId: filters?.transactionId || '',
      fullName: filters?.fullName || '',
      email: filters?.email || '',
      status: filters?.status || [],
      assignedTo: filters?.['assigned to'] || [],
      depositType: filters?.['real/fake'] || [],
      amountRange: filters?.amount?.length ? filters.amount : [0, 1000000],
      currency: filters?.currency || [],
      timeOptionIds: filters?.['time initiated'] || [],
      brand: filters?.brand || [],
      type: filters?.type || [],
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        uid: filters?.userId || '',
        accountId: filters?.accountId || '',
        transactionId: filters?.transactionId || '',
        fullName: filters?.fullName || '',
        email: filters?.email || '',
        status: filters?.status || [],
        assignedTo: filters?.['assigned to'] || [],
        depositType: filters?.['real/fake'] || [],
        amountRange: filters?.amount?.length ? filters.amount : [0, 1000000],
        currency: filters?.currency || [],
        timeOptionIds: filters?.['time initiated'] || [],
        brand: filters?.brand || [],
        type: filters?.type || [],
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const toggleColumn = (name) => {    
    const updatedColumns = columnConfig.map((column) => {
      if (column.name === name) {
        return { ...column, selected: !column.selected };
      }

      return column;
    });

    setColumnConfig(updatedColumns);
    localStorage.setItem(columnsStorageName, JSON.stringify(updatedColumns));
  };

  const refresh = async () => {
    setTableLoading(true);
    await getAllStoredData();
    setTableLoading(false);
  };

  const handlePageChange = (page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: rowsPerPage, page }));
    setPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage, page }));
    setRowsPerPage(currentRowsPerPage);
  };

  const handleExportTransactions = async (fileType) => {
    const columns = columnConfig.filter((obj) => obj.selected && obj.field);
    if (columns.length) {
      setLoader(true);
      toast.success('Data export in progress. Please wait while we prepare the file.', {
        autoClose: 2000,
      });
      await dispatch(exportFilteredDataToFile({ ...depositsFilters, userId: userIdFromDetail ?? depositsFilters.userId, isDeleted }, columns, fileType, 'deposit'));
      setLoader(false);
    } else {
      toastError('There is nothing to download.');
    }
  };

  useEffect(() => {
    const { roleId, _id: uid } = JSON.parse(localStorage.getItem('user'));
    getAllStoredData();

    Promise.allSettled([
      dispatch(getAffiliates()),
      dispatch(getDesksIbs()),
      dispatch(showAllCurrencies()),
      dispatch(getSubCRMUsers(roleId, uid)),
      dispatch(getCurrencyRates('USD')),
      dispatch(getBrands()),
    ]);
  }, []);

  useEffect(() => {
    async function fetchdata() {
      if (isPaginationDT) {
        localStorage.setItem(filtersStorageName, JSON.stringify(depositsFilters));
        setTableLoading(true);

        await refetchTable();

        setTableLoading(false);
      }
    };

    fetchdata();
  }, [page, rowsPerPage, isPaginationDT, depositsFilters, sortParams, userIdFromDetail]);

  useEffect(() => {
    if (exportFilterdDataToFile) {
      dispatch(updateTransactionState()); 
      setLoader(false);
    }
  }, [exportFilterdDataToFile]);

  const handleSort = async (sortField, sortDirection) => {
    const sort = generateSortParams(sortField, sortDirection);
    setSortParams(sort);
  };

  const clearRows = () => {
    setToggleCleared((prev) => (!prev));
    setSelectedTransactions([]);
  };

  const handleMassDelete = async () => {
    Swal.fire({
      title: 'Are you sure you want to Delete this transactions?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          const ids = selectedTransactions.map((transaction) => transaction._id);
          await dispatch(massDeleteTransactions(ids));
          clearRows();
        } 
      });
  };

  const handleTransationTableSelect = ({ selectedRows }) => {
    setSelectedTransactions(selectedRows);
  };

  const storeColumnConfig = (config) => {
    setColumnConfig(config);
    localStorage.setItem(columnsStorageName, JSON.stringify(config));
  };

  const columns = ExternalTransactionsSchema(
    setTransactionIdFilter,
    permissionName,
    currencies,
    transactionIdFilter,
    uidFilter,
    setUidFilter,
    nameFilter,
    setNameFilter,
    emailFilter, 
    secondEmailFilter,
    setEmailFilter,
    setSecondEmailFilter,
    setAssetFilters,
    assetFilters,
    amountRange,
    setAmountRange,
    handleAdditionalInfoModalToggle,
    handleAdditionalCommentModalToggle,
    setManagerFilters,
    managerFilters,
    realFilter,
    setRealFilter,
    statusFilter,
    setStatusFilter,
    isAdditionalInfoModalShown,
    isAdditionalCommentModalShown,
    setIsAdditionalInfoModalShown,
    setIsAdditionalCommentModalShown,
    resolveCurrentDepositTransaction,
    handleRevertTransaction,
    assignToShow, 
    depositsFilters,
    setDepositsFilters,
    columnConfig,
    timeInitiatedFilter,
    setTimeInitiatedFilter,
    updateIsReal,
    handleDeleteTransaction,
    isDeleted,
    setDebounceAmountRange,
    brandsFilters,
    setBrandsFilters,
    balanceTypeFilter,
    setBalanceTypeFilter,
    handleSort,
    accountIdFilter,
    setAccountIdFilter,
    sourceFilter, 
    setSourceFilter,
    ibdeskUserFilter,
    setIbdeskUserFilters,
    allIbDesks,
    allAffiliates,
    affiliateFilter,
    setAffiliateFilter,
  );

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className={!disableHeader && 'content-box'}>
        {!disableHeader && <h3>Deposits</h3>}
        <div className="action__btn-row">
          <div className="main_btn-row">
            <div className="secondary_btn-row">
              {secondaryButtonRow}
              {crmFilters && (
                <DatatableFilters 
                  ref={datatableFiltersRef}
                  filters={crmFilters} 
                  setFilters={setCRMFilters}
                  createFilter={createUserCRMFilter}
                  deleteFilter={deleteUserCRMFilter}
                  updateFilter={updateUserCRMFilter}
                  storageKey={filterIdStorageName}
                  pathname={pathname}
                /> 
              )}
              <button 
                type="button"
                className="btn-secondary_dark iconed"
                onClick={handleClear}
              >
                <CloseOutlinedIcon sx={{ fontSize: '20px' }} />
              </button>

              <button 
                type="button"
                className="btn-secondary_dark iconed"
                onClick={refresh}
              >
                <RefreshIcon sx={{ fontSize: '20px' }} />
              </button>
            </div>
            <div className="secondary_btn-row">
              <DatatableColumns setColumns={storeColumnConfig} columnConfig={columnConfig} defaultConfig={defaultColumns} />
            </div>
            <div>
              {disableHeader && <DownloadFile isPositionUnset handleExport={handleExportTransactions} />}
            </div>
          </div>
          {!disableHeader && <DownloadFile handleExport={handleExportTransactions} />}
          {(!disableHeader && isUserCanDeleteTransaction && selectedTransactions.length > 0) && (
          <div className="dt-actions__container">
            <div className="dt-actions__selected-counter">{`Actions: selected ${selectedTransactions.length} ${selectedTransactions.length > 1 ? 'records' : 'record'}`}</div>
            <div className="main_actions-row">
              <button 
                disabled={!selectedTransactions.length}
                type="button"
                className="secondary-btn"
                onClick={handleMassDelete}
              >
                Delete
              </button>
            </div>
          </div>
          )}
        </div>

        <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
          {
              isPaginationDT && (
                <DataTable
                  columns={columns}
                  data={transactionsData}
                  fixedHeader
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount}
                  paginationPerPage={rowsPerPage}
                  paginationRowsPerPageOptions={[25, 50, 100, 500]}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  paginationDefaultPage={page}
                  persistTableHead
                  highlightOnHover
                  onSelectedRowsChange={handleTransationTableSelect}
                  onColumnOrderChange={(newColumns) => setNewOrderedColumns(
                    newColumns,
                    columnConfig,
                    storeColumnConfig,
                  )}
                  clearSelectedRows={toggleCleared}
                  selectableRows
                  theme="solarizedd"
                  progressPending={tableLoading}
                  disabled={tableLoading}
                  progressComponent={<div className="datatable-loader__background" />}
                />
              )
            }
        </div>
      </div>
    </div>
  );
});

export default ExternalTransactions;

