import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faDownload, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import { BsTrashFill } from 'react-icons/bs';
import Swal from 'sweetalert2';
import { ModalContext } from '../../../context';
import { getKycAmlRequestsByUserId, setStatusToKycRequest, deleteKycAmlDoc } from '../../../redux/kycAml/kycAmlActions';
import { selectKycAmlRequests } from '../../../redux/kycAml/kycAmlSelectors';
import { downloadImage } from '../../../helpers/downloadImage';
import { formatDate } from '../../../DataTableSchemas/helper';
import styles from './KycAmlRequests.module.css';
import ErrorImage from '../../../assets/images/placeholder.jpg';
import useBlobImage from '../../../hooks/useBlobImage';

function KycAmlRequests({ userId }) {
  const dispatch = useDispatch();

  const kycAmlRequests = useSelector(selectKycAmlRequests);

  useEffect(() => {
    dispatch(getKycAmlRequestsByUserId(userId));
  }, [userId]);

  return (
    <div className={styles.wrapper}>
      {
        kycAmlRequests.map((kycRequest) => (
          <div key={kycRequest._id} className={styles.itemWrapper}>
            <div className={styles.itemHeaderWrapper}>
              <TitleWrapper kycRequest={kycRequest} />
              <ActionButtons kycRequest={kycRequest} userId={userId} />
            </div>
            <ImageWrapper imageUrl={kycRequest.fileUrl} />
            <br />
            <span className={styles.uploadedAtSpan}>{`Uploaded At: ${formatDate(new Date(kycRequest.updatedAt))}`}</span>
          </div>
        ))
      }
    </div>
  );
}

function ActionButtons({ kycRequest, userId }) {
  const dispatch = useDispatch();
  const { hideModal, showModal } = useContext(ModalContext);

  const [isEditMode, setIsEditMode] = useState(kycRequest.verifiedStatus === 'pending');

  const handleClickBtn = async (status) => {
    hideModal();

    Swal.fire({
      title: `Are you sure you want to ${status === 'verified' ? 'Verify' : 'Decline'} it?`,
      input: 'textarea',
      inputPlaceholder: 'Enter information/comment...',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async ({ isConfirmed, value }) => {
      if (isConfirmed) {
        await dispatch(setStatusToKycRequest(kycRequest._id, userId, status, value));
      }

      setIsEditMode(false);
      showModal({
        headerContent: <h3>KYC/AML Requests</h3>,
        bodyContent: <KycAmlRequests userId={userId} />,
      });
    });
  };

  const handleDeleteBtn = async () => {
    hideModal();

    Swal.fire({
      title: 'Are you sure you want to delete document?',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        await dispatch(deleteKycAmlDoc({ docsId: kycRequest._id, userId }));
      }

      setIsEditMode(false);
      showModal({
        headerContent: <h3>KYC/AML Requests</h3>,
        bodyContent: <KycAmlRequests userId={userId} />,
      });
    });
  };

  const handleEditBtn = () => {
    setIsEditMode(true);
  };

  const jsxCode = isEditMode ? (
    <div className={styles.btnsContainer}>
      <button className={styles.approveBtn} onClick={() => handleClickBtn('verified')} type="button">Approve</button>
      <button className={styles.declineBtn} onClick={() => handleClickBtn('declined')} type="button">Decline</button>
      <button type="button" className={styles.deleteIconBtn} onClick={handleDeleteBtn}>
        <BsTrashFill color="white" size={20} />
      </button>
    </div>
  ) : (
    <div className={styles.btnsContainer}>
      <button className={styles.editBtn} onClick={handleEditBtn} type="button">Edit</button>
      <button type="button" className={styles.deleteIconBtn} onClick={handleDeleteBtn}>
        <BsTrashFill color="white" size={20} />
      </button>
    </div>

  );

  return jsxCode;
}

function TitleWrapper({ kycRequest }) {
  const verifyStatuses = {
    pending: '#d0d31d',
    verified: '#02bb02',
    declined: '#e72c2c',
  };

  const verifyTypesAliases = {
    idCardFront: 'ID card: Front side photo',
    idCardBack: 'ID card: Back side photo',
    creditCardFront: 'Credit Card: Front side photo',
    creditCardBack: 'Credit Card: Back side photo',
    selfie: 'Selfie Photo',
    address: 'Address Proof',
  };

  return (
    <div className={styles.itemTitleWrapper}>
      <span className={styles.verifyType}>
        {verifyTypesAliases[kycRequest.type]}
      </span>
      <div>
        <span 
          className={styles.verifyStatus}
          style={{ color: verifyStatuses[kycRequest.verifiedStatus] }}
        >
          {kycRequest.verifiedStatus}
        </span>

        <FontAwesomeIcon
          icon={faCircleInfo}
          className="ms-1"
          size="sm"
          color={kycRequest.info ? '#6E7F95' : '#4a5668'}
          style={{ cursor: kycRequest.info ? 'pointer' : 'default', outline: 'none' }}
          data-tip={kycRequest.info ?? ''}
        />

        <ReactTooltip />

      </div>
    </div>
  );
};

function ImageWrapper({ imageUrl }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { image, isLoading, error } = useBlobImage(imageUrl);

  const toggleImageSize = () => setIsExpanded(!isExpanded);
  const toggleStatusClassName = isExpanded ? styles.imageExpanded : styles.imageNotExpanded;

  if (isLoading) {
    return (
      <div className={`${styles.imageWrapper} ${toggleStatusClassName}`}>
        <div className={styles.skeletonLoader} />
      </div>
    ); 
  }

  if (error) {
    return (
      <div className={`${styles.imageWrapper} ${toggleStatusClassName}`}>
        <img 
          onClick={toggleImageSize} 
          alt="KYC Document" 
          src={ErrorImage} 
          style={{ maxWidth: '90%', borderRadius: '10px' }}
        />
      </div>
    ); 
  }

  return (
    <div className={`${styles.imageWrapper} ${toggleStatusClassName}`}>
      <img 
        onClick={toggleImageSize} 
        alt={imageUrl || 'KYC Document'} 
        src={image} 
        style={{ maxWidth: '90%' }} 
      />
      <button 
        className={styles.downloadBtn} 
        onClick={() => downloadImage(image, imageUrl)} 
        type="button"
      >
        <FontAwesomeIcon icon={faDownload} />
      </button>
    </div>
  );
}

export default KycAmlRequests;
