import { ENV } from '../config/config';

export const savingsInvestmentColumns = [
  {
    name: 'ID',
    field: '_id',
    selected: true,
    order: 1,
  },
  {
    name: 'User',
    field: 'user',
    selected: true,
    order: 2,
  },
  {
    name: 'Invested Amount',
    field: 'investedAmount',
    selected: true,
    order: 3,
  },
  {
    name: 'Final Earning',
    field: 'finalEarnings',
    selected: true,
    order: 4,
  },
  {
    name: 'Period',
    field: 'period',
    selected: true,
    order: 5,
  },
  {
    name: 'Percent',
    field: 'pct',
    selected: true,
    order: 6,
  },
  {
    name: 'Start Date',
    field: 'createdAt',
    selected: true,
    order: 7,
  },
  {
    name: 'Release Date',
    field: 'releaseTime',
    selected: true,
    order: 8,
  },
  {
    name: 'Flexible',
    field: 'isFlexible',
    selected: true,
    order: 9,
  },
  {
    name: 'Closed',
    field: 'isReleased',
    selected: true,
    order: 10,
  },
  {
    name: 'Actions',
    field: '',
    selected: true,
    order: 11,
  },
];
