import React, {
  useContext, useEffect, useState,
} from 'react';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import {
  Box,
  IconButton,
  List, ListItem, ListItemText,
  Typography, Chip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import styled from 'styled-components';
import { exportFilteredDataToFile, deleteSalesStatus, updateSalesStatusPriority } from '../../redux/salesStatus/salesStatusActions';
import { AddSalesStatusModalBody, EditSalesStatusModalBody } from './components';
import { ModalContext } from '../../context';
import SegmentButtons from './SegmentButtons/SegmentButtons';
import { styles } from './SalesStatusStyles';
import { isStatusesHasChanged } from './helpers/isStatusesHasChanged';
import { DownloadFile } from '../../components';
import { BrandsSelector } from './components/BrandsSelector/BrandsSelector';

const StyledChip = styled(Chip)(() => ({
  '& .MuiChip-label': {
    paddingLeft: '4px',
    paddingRight: '4px',
  },
}));

function DraggableList({ initialData = [], currentPage, selectedBrand }) {
  const dispatch = useDispatch();
  const { showModal } = useContext(ModalContext);

  const [data, setData] = useState(initialData);
  const [draggingIndex, setDraggingIndex] = useState(null);

  useEffect(() => {
    let priority = 1;

    if (data) {
      const newPriorityList = data.map(({ _id }) => ({ _id, priority: priority++ }));
      const initialPriorityList = initialData.map(({ _id, priority }) => ({ _id, priority }));
      const isChanged = isStatusesHasChanged(initialPriorityList, newPriorityList);

      if (isChanged) {
        dispatch(updateSalesStatusPriority(newPriorityList, currentPage, selectedBrand));
      }
    }
  }, [data]);

  // handle update for initialData
  useEffect(() => {
    if (initialData) {
      setData(initialData);
    }
  }, [initialData]);

  const handleDragStart = (event, index) => {
    setDraggingIndex(index);
    event.dataTransfer.setData('dragIndex', index);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, dropIndex) => {
    event.preventDefault();
    const dragIndex = parseInt(event.dataTransfer.getData('dragIndex'), 10);
    const newData = [...data];
    const [movedItem] = newData.splice(dragIndex, 1);
    newData.splice(dropIndex, 0, movedItem);
    setData(newData);
    setDraggingIndex(null);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed === true) {
        dispatch(deleteSalesStatus(id, currentPage, selectedBrand));
      }
    });
  };

  const handleEdit = (id) => showModal({ 
    bodyContent: <EditSalesStatusModalBody id={id} currentPage={currentPage} brandId={selectedBrand} />, 
    headerContent: <h3>Edit Status</h3>, 
  });

  return (
    <Box sx={styles.DraggableListBox}>
      <List sx={styles.DraggableList}>
        {/* Header */}
        <Box sx={styles.DraggableListHeader}>
          <Typography sx={styles.TypographyPriority}>Priority</Typography>
          <Typography sx={styles.TypographyStatusPreview}>Status Preview</Typography>
          <Typography sx={styles.TypographyName}>Name</Typography>
          <Typography sx={styles.TypographyAction}>Action</Typography>
        </Box>

        {/* Draggable Items */}
        {data.map((item, index) => (
          <ListItem
            key={item._id}
            draggable
            onDragStart={(event) => handleDragStart(event, index)}
            onDragOver={handleDragOver}
            onDrop={(event) => handleDrop(event, index)}
            sx={styles.DraggableListItem(index, draggingIndex)}
          >
            {/* Drag Icon and priority title */}
            <Box sx={styles.PriorityWrapper}>
              <IconButton sx={styles.DragIconButton}>
                <DragIndicatorIcon sx={styles.DragIcon(index, draggingIndex)} />
              </IconButton>
              <ListItemText sx={styles.PriorityTitle} primary={item.priority} />
            </Box>

            {/* Status Preview */}
            <Box sx={styles.StatusPreviewWrapper}>
              <div style={styles.StatusPreview}>
                <StyledChip sx={styles.StatusPreviewChip(item)} label={item.name.toUpperCase()} clickable={false} />
                <span style={styles.StatusPreviewSpan}>{item.color}</span>
              </div>
            </Box>

            {/* Name */}
            <ListItemText sx={styles.NameTitle} primary={item.name} />
            
            {/* Actions */}
            <Box sx={styles.ActionsBox}>
              <IconButton sx={styles.EditActionsBtn} onClick={() => handleEdit(item._id)}>
                <EditIcon sx={styles.EditIcon} />
              </IconButton>
              <IconButton sx={styles.DeleteActionsBtn} onClick={() => handleDelete(item._id)}>
                <DeleteIcon sx={styles.DeleteIcon} />
              </IconButton>
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

function SalesStatus() {
  const { showModal } = useContext(ModalContext);

  const [activeButton, setActiveButton] = useState('sales-list');
  const [selectedBrand, setSelectedBrand] = useState('');

  const { statuses } = useSelector((state) => state?.salesStatus);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const isUserCanAddSalesStatuses = permissionName && permissionName.length > 0 && permissionName.includes('add_sales_status');

  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const handleExportStatuses = async (fileType) => {
    if (statuses.length) {
      toast.success('Data export in progress. Please wait while we prepare the file.', {
        autoClose: 2000,
      });
      exportFilteredDataToFile(fileType, activeButton, selectedBrand); 
    } else {
      toastError('There is nothing to download.');
    }
  };

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>Sales Statuses</h3>
        <div className="action__btn-row">
          <div className="main_btn-row w-100">
            <div className="secondary_btn-row w-100 d-flex justify-content-between">
              {isUserCanAddSalesStatuses && (
                <button 
                  type="button" 
                  className="btn-primary_light"
                  onClick={() => showModal({ 
                    bodyContent: <AddSalesStatusModalBody currentPage={activeButton} brandId={selectedBrand} />, 
                    headerContent: <h3>Add Status</h3>, 
                  })}
                >
                  <FontAwesomeIcon icon={faPlus} size="sm" />
                  Add Status
                </button>
              )}
              <BrandsSelector selectedBrand={selectedBrand} setSelectedBrand={setSelectedBrand} />
            </div>
          </div>
          <div style={styles.SegmentButtonsWrapper}>
            <SegmentButtons activeButton={activeButton} setActiveButton={setActiveButton} selectedBrand={selectedBrand} />
            <DownloadFile isPositionUnset handleExport={handleExportStatuses} />
          </div>
        </div>
        <div style={styles.DraggableListWrapper}>
          <DraggableList initialData={statuses} currentPage={activeButton} selectedBrand={selectedBrand} />
        </div>
      </div>
    </div>
  );
}

export default SalesStatus;
