import React, { useEffect } from 'react';
import useIsSuperUser from '../hooks/isSuperAdmin';
import { ENV } from '../config/config';

function AuthLayout({ title, children }) {
  const isSuperUser = useIsSuperUser();

  useEffect(() => {
    if (title) document.title = title;
    else document.title = 'RelateXpert';
    if (isSuperUser) document.title = ENV.brandName;
  }, [title, isSuperUser]);

  useEffect(() => {
    if (localStorage.token) {
      window.location.href = '/';
    }
  }, []);

  return (
    <div className="auth-layout">
      {children}
    </div>
  );
}

export default AuthLayout;
