import { addOpacityToHex } from '../../helpers/addOpacityToHex';

export const styles = {
  SegmentButtonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
  },
  DraggableListWrapper: {
    border: '1px solid #44474E',
    position: 'relative',
    borderRadius: '5px',
    marginTop: '1rem',
  },
  DraggableListBox: {
    color: '#fff',
    borderRadius: 2,
    margin: 'auto',
  },
  DraggableList: { width: '100%', paddingTop: '0px', paddingBottom: '0px' },
  DraggableListHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #44474E',
    fontWeight: 'bold',
  },
  TypographyPriority: { flex: 1, borderRight: '1px solid #44474E', padding: '8px 7px 8px 17px' },
  TypographyStatusPreview: { flex: 2, borderRight: '1px solid #44474E', padding: '8px 12px' },
  TypographyName: { flex: 2, borderRight: '1px solid #44474E', padding: '8px 12px' },
  TypographyAction: { flex: 1, textAlign: 'center', padding: '8px 2px' },
  DraggableListItem: (index, draggingIndex) => ({
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #44474E',
    padding: '0px',
    backgroundColor:
      draggingIndex === index ? '#c4c6d04d' : 'transparent',
    transition: 'background-color 0.2s ease',
    '&:hover': {
      backgroundColor: draggingIndex === index ? '#c4c6d04d' : '#c4c6d01a',
      cursor: 'pointer',
    },
  }),
  PriorityWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '0px 10px',
    borderRight: '1px solid #44474E',
    gap: '6px',
  },
  DragIconButton: { cursor: 'grab', padding: '8px 0px' },
  DragIcon: (index, draggingIndex) => ({
    color: draggingIndex === index ? '#fff' : '#c4c6d052',
  }),
  PriorityTitle: { flex: 1, color: '#C4C6D0' },
  StatusPreviewWrapper: {
    flex: 2,
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    padding: '8px',
    borderRight: '1px solid #44474E',
  },
  StatusPreview: {
    display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', 
  },
  StatusPreviewChip: (item) => ({
    color: item.color,
    background: addOpacityToHex(item.color, 0.08),
    height: '24px',
    borderRadius: '2px',
  }),
  StatusPreviewSpan: { color: '#c4c6d052' },
  NameTitle: {
    flex: 2,
    color: '#E2E2E9',
    borderRight: '1px solid #44474E',
    padding: '8px',
    margin: 0,
  },
  ActionsBox: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    gap: 1,
    alignItems: 'center',
  },
  EditActionsBtn: {
    height: '32px',
    width: '32px',
    borderRadius: '2px',
    '&:hover': { background: '#bec6dc14' },
  },
  DeleteActionsBtn: {
    height: '32px',
    width: '32px',
    borderRadius: '2px',
    '&:hover': { background: '#bec6dc14' },
  },
  EditIcon: {
    color: '#fff',
    height: '18px',
    width: '18px',
  },
  DeleteIcon: {
    color: '#fff',
    height: '18px',
    width: '18px',
  },
};
