export const openOrdersColumns = [
  {
    name: 'ID',
    field: 'customId',
    selected: true,
    order: 1,
  },
  {
    name: 'Client',
    field: 'user.firstName,user.lastName',
    selected: true,
    order: 2,
  },
  {
    name: 'Online',
    field: 'user.isOnline',
    selected: true,
    order: 3,
  },
  {
    name: 'Type',
    field: 'tradeType',
    selected: true,
    order: 4,
  },
  {
    name: 'Assigned To',
    field: 'user.assignedTo.firstName,user.assignedTo.lastName',
    selected: true,
    order: 5,
  },
  {
    name: 'Symbol',
    field: 'setting.symbol',
    selected: true,
    order: 6,
  },
  {
    name: 'Open Price',
    field: 'tradeStartPrice',
    selected: true,
    order: 7,
  },
  {
    name: 'Volume',
    field: 'position',
    selected: true,
    order: 8,
  },
  {
    name: 'Time Opened',
    field: 'timeOpened',
    selected: true,
    order: 9,
  },
  {
    name: 'TP',
    field: 'takeProfitPrice',
    selected: true,
    order: 10,
  },
  {
    name: 'SL',
    field: 'stopLossPrice',
    selected: true,
    order: 11,
  },
  {
    name: 'PNL',
    field: '',
    selected: true,
    order: 12,
  },
  {
    name: 'Swap',
    field: 'swap',
    selected: true,
    order: 13,
  },
  {
    name: 'Commission',
    field: 'commission',
    selected: true,
    order: 14,
  },
  {
    name: 'Actions',
    field: '',
    selected: true,
    order: 15,
  },
];
