import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import 'react-calendar/dist/Calendar.css';
import DataTable from 'react-data-table-component';
import {
  faPlus, faCheck, faTimes, faColumns, faRefresh, 
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { displayAdminRoles } from '../../redux/roles/roleActions';
import { CRMUsersSchema } from '../../DataTableSchemas/CRMUsersSchema/CRMUsersSchema';
import {
  createCRMFilter,
  deleteCRMFilter,
  updateCRMFilter,
  exportFilteredDataToFile,
  updateExportDataState,
  editCRMUsers,
  getCRMUsersByQuery,
  getDesksIbs,
} from '../../redux/crmUser/crmUserActions';
import { getAllPermissionTemplates } from '../../redux/permissions/permissionActions';
import { crmUsersColumns } from '../../columnsDefaultConfig';
import { DatatableFilters, DatatableColumns } from '../../components';
import { DownloadFile } from '../../components/DownloadFile';
import { DatatableCRMActions } from '../../components/DatatableActions/DatatableCRMActions';
import { useDebounce } from '../../hooks/useDebounce';
import socket from '../../services/socket';
import { setNewOrderedColumns } from '../../DataTableSchemas/helper';
import { generateSortParams } from '../../helpers/sortHelper';

function CRMUsers() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [loader, setLoader] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [agentRoles, setAgentRoles] = useState([]);
  const [fullname, setFullname] = useState();
  const [searchEmail, setSearchEmail] = useState();
  const [rolesToShow, setRolesToShow] = useState([]);
  const [ibdeskFilterPreset, setIbdeskFilterPreset] = useState([]);
  const [idFilter, setIdFilter] = useState('');
  const [salesRoleFilterPreset, setSalesRoleFilterPreset] = useState([]);
  const [crmUsersFilters, setCrmUsersFilters] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [sortParams, setSortParams] = useState(JSON.stringify({ createdAt: -1 }));
  const [selectedUsersIds, setSelectedUsersIds] = useState([]);
  const [type] = useState('0');
  const [show] = useState(true);
  const [columnConfig, setColumnConfig] = useState(crmUsersColumns);
  const [socketIdToCRMUserId, setSocketIdToCRMUserId] = useState({});

  const filtersStorageName = 'DataTable_users/crm_users_filters'; 
  const filterIdStorageName = 'DataTable_users/crm_users_filter_id'; 
  const columnsStorageName = 'DataTable_users/crm_users_columns';
  const paginationStorageName = 'DataTable_users/crm_users_pagination';
  const crmUsersFilterJSON = localStorage.getItem(filtersStorageName);
  const crmUsersColumnsJSON = localStorage.getItem(columnsStorageName);

  const datatableFiltersRef = useRef(null);

  const agentUsers = useSelector((state) => state.users?.allAgents);
  const { paginatedData: crmUsers, totalCount } = useSelector((state) => state.crmUser?.tableCrmUsers);
  const getAgentRoles = useSelector((state) => state.role?.adminRoles);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);
  const exportFilterdDataToFile = useSelector((state) => state.crmUser.exportFilterdDataToFile);
  const permissionTemplates = useSelector((state) => state.permission.permissionTemplates);
  const allIbDesks = useSelector((state) => state.crmUser?.deskIbs);

  const debounceCallback = ({ value, key }) => {
    if ((value.length >= 0 || Array.isArray(value)) && crmUsersFilters[key] !== value) {
      setCrmUsersFilters((prev) => ({ ...prev, [key]: value }));
    }
  };

  useDebounce(fullname, 1000, (value) => debounceCallback({ value, key: 'fullName' }));
  useDebounce(searchEmail, 1000, (value) => debounceCallback({ value, key: 'email' }));
  useDebounce(idFilter, 1000, (value) => setCrmUsersFilters((prev) => ({ ...prev, id: value })));

  const fireSwal = (title) => Swal.fire({
    title,
    html: '',
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes',
  });

  useEffect(() => {
    dispatch(getDesksIbs());
  }, []);

  useEffect(() => {
    if (getAgentRoles?.length > 0) {
      setAgentRoles(getAgentRoles);
    }
  }, [agentUsers, getAgentRoles]);

  const setStoredColumnsData = () => {
    if (crmUsersColumnsJSON) {
      const columns = JSON.parse(crmUsersColumnsJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  const setStoredPagination = () => {
    const crmRowsJSON = localStorage.getItem(paginationStorageName);
    if (crmRowsJSON) {
      const filterRows = JSON.parse(crmRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
      setPage(filterRows.page || 1);
    }
    
    setIsPaginationDT(true);
  };

  const handleUserSelectedChange = async ({ selectedRows }) => {
    const ids = await selectedRows.map((row) => row._id);
    setSelectedUsersIds(ids);
  };

  const setCRMFilters = (filter) => {
    const {
      fullName, email, role, salesRole, ibdesk,
    } = filter;

    setFullname(fullName || null);
    setSearchEmail(email || null);
    setRolesToShow(role);
    setSalesRoleFilterPreset(salesRole);
    setIbdeskFilterPreset(ibdesk);
    setCrmUsersFilters({
      fullName, email, role, salesRole, ibdesk,
    });
    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const setStoredFilterData = () => {
    if (crmUsersFilterJSON) {
      const filters = JSON.parse(crmUsersFilterJSON);
      setCrmUsersFilters(filters || {});

      setFullname(filters.fullName || null);
      setSearchEmail(filters.email || null);
      setRolesToShow(filters.role || []);
      setSalesRoleFilterPreset(filters.salesRole || []);
      setIbdeskFilterPreset(filters.ibDesk || []);
    }
  };

  const getAllStoredData = () => {
    setStoredPagination();
    setStoredFilterData();
    setStoredColumnsData();
  };

  const refresh = async () => {
    setTableLoading(true);
    await getAllStoredData();
    setTableLoading(false);
  };
 
  const handlePageChange = (page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: rowsPerPage, page }));
    setPage(page);
  };

  const handleRowsPerPageChange = async (currentRowsPerPage, page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage, page }));
    setRowsPerPage(currentRowsPerPage);
  };

  const toggleColumn = (name) => {    
    const updatedColumns = columnConfig.map((column) => {
      if (column.name === name) {
        return { ...column, selected: !column.selected };
      }

      return column;
    });

    setColumnConfig(updatedColumns);
    localStorage.setItem(columnsStorageName, JSON.stringify(updatedColumns));
  };

  const handleClear = () => {
    setFullname(null);
    setSearchEmail(null);
    setRolesToShow([]);
    setSalesRoleFilterPreset([]);
    setIbdeskFilterPreset([]);
    setCrmUsersFilters({});
    localStorage.removeItem(filtersStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);
    const data = {
      name,
      crmUserId,
      pathname,
      fullName: filters.fullName || '',
      email: filters.email || '',
      role: filters.role || [],
      salesRole: filters.salesRole || [],
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toast.error('Select atleast one filter to complete this action.', {
        autoClose: 1000,
      });
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        fullName: filters.fullName || '',
        email: filters.email || '',
        role: filters.role || [],
        salesRole: filters.salesRole || [],
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toast.error('Select atleast one filter to complete this action.', {
        autoClose: 1000,
      });
    }
  };
  
  const handleExportTransactions = async (fileType) => {
    const columns = columnConfig.filter((obj) => obj.selected && obj.field);
    if (crmUsers.length && columns.length) {
      toast.success('Export in progress. Please wait.', {
        autoClose: 1000,
      });
      dispatch(exportFilteredDataToFile(crmUsers, columns, fileType, selectedUsersIds)); 
    } else {
      toast.error('There is nothing to download.', {
        autoClose: 1000,
      });
    }
  };

  useEffect(() => {
    getAllStoredData();

    Promise.allSettled([
      dispatch(displayAdminRoles()),
      dispatch(getAllPermissionTemplates()),
    ]);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      socket.emit('getOnlineCRMUsers');
    }, 1000);
    
    socket.on('recieveOnlineCRMUsers', ({ onlineCRMUsers = {} }) => {
      setSocketIdToCRMUserId(onlineCRMUsers);
    });

    return () => {
      socket.off('recieveOnlineCRMUsers');
    };
  }, [socket]);

  useEffect(() => {
    async function fetchData() {
      if (isPaginationDT) {
        localStorage.setItem(filtersStorageName, JSON.stringify(crmUsersFilters));
        setTableLoading(true);
  
        await dispatch(getCRMUsersByQuery({
          page, limit: rowsPerPage, query: crmUsersFilters, sortParams,
        }));
  
        setTableLoading(false);
      }
    }
   
    fetchData();
  }, [page, rowsPerPage, isPaginationDT, crmUsersFilters, sortParams]);

  useEffect(() => {
    if (exportFilterdDataToFile) {
      dispatch(updateExportDataState()); 
      setLoader(false);
    }
  }, [exportFilterdDataToFile]);

  const assignRightsInBulk = async (data, swalTitle) => {
    if (selectedUsersIds.length) {
      try {
        const result = await fireSwal(swalTitle);
        if (result.isConfirmed) {
          setLoader(true);
          setSelectedUsersIds([]);
          await dispatch(editCRMUsers(data));
          refresh();
          return true;
        }
      } finally {
        setLoader(false);
      }
    } else {
      toast.error('Select at least one CRM user to complete this action.', {
        autoClose: 1000,
      });
    }

    return false;
  };

  const handleSort = async (sortField, sortDirection) => {
    const sort = generateSortParams(sortField, sortDirection);
    setSortParams(sort);
  };

  const storeColumnConfig = (config) => {
    setColumnConfig(config);
    localStorage.setItem(columnsStorageName, JSON.stringify(config));
  };

  const columns = CRMUsersSchema({
    fullname,
    searchEmail,
    agentRoles,
    rolesToShow, 
    setRolesToShow,
    crmUsersFilters, 
    setCrmUsersFilters,
    columnConfig,
    setFullname,
    setSearchEmail,
    socketIdToCRMUserId,
    handleSort,
    salesRoleFilterPreset,
    setSalesRoleFilterPreset,
    ibdeskToShow: allIbDesks,
    setIbdeskFilterPreset,
    ibdeskFilterPreset,
    idFilter,
    setIdFilter,
  });

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>CRM Users</h3>
        <div className="action__btn-row">
          <div className="main_btn-row">
            <div className="secondary_btn-row">
              {show && permissionName && permissionName.length && permissionName.includes('add_crm_user') ? (
                <Link 
                  to="/add-admin"
                  className="btn-primary_light"
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    size="sm"
                  />
                  Add New
                </Link>
              ) : null}
            </div>
            <div className="secondary_btn-row">
              {crmFilters 
                ? (
                  <DatatableFilters 
                    ref={datatableFiltersRef}
                    filters={crmFilters} 
                    setFilters={setCRMFilters}
                    createFilter={createUserCRMFilter}
                    deleteFilter={deleteUserCRMFilter}
                    updateFilter={updateUserCRMFilter}
                    storageKey={filterIdStorageName}
                    pathname={pathname}
                  />
                ) : null}
              <button 
                className="btn-secondary_dark iconed" 
                type="button"
                onClick={handleClear}
              > 
                <CloseOutlinedIcon sx={{ fontSize: '20px' }} />
              </button>
              <button 
                className="btn-secondary_dark iconed" 
                type="button" 
                onClick={refresh}
              >
                <RefreshIcon sx={{ fontSize: '20px' }} />
              </button>
            </div>
            <div className="secondary_btn-row">
              <DatatableColumns setColumns={storeColumnConfig} columnConfig={columnConfig} defaultConfig={crmUsersColumns} />
            </div>
          </div>


          <DownloadFile handleExport={handleExportTransactions} />
          {
                  (
                    permissionName 
                    && permissionName.length 
                    && permissionName.includes('edit_crm_user')
                    && selectedUsersIds.length > 0
                  ) ? (
                    <DatatableCRMActions
                      ids={selectedUsersIds} 
                      templates={permissionTemplates} 
                      editCRMUsers={assignRightsInBulk}
                    />
                    ) : null
                }
        </div>
        <br />
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
          {
                isPaginationDT
                  && (
                    <DataTable
                      rows={crmUsers?._id}
                      columns={columns}
                      data={crmUsers}
                      fixedHeader
                      pagination
                      paginationServer
                      highlightOnHover
                      paginationPerPage={rowsPerPage}
                      paginationTotalRows={totalCount}
                      paginationRowsPerPageOptions={[25, 50, 100, 500]}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      onChangePage={handlePageChange}
                      paginationDefaultPage={page}
                      persistTableHead
                      selectableRows
                      onSelectedRowsChange={handleUserSelectedChange}
                      onColumnOrderChange={(newColumns) => setNewOrderedColumns(
                        newColumns,
                        columnConfig,
                        storeColumnConfig,
                      )}
                      selectableRowsHighlight
                      selectableRowsVisibleOnly
                      theme="solarizedd"
                      className="specific-field-table custome-table-scroll"
                      progressPending={tableLoading}
                      disabled={tableLoading}
                      progressComponent={<div className="datatable-loader__background" />}
                    />
                  )
              }
        </div>
      </div>
    </div>
  );
}

export default CRMUsers;
