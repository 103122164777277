import { toast } from 'react-toastify';
import {
  SHOW_ALL_ADMIN_COMMENTS, GET_ADMIN_COMMENTS, ADD_ADMIN_COMMENT, EDIT_ADMIN_COMMENT, DELETE_ADMIN_COMMENT, TOGGLE_STATE, DELETE_ADMIN_COMMENT_BY_USERID,
  ERROR_STATE,
} from './adminCommentTypes';
import { apiHelper } from '../apiHelper';
import { UPDATE_COMMENTS } from '../users/userTypes';

export const showAllAdminComments = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/admin-comment', '');
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: SHOW_ALL_ADMIN_COMMENTS,
        payload: data,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const getAdminComments = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/admin-comment/get-comments/${id}`, '');
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: GET_ADMIN_COMMENTS,
        payload: data?.adminComments,
      });
    }
  } catch (error) {
    console.log('🚀 ~ getAdminComments ~ error:', error);
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const addAdminComment = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/admin-comment/add', data);

    if (res?.data) {
      const { data } = res;
      dispatch({
        type: ADD_ADMIN_COMMENT,
        payload: data?.adminComment_ ?? {},
      });

      dispatch({
        type: UPDATE_COMMENTS,
        payload: data?.adminComment_ ?? {},
      });
    } else {
      dispatch({
        type: ERROR_STATE,
      });
    }
  } catch (error) {
    // console.log(error.message);
    toast.error(error.message);
  }
};

export const editAdminComment = (id, data) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/admin-comment/${id}`, data);
    if (res?.data) {
      const { data } = res;

      dispatch({
        type: EDIT_ADMIN_COMMENT,
        payload: data.adminComment,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const deleteAdminComment = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/admin-comment/${id}`, '');
    if (res?.data) {
      const { data } = res;

      dispatch({
        type: DELETE_ADMIN_COMMENT,
        payload: data?.adminComment_._id,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const deleteAdminCommentsByUserIds = (userIds) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/admin-comment/delete-user-comments', { userIds });
    if (res?.data) {
      dispatch({
        type: DELETE_ADMIN_COMMENT_BY_USERID,
        payload: { userIds },
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const deleteAdminComments = (apiData) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', '/api/admin-comment/delete-comments', apiData);
    if (res?.data) {
      dispatch(getAdminComments(apiData.userId));
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const updateAdminCommentState = () => async (dispatch) => {
  try {
    dispatch({
      type: TOGGLE_STATE,
    });
  } catch (error) {
    // console.log(error.response.message);
  }
};

export const pinComment = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('patch', `/api/admin-comment/pin-comment/${id}`, '');
    if (res?.data?.success) {
      dispatch(getAdminComments(res.data.adminComment.userId));
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};
