import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLeadsReports } from '../../redux/leadsReport/leadsReportActions';

const columns = [
  {
    name: 'Error Length',
    selector: (row) => row?.length,
    sortable: true,
  },
  {
    name: 'Uploaded At',
    selector: (row) => new Date(row?.createdAt).toLocaleString(),
    sortable: true,
  },
  {
    name: 'Uploaded By',
    selector: (row) => (row?.crmUserId ? `${row?.crmUserId?.firstName} ${row?.crmUserId?.lastName}` : '-'),
    sortable: true,
  },
  {
    name: 'Actions',
    cell: (row) => (
      <div>
        <a href={`/leads-report/${row?._id}`} className="btn btn-primary">
          <FontAwesomeIcon
            icon={faEye}
            color="white"
          />
        </a>
      </div>
    ),
  },
];

export default function LeadsReports() {
  const dispatch = useDispatch();

  const leadsReports = useSelector((state) => state?.leadsReport?.leadsReportsData);

  useEffect(() => {
    dispatch(getLeadsReports());
  }, []);

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>Leads Upload Reports</h3>
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
          <DataTable
            columns={columns}
            data={leadsReports ?? []}
            highlightOnHover
            persistTableHead
            theme="solarizedd"
          />
        </div>
      </div>
    </div>
  );
}
