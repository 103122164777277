import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion } from 'react-bootstrap';
import { BsTrashFill } from 'react-icons/bs';
import { UserPhoto } from './UserPhoto';
import { deleteChat } from '../../../redux/chat/chatActions';

export function ChatList({ userId, chats = {}, setActiveChat }) {
  const dispatch = useDispatch();
  const isOneUser = useMemo(() => Object.keys(chats).length === 1 && !!chats[userId], [chats, userId]);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const hasDeleteChatPermission = useMemo(() => permissionName?.includes('delete_chat'), [permissionName]);

  const setMsgCounter = (id, messages = []) => {
    let count = 0;

    messages.forEach((message) => {
      if (message.sender !== id && !message.isRead) count += 1;
    });

    return count;
  };

  const setAccordionMsgCounter = (id, chats) => {
    let count = 0;

    chats?.forEach((chat) => {
      chat.messages.forEach((message) => {
        if (message.sender !== id && !message.isRead) count += 1;
      });
    });

    return !!count && <span className="message-counter">{count}</span>;
  };

  const deleteChatHandler = (event, chat) => {
    event.stopPropagation();
    if (!chat?._id) return;
    dispatch(deleteChat(chat._id));
  };

  const setChatItem = (chat, index) => {
    const counter = setMsgCounter(chat?.crmUser, chat?.messages);
    const lastMessage = chat?.messages?.at(-1);

    return (
      <div key={index} onClick={() => setActiveChat(chat?._id)}>
        <div className="friend-drawer friend-drawer--onhover">
          {!!counter && <div className="message-counter">{counter}</div>}
          {!!chat?.userData && <UserPhoto imageId={chat?.userData.profileImage} />}
          <div className="text">
            <h6>{`${chat?.userData?.firstName} ${chat?.userData?.lastName}`}</h6>
            <p className="text-muted text-truncate">
              {lastMessage ? lastMessage.content : ''}
            </p>
            <span className="time text-muted small">{lastMessage ? new Date(lastMessage.createdAt).toLocaleString() : ''}</span> 
          </div>
          {hasDeleteChatPermission && (
          <div className="chatListBinIconContainer">
            <BsTrashFill role="button" color="white" size={18} onClick={(e) => deleteChatHandler(e, chat)} />  
          </div>
          )}
        </div>
        <hr />
      </div>
    );
  };

  return (
    <div className="chat-profile-container border-right pe-0">
      {!!Object.keys(chats).length
        && (
          isOneUser ? chats[userId].map((chat) => (
            setChatItem(chat, chat._id)
          )) : (
            <Accordion className="chat-accordion-list" defaultActiveKey="0">
              {Object.keys(chats).map((id) => (
                <Accordion.Item key={id} eventKey={id}>
                  <Accordion.Header>
                    {`${chats[id][0]?.crmUserData?.firstName} ${chats[id][0]?.crmUserData?.lastName}`}
                    {setAccordionMsgCounter(chats[id][0]?.crmUser, chats[id])}
                  </Accordion.Header>
                  <Accordion.Body>
                    {chats[id].map((chat) => (setChatItem(chat, chat._id)))}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          )
        )}
    </div>
  );
};
