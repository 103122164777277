import React, { useEffect } from 'react';
import useIsSuperUser from '../hooks/isSuperAdmin';
import { ENV } from '../config/config';

function NotFound() {
  const isSuperUser = useIsSuperUser();
  document.title = 'Page Not Found';
  
  useEffect(() => {
    if (isSuperUser) document.title = ENV.brandName;
  }, [isSuperUser]);

  return (
    <div className="col-lg-9 col-md-8">
      <div className="content-wrapper">
        <div className="content-box">
          <h3>Error 404. Not Found</h3>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
