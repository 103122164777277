import { toast } from 'react-toastify';
import {
  SHOW_ALL_AFFILIATE_RULES,
  GET_AFFILIATE_RULES,
  GET_AFFILIATE_RULE,
  ADD_AFFILIATE_RULE,
  DELETE_AFFILIATE_RULE,
  TOGGLE_AFFILIATE_RULE_STATE,
  ERROR_AFFILIATE_RULE_STATE,
} from './affiliateRuleTypes';
import { apiHelper } from '../apiHelper';

export const showAllAffiliateRules = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/affiliate-rule', '');
    if (res?.data?.affiliateRules) {
      const { data } = res;
      dispatch({
        type: SHOW_ALL_AFFILIATE_RULES,
        payload: data.affiliateRules,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const getAffiliateRules = (id, sortParams = '{"createdAt": -1}') => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/affiliate-rule/get-rules/${id}`, '', { sortParams });
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: GET_AFFILIATE_RULES,
        payload: data?.affiliateRules,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const getAffiliateRule = (id, data) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/affiliate-rule/${id}`, data);
    if (res?.data) {
      const { data } = res;
      toast.success(res.data.message);
      dispatch({
        type: GET_AFFILIATE_RULE,
        payload: data?.affiliateRule,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const addAffiliateRule = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/affiliate-rule/add', data);

    if (res?.data) {
      const { data } = res;
      toast.success(res.data.message);
      dispatch({
        type: ADD_AFFILIATE_RULE,
        payload: data?.affiliateRule_,
      });
    } else {
      dispatch({
        type: ERROR_AFFILIATE_RULE_STATE,
      });
    }
  } catch (error) {
    // console.log(error.message);
    toast.error(error.message);
  }
};

export const editAffiliateRule = (id, bodyData) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/affiliate-rule/${id}`, bodyData);
    if (res?.data) {
      toast.success(res.data.message);
      dispatch(getAffiliateRules(bodyData.affiliateId));
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const deleteAffiliateRule = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/affiliate-rule/${id}`, '');
    if (res?.data) {
      const { data } = res;
      toast.success(res.data.message);
      dispatch({
        type: DELETE_AFFILIATE_RULE,
        payload: data?.affiliateRule_._id,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const deleteAffiliateRules = (apiData) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', '/api/affiliate-rule/delete-rules', apiData);
    if (res?.data) {
      toast.success(res.data.message);
      dispatch(getAffiliateRules(apiData.userId));
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const updateAffiliateRuleState = () => async (dispatch) => {
  try {
    dispatch({
      type: TOGGLE_AFFILIATE_RULE_STATE,
    });
  } catch (error) {
    // console.log(error.response.message);
  }
};
