import React, {
  useState, useMemo, useEffect, useContext, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dropdown, Button, Row, Col, InputGroup, DropdownButton, Form, 
} from 'react-bootstrap';
import Select from 'react-select';
import { massAddShift } from '../../../redux/tradingSetting/tradingSettingActions';
import { getCfdSettings } from '../../../redux/cfdSettings/cfdActions';
import { ModalContext } from '../../../context';
import { colourStyles } from '../../../helpers/styles';
import './style.css';
    
export function MassAddShiftModal({ userIds }) {
  const dispatch = useDispatch();
  const { hideModal } = useContext(ModalContext);
  const [selectedPair, selectPair] = useState('');
  const [shiftValue, setShiftValue] = useState('');
  const [isPositive, setIsPositive] = useState(true);
    
  const { cfdSettings: settings } = useSelector((state) => state.cfdSettings);
    
  useEffect(() => {
    dispatch(getCfdSettings());
  }, []);

  const isBtnDisabled = useMemo(
    () => !selectedPair.length || shiftValue === '' || Number.isNaN(shiftValue),
    [selectedPair, shiftValue],
  );

  const closeModal = () => {
    selectPair('');
    setIsPositive(true);
    setShiftValue('');
    hideModal();
  };
    
  const submit = () => {
    if (!selectedPair) return;
  
    const { brokerSymbol } = settings.find(({ symbol }) => symbol === selectedPair);
    const data = {
      userIds,
      pairName: selectedPair,
      shift: shiftValue,
      brokerSymbol,
      isPositive,
    };
    
    hideModal();
    dispatch(massAddShift(data));
  };
    
  return (
    <div className="withdrawal-data">
      <Row>
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2">Pair Name</label>
            <Select
              styles={colourStyles}
              options={settings.map((setting) => ({
                value: setting._id,
                label: setting.symbol,
              }))}
              isSearchable
              onChange={({ label }) => selectPair(label)}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-15">
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2">Shift Value</label>
            <div className="input-wrapper">
              <InputGroup className="mb-3 t-settings_input-group">
                <DropdownButton
                  variant="outline-secondary"
                  title={isPositive ? '+' : '-'}
                  id="input-group-dropdown-1"
                >
                  <Dropdown.Item onClick={() => setIsPositive(true)}>+</Dropdown.Item>
                  <Dropdown.Item onClick={() => setIsPositive(false)}>-</Dropdown.Item>
                </DropdownButton>
                <Form.Control 
                  value={shiftValue} 
                  onChange={({ target: { value } }) => {
                    const cleanValue = value.replace(/^0+/, '') || '0';
                    const numValue = Math.abs(Number(cleanValue));
                    setShiftValue(Number.isNaN(numValue) ? '' : numValue);
                  }}
                  onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                  aria-label="Text input with dropdown button"
                />
              </InputGroup>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Button variant="secondary" onClick={() => closeModal()}>Cancel</Button>
          <Button variant="primary" className="ms-2" disabled={isBtnDisabled} onClick={() => submit()}>Add</Button>
        </Col>
      </Row>
    </div>
  );
};
    
  
