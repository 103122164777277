import {
  GET_VOIPS, GET_USER_VOIPS, ACTIVATE_VOIP, ADD_VOIP,
  UPDATE_VOIP,
  DELETE_VOIP,
  GET_VOIP_PROVIDERS, 
  SET_SELECTED_PREFIX,
} from './voipTypes';

const initialState = {
  voips: [],
  userVoips: [],
  selectedVoip: null,
  selectedPrefix: null,
  totalCount: 0,
  voipProviders: [],
};

const voipReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_PREFIX:
      return {
        ...state,
        selectedPrefix: action.payload,
      };
    case GET_VOIPS:
      return {
        ...state,
        voips: action.payload.voips,
        totalCount: action.payload.totalCount,
      };

    case GET_USER_VOIPS: 
      return {
        ...state,
        userVoips: action.payload,
        selectedVoip: action.payload.find((voip) => voip.isActive === true) || null,
      };

    case ADD_VOIP:
      return {
        ...state,
        voips: [
          action.payload.voip,
          ...state.voips,
        ],
      };

    case ACTIVATE_VOIP: 
      return {
        ...state,
        userVoips: state.userVoips.map((voip) => {
          if (voip._id === action.payload) voip.isActive = true;
          if (voip._id === state.selectedVoip) voip.isActive = false;
          return voip;
        }),
        selectedVoip: state.userVoips.find((item) => item._id === action.payload),
      };

    case UPDATE_VOIP:

      const shouldIncludeInUserVoips = (
        action.payload.voip.isEnabled 
      && action.payload.voip.agent._id === action.payload.userId
      );
  
      const updatedUserVoips = state.userVoips.filter((voip) => voip._id !== action.payload.voip._id);
  
      if (shouldIncludeInUserVoips) {
        updatedUserVoips.push(action.payload.voip);
      }
      
      return {
        ...state,
        voips: state.voips.map((voip) => {
          if (voip._id === action.payload.voip._id) return action.payload.voip;
          return voip;
        }),
        userVoips: updatedUserVoips,
      };
    case DELETE_VOIP:   
      return {
        ...state,
        voips: state.voips.filter((voip) => voip._id !== action.payload),
        userVoips: state.userVoips.filter((voip) => voip._id !== action.payload),
      };
    case GET_VOIP_PROVIDERS:
      return {
        ...state,
        voipProviders: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default voipReducer;
