import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { deleteOtherPsp, updateOtherPsp } from '../redux/pspOthers/pspOthersActions';
import { prettyCutOutputByCellWidth, valueOutputCutLength } from './helper';
import { TooltipComponent } from '../components/TooltipComponent/TooltipComponent';
import { selectAllCountries } from '../redux/countries/countriesSelectors';
import { UsersInUseModal } from './UsersInUseModal/UsersInUseModal';
import { SortWrapper, useTableSorting } from '../helpers/sortHelper';
import SortIcon from '../components/SortIcon';

export const OtherPspSchema = (OtherPspModal, showModal, handleSort) => {
  const dispatch = useDispatch();
  const brands = useSelector((state) => state.brands);
  const countries = useSelector(selectAllCountries);
  const { currentUserPermissions } = useSelector((state) => state.crmUser);
  const isUserCanEditPspOther = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('psp_other_edit');
  const isUserCanDeletePspOther = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('psp_other_delete');

  const deleteAction = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteOtherPsp(id));
      }
    });
  };
  
  const handleChangeStatus = async (e, id) => {
    if (e.target.value) {
      const formDataToSend = new FormData();
      formDataToSend.append('status', e.target.value);
      await dispatch(updateOtherPsp(id, formDataToSend));
    }
  };

  const makeListOfRecords = ({ state = [], ids = [], nameField = '' }) => {
    let list = '';

    // eslint-disable-next-line no-restricted-syntax
    for (const record of state) {
      if (ids.includes(record._id)) {
        list += `${record[nameField]}, `;
      }
    }
    
    list = list.length ? list.slice(0, -2) : '';

    return list;
  };

  const preatyCountriesTooltipView = (listOfIncludesCountry) => {
    const listOfCountriesLenght = listOfIncludesCountry.split(',').length;
    const numberOfCutElems = 10;
    const listOfIncludesCountryTooltipView = listOfIncludesCountry.split(',').slice(0, numberOfCutElems).join(',');
    return `${listOfIncludesCountryTooltipView} ... and ${listOfCountriesLenght - numberOfCutElems} more.`;
  };

  const { sortFields, getSortIcon } = useTableSorting(handleSort);

  const columns = [
    {
      name: (
        <SortWrapper handleSort={() => sortFields('label')}>
          <div className="d-flex flex-row">
            <span>Other PSP</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('label')} />
            </button>
          </div>
        </SortWrapper>
      ),
      cell: (row) => (
        row?.label.length >= valueOutputCutLength.pspLabel
          ? (
            <TooltipComponent
              title={row?.label}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>{prettyCutOutputByCellWidth(row?.label, 'pspLabel')}</span>
              )}
            />
          )
          : (
            <span style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>{row.label}</span>
          )
      ),
      minWidth: '100px',
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('imageUrl')}>
          <div className="d-flex flex-row">
            <span>Image</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('imageUrl')} />
            </button>
          </div>
        </SortWrapper>
      ),
      cell: (row) => (<img src={`${process.env.REACT_APP_SERVER_URL}/images/${row.imageUrl}`} alt="row.img" height="40px" width="100%" />),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('brandIds')}>
          <div className="d-flex flex-row">
            <span>Brands</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('brandIds')} />
            </button>
          </div>
        </SortWrapper>
      ),
      cell: ({ brandIds }) => {
        const listOfBrands = makeListOfRecords({ state: brands, ids: brandIds, nameField: 'name' });
        if (listOfBrands.length >= valueOutputCutLength.pspBrands) {
          return (
            <TooltipComponent
              title={listOfBrands}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                  {prettyCutOutputByCellWidth(listOfBrands, 'pspBrands')}
                </span>
              )}
            />
          );
        }
        return (
          <span style={{ whiteSpace: 'nowrap' }}>
            {listOfBrands}
          </span>
        );
      },
      minWidth: '100px',
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('brandIds')}>
          <div className="d-flex flex-row">
            <span>Included Countries</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('brandIds')} />
            </button>
          </div>
        </SortWrapper>
      ),
      cell: ({ includedCountries }) => {
        const listOfIncludesCountry = makeListOfRecords({ state: countries, ids: includedCountries, nameField: 'nicename' });

        if (listOfIncludesCountry.length >= valueOutputCutLength.pspCountries) {
          return (
            <TooltipComponent
              title={preatyCountriesTooltipView(listOfIncludesCountry)}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                  {prettyCutOutputByCellWidth(listOfIncludesCountry, 'pspCountries')}
                </span>
            )}
            />
          );
        }
        return (
          <span style={{ whiteSpace: 'nowrap' }}>
            {listOfIncludesCountry}
          </span>
        );
      },
      minWidth: '170px',
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('excludedCountries')}>
          <div className="d-flex flex-row">
            <span>Excluded Countries</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('excludedCountries')} />
            </button>
          </div>
        </SortWrapper>
      ),
      cell: ({ excludedCountries }) => {
        const listOfExcludesCountries = makeListOfRecords({ state: countries, ids: excludedCountries, nameField: 'nicename' });
        if (listOfExcludesCountries.length >= valueOutputCutLength.pspCountries) {
          return (
            <TooltipComponent
              title={preatyCountriesTooltipView(listOfExcludesCountries)}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                  {prettyCutOutputByCellWidth(listOfExcludesCountries, 'pspCountries')}
                </span>
            )}
            />
          );
        }
        return (
          <span style={{ whiteSpace: 'nowrap' }}>
            {listOfExcludesCountries}
          </span>
        );
      },
      minWidth: '170px',
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('status')}>
          <div className="d-flex flex-row">
            <span>Status</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('status')} />
            </button>
          </div>
        </SortWrapper>
      ),
      cell: (row) => (
        isUserCanEditPspOther 
          ? (
            <select 
              className="form-control user-status-select leads-status-select"
              name="status"
              value={row.status}
              onChange={(e) => handleChangeStatus(e, row._id)}
            >
              <option value>Active</option>
              <option value={false}>Inactive</option>
            </select>
          ) : <div>{row.status ? 'Active' : 'Inactive'}</div>
      ),
      minWidth: '150px',
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('url')}>
          <div className="d-flex flex-row">
            <span>Referal Link</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('url')} />
            </button>
          </div>
        </SortWrapper>
      ),
      cell: (row) => (
        <div>
          {
            row?.url?.length >= valueOutputCutLength.url
              ? (
                <TooltipComponent title={row.url}>
                  <div className="truncate-text m5" style={{ cursor: 'pointer' }} data-for={`aff-${row._id}`} data-tip={`${row.url}`}>
                    {prettyCutOutputByCellWidth(row.url, 'url')}
                  </div>
                </TooltipComponent>
              )
              : (
                <div className="truncate-text m5" data-for={`funnel-${row._id}`} data-tip={row.url}>
                  {row.url ?? '-'}
                </div>
              )
          }
        </div>
      ),
      minWidth: '160px',
    },
    {
      name: 'In Use',
      cell: ({ _id }) => (
        <button
          type="button"
          style={{ minWidth: '90px' }}
          className="btn btn-warning btn-sm me-2"
          onClick={() => showModal({
            headerContent: <h3>List of in-use users</h3>, 
            bodyContent: <UsersInUseModal _id={_id} />,
          })}
        >
          View Users
        </button>
      ),
    },
    {
      name: 'Actions',
      width: '240px',
      cell: (row) => (
        isUserCanDeletePspOther || isUserCanEditPspOther ? (
          <div className="action-btn">
            {isUserCanEditPspOther && (
              <button
                type="button"
                className="btn btn-success btn-sm me-1 p-1"
                onClick={() => showModal({
                  bodyContent: <OtherPspModal row={row} type="edit" />,
                  headerContent: <h3>Edit PSP</h3>, 
                })}
              >
                <FontAwesomeIcon icon={faPencil} />
              </button>
            )}
            {isUserCanDeletePspOther && (
              <button
                type="button"
                className="btn btn-danger btn-sm me-1 p-1"
                onClick={() => deleteAction(row._id)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            )}
          </div>
        ) : '-'
      ),
      minWidth: '174px',
    },
  ];

  return columns;
};
