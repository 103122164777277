export const withdrawalColumns = [
  {
    name: 'Transaction ID',
    field: 'customId',
    selected: true,
    order: 1,
  },
  {
    name: 'Client ID',
    field: 'user.customId',
    selected: true,
    order: 2,
  },
  {
    name: 'Account ID',
    field: 'cfdaccount.customId',
    selected: true,
    order: 3,
  },
  {
    name: 'Amount',
    field: 'amount',
    selected: true,
    order: 4,
  },
  {
    name: 'Currency',
    field: 'currency',
    selected: true,
    order: 5,
  },
  {
    name: 'Brand',
    field: 'brandId',
    selected: true,
    order: 6,
  },
  {
    name: 'Client',
    field: 'user.firstName,user.lastName',
    selected: true,
    order: 7,
  },
  {
    name: 'Assigned To',
    field: 'user.assignedTo.firstName,user.assignedTo.lastName',
    selected: true,
    order: 8,
  },
  {
    name: 'Desk/IB',
    field: 'ibdesk.fullName',
    selected: true,
    order: 9,
  },
  {
    name: 'In USD',
    field: 'amountInUSD',
    selected: true,
    order: 10,
  },
  {
    name: 'Transaction Type',
    field: 'transactionType',
    selected: true,
    order: 11,
  },
  {
    name: 'Status',
    field: 'status',
    selected: true,
    order: 12,
  },
  {
    name: 'Time Initiated',
    field: 'createdAt',
    selected: true,
    order: 13,
  },
  {
    name: 'Time Processed',
    field: 'processedAt',
    selected: true,
    order: 14,
  },
  {
    name: 'Real/Fake',
    field: 'isReal',
    selected: true,
    order: 15,
  },
  {
    name: 'Action',
    field: '',
    selected: true,
    order: 16,
  },
];
