import React from 'react';
import {
  faUndo, faTrash, faThumbsDown, faThumbsUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

// eslint-disable-next-line default-param-last
export const omitColumn = (columnConfig = [], columnName) => {
  let omit = false;

  columnConfig.forEach((column) => {
    if (column.name === columnName) {
      omit = !column.selected;
    }
  });

  return omit;
};

let timeoutId;

export const setNewOrderedColumns = (
  newColumns = [], 
  columnConfig = [],
  setNewColumnsState,
  setIsColumnsChanged = () => {},
) => {
  const sortedConfig = columnConfig.map((column) => {
    const newColumnIndex = newColumns.findIndex((newCol) => newCol.label === column.name);
    return newColumnIndex !== -1 ? { ...column, order: newColumnIndex + 1 } : column;
  });

  if (timeoutId) {
    clearTimeout(timeoutId);
  }

  timeoutId = setTimeout(() => {
    setNewColumnsState(sortedConfig);
    setIsColumnsChanged(false);
  }, 1000);
};

export const sortColumnByOrderConfig = (
  columns = [],
  columnConfig = [],
) => {  
  const sortedColumns = columnConfig
    .sort((a, b) => a.order - b.order)
    .map((config) => {
      const column = columns.find((col) => col.label === config.name);
      return column ?? null;
    })
    .filter((col) => col !== null);

  return sortedColumns;
};

export const cutId = (id) => (`${id.substring(0, 3)}...${id.substring(id.length - 3, id.length)}`);

const padTo2Digits = (num) => num.toString().padStart(2, '0');

export const formatDate = (date) => (
  `${[
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join('-')
  } ${[
    padTo2Digits(date.getHours()),
    padTo2Digits(date.getMinutes()),
    padTo2Digits(date.getSeconds()),
  ].join(':')}`
);

export const setTransactionActionColumn = (
  row,
  permissionName,
  resolveCurrentDepositTransaction,
  updateIsReal,
  handleRevertTransaction,
  handleDeleteTransaction,
  isDeleted,
  dispatch,
  isSuperUser,
) => {
  const isUserCanRevertTransaction = permissionName.includes('revert_transaction');
  const isUserCanDeleteTransaction = permissionName.includes('delete_transaction');
  const isUserCanChangeTransactionType = permissionName.includes('change_transaction_type');
  const isUserCanApprovePendingTransaction = permissionName.includes('approve_pending_transactions');

  const { isTransactionLoading } = useSelector((state) => state.users);

  if (!row.isResolved) {
    return (
      <>
        {
          isUserCanChangeTransactionType ? (
            <button
              type="button"
              className={`btn ${row.isReal ? 'btn-danger' : 'btn-success'} btn-sm me-1 p-1`}
              onClick={() => dispatch(updateIsReal(row._id, !row.isReal))}
            >
              <FontAwesomeIcon icon={row.isReal ? faThumbsDown : faThumbsUp} className="header-icon" />
            </button>
          ) : null
        }
        {
          isUserCanApprovePendingTransaction ? (
            <>
              <button
                type="button"
                className="btn btn-success btn-sm me-1 p-1"
                disabled={isTransactionLoading}
                onClick={() => resolveCurrentDepositTransaction(row._id, row.userId, 1)}
              >
                Accept
              </button>
              <button
                type="button"
                className="btn btn-danger btn-sm me-1 p-1"
                disabled={isTransactionLoading}
                onClick={() => resolveCurrentDepositTransaction(row._id, row.userId, 2)}
              >
                Decline
              </button>
            </>
          ) : '-'
        }
      </>
    );
  }

  return (
    <>
      {
        (!isUserCanRevertTransaction && !isUserCanDeleteTransaction && !isUserCanChangeTransactionType) && (
          <span>-</span>
        )
      }
      {
        isUserCanChangeTransactionType && (
          <button
            type="button"
            className={`btn ${row.isReal ? 'btn-danger' : 'btn-success'} btn-sm me-1 p-1`}
            onClick={() => dispatch(updateIsReal(row._id, !row.isReal))}
          >
            <FontAwesomeIcon icon={row.isReal ? faThumbsDown : faThumbsUp} className="header-icon" />
          </button>
        )
      }
      {
        (isUserCanRevertTransaction && row.isResolved !== 2) && (!row.isReal || isUserCanDeleteTransaction) && (
          <button
            type="button"
            className="btn btn-danger btn-sm me-1 p-1"
            onClick={() => handleRevertTransaction(row._id, row.userId)}
          >
            <FontAwesomeIcon icon={faUndo} className="header-icon" />
          </button>
        )
      }
      {
        (isUserCanDeleteTransaction && !isDeleted && isSuperUser) && (
          <button
            type="button"
            className="btn btn-danger btn-sm me-1 p-1"
            onClick={() => handleDeleteTransaction(row._id)}
          >
            <FontAwesomeIcon icon={faTrash} className="header-icon" />
          </button>
        )
      }
    </>
  );
};

export const dateOptions = [
  {
    name: 'Today',
    date: new Date(),
    get start() {
      return new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()).toISOString();
    },
    get end() {
      return new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), 23, 59, 59).toISOString();
    },
    get _id() {
      return `${this.start}||${this.end}`;
    },
  },
  {
    name: 'Yesterday',
    date: new Date(new Date().setDate(new Date().getDate() - 1)),
    get start() {
      return new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()).toISOString();
    },
    get end() {
      return new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), 23, 59, 59).toISOString();
    },
    get _id() {
      return `${this.start}||${this.end}`;
    },
  },
  {
    name: 'This Week',
    date: new Date(),
    get start() {
      return new Date(this.date.setDate(this.date.getDate() - this.date.getDay())).toISOString();
    },
    get end() {
      return new Date(this.date.setDate(this.date.getDate() - this.date.getDay() + 6)).toISOString();
    },
    get _id() {
      return `${this.start}||${this.end}`;
    },
  },
  {
    name: 'Past Week',
    date: new Date(),
    get start() {
      const date = new Date(this.date);
      const day = date.getDay();
      const diff = day >= 1 ? day - 1 : 6; // Calculate the difference to Monday
      const startDate = new Date(date.setDate(date.getDate() - diff - 7)); // Go back to the previous Monday
      startDate.setHours(0, 0, 0, 0); // Set to the start of the day
      return startDate.toISOString();
    },
    get end() {
      const date = new Date(this.date);
      const day = date.getDay();
      const diff = day >= 1 ? day - 1 : 6; // Calculate the difference to Monday
      const endDate = new Date(date.setDate(date.getDate() - diff - 1)); // Go back to the previous Sunday
      endDate.setHours(23, 59, 59, 999); // Set to the end of the day
      return endDate.toISOString();
    },
    get _id() {
      return `${this.start}||${this.end}`;
    },
  },
  {
    name: 'This Month',
    date: new Date(),
    get start() {
      return new Date(this.date.getFullYear(), this.date.getMonth(), 1).toISOString();
    },
    get end() {
      return new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).toISOString();
    },
    get _id() {
      return `${this.start}||${this.end}`;
    },
  },
  {
    name: 'Past Month',
    date: new Date(),
    get start() {
      return new Date(this.date.getFullYear(), this.date.getMonth() - 1, 1).toISOString();
    },
    get end() {
      return new Date(this.date.getFullYear(), this.date.getMonth(), 0).toISOString();
    },
    get _id() {
      return `${this.start}||${this.end}`;
    },
  },
  {
    name: 'Last 7 days',
    date: new Date(),
    get start() {
      return new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() - 7).toISOString();
    },
    get end() {
      return this.date;
    },
    get _id() {
      return `${this.start}||${this.end}`;
    },
  },
  {
    name: 'Last 30 days',
    date: new Date(),
    get start() {
      return new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() - 30).toISOString();
    },
    get end() {
      return this.date;
    },
    get _id() {
      return `${this.start}||${this.end}`;
    },
  },
];

export const timeInitiatedHelper = (item, filter) => {
  const createdTime = new Date(item.createdAt);
  let inDateRange = false;

  filter.forEach((time) => {
    if (createdTime >= new Date(time.split('||')[0]) && createdTime <= new Date(time.split('||')[1])) {
      inDateRange = true;
    }
  });

  return inDateRange;
};

export const valueOutputCutLength = {
  id: 9,
  email: 18,
  secondEmail: 18,
  fullName: 23,
  phoneNumber: 16,
  secondPhoneNumber: 16,
  country: 17,
  language: 20,
  created: 19,
  affiliateFullName: 17,
  funnelName: 14,
  additionalInfo: 14,
  assignedTo: 18,
  status: 20,
  balance: 11,
  credit: 11,
  netDeposits: 14,
  lastLoginAt: 20,
  lastOnline: 20,
  lastComment: 20,
  localTime: 20,
  userRole: 11,
  registerDate: 20,
  client: 17,
  brand: 15,
  clientWithdrawal: 20,
  manager: 23,
  ibdesk: 23,
  transactionType: 20,
  inUsd: 10,
  managerStatus: 14,
  margin: 14,
  tpSl: 8,
  pnl: 10,
  url: 20,
  volume: 9,
  investAmount: 10,
  profitLoss: 12,
  shift: 5,
  leverage: 8,
  mongoId: 5,
  userSaving: 10,
  investedAmountSavings: 12,
  crmUserLog: 15,
  pspLabel: 15,
  pspWallet: 18,
  pspBrands: 15,
  pspCountries: 20,
  smtpEmail: 21,
  smtpPass: 21,
  smtpDesc: 20,
  sms: 20,
  smsAuthData: 20,
  pspAddress: 14,
  accountsAssignTo: 12,
  ftdOwnerFullName: 14,
  stdOwnerFullName: 14,
  prevAssignedToFullName: 14,
  iban: 28,
  alias: 13,
  selectMenuItem: 20,
};

export const prettyCutOutputByCellWidth = (value, field) => {
  if (!value) return '-';
  const maxLength = valueOutputCutLength[field];
  const trimmedValue = value.length >= maxLength ? `${value.slice(0, maxLength)}...` : value;
  return trimmedValue ?? value;
};

export const ibDeskIdsOutput = (allIbDesks, ibDeskId) => {
  if (ibDeskId === undefined || allIbDesks === undefined) return '-';
  if (ibDeskId.length === 0) return 'All';
  const outputString = allIbDesks
    .filter((ibDesk) => ibDeskId.includes(ibDesk._id))
    .map((el) => `${el.firstName} ${el.lastName}`)
    .join(',');

  return outputString;
}; 

export const countriesOutput = (allCountry, country) => {
  if (country === undefined || allCountry === undefined) return '-';
  if (allCountry.length === country.length) return 'All';
  const outputString = allCountry
    .filter((cont) => country.includes(cont._id))
    .map((el) => `${el.nicename}`)
    .join(',');

  return outputString;
};
