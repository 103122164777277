import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { ModalContext } from '../../../context';
import { createVoip } from '../../../redux/voip/voipActions';

export function AddVoipModal() {
  const dispatch = useDispatch();
  const { hideModal } = useContext(ModalContext);
  const crmUsers = useSelector((state) => state.crmUser.crmUsers);
  const uId = useSelector((state) => state.crmUser.crmUserInfo._id);
  const voipProviders = useSelector((state) => state.voip.voipProviders);
  const brands = useSelector((state) => state.brands);

  const generateUniqueId = () => Math.random().toString(36).slice(2, 11);

  const [formData, setFormData] = useState({});
  const [prefixes, setPrefixes] = useState([{ id: generateUniqueId(), value: '' }]);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      await dispatch(createVoip({
        ...formData,
        prefixes: prefixes.filter((prefix) => prefix.value !== '').map((prefix) => prefix.value),
      }, uId));
      hideModal();
    } catch (error) {
      toast.error(error.message);
      hideModal();
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
  
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePrefixChange = (id, value) => {
    const newPrefixes = prefixes.map((prefix) => (prefix.id === id ? { ...prefix, value } : prefix));
    setPrefixes(newPrefixes);
  };

  const addPrefix = () => {
    setPrefixes([...prefixes, { id: generateUniqueId(), value: '' }]);
  };

  const removePrefix = (id) => {
    const newPrefixes = prefixes.filter((prefix) => prefix.id !== id);
    setPrefixes(newPrefixes);
  };

  useEffect(() => {
    if (voipProviders && voipProviders.length) {
      setFormData((prevData) => ({
        ...prevData,
        providerId: (voipProviders[0]._id),
      }));
    }
    
    if (crmUsers && crmUsers.length) {
      setFormData((prevData) => ({
        ...prevData,
        agent: (crmUsers[0]._id),
      }));
    }

    if (brands && brands.length) {
      setFormData((prevData) => ({
        ...prevData,
        brandId: (brands[0]._id),
      }));
    }
  }, [crmUsers, brands, voipProviders]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group mt-2">
        <span>Agent</span>
        <select 
          name="agent"
          className="form-control header-smtp-boxes-selector"
          style={{ width: '100%' }}
          value={formData.agent}
          onChange={handleInputChange}
        >
          {crmUsers.map((user, key) => (
            <option key={user._id} selected={key === 0} value={user._id}>
              {`${user.firstName} ${user.lastName}`}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group mt-2">
        <span>Provider</span>
        <select 
          name="providerId"
          className="form-control header-smtp-boxes-selector"
          style={{ width: '100%' }}
          value={formData.providerId}
          onChange={handleInputChange}
        >
          {voipProviders.map(({ name, _id }) => (
            <option key={_id} value={_id}>
              {name}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group mt-2">
        <span>Brand</span>
        <select 
          name="brandId"
          className="form-control header-smtp-boxes-selector"
          style={{ width: '100%' }}
          value={formData.brandId}
          onChange={handleInputChange}
        >
          {brands.map(({ name, _id }) => (
            <option key={_id} value={_id}>
              {name}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group mt-2">
        <label htmlFor="extension">Extension</label>
        <input
          type="text"
          id="extension"
          name="extension"
          value={formData.extension}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>

      <div className="form-group mt-2">
        <label>Prefixes</label>
        {prefixes.map(({ id, value }) => (
          <div key={id} className="d-flex mb-2">
            <input
              type="text"
              value={value}
              onChange={(e) => handlePrefixChange(id, e.target.value)}
              className="form-control"
              placeholder="Enter prefix"
            />
            {prefixes.length > 1 && (
              <button
                type="button"
                className="btn btn-danger ms-2"
                onClick={() => removePrefix(id)}
              >
                Delete
              </button>
            )}
          </div>
        ))}
        <button
          type="button"
          className="btn btn-secondary"
          onClick={addPrefix}
        >
          Add Prefix
        </button>
      </div>

      <div className="m-2 d-flex justify-content-end">
        <button className="btn btn-default" type="submit">
          Add
        </button>
      </div>
    </form>
  );
}
