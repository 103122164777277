import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-solid-svg-icons';
import { BsTrashFill } from 'react-icons/bs';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { deleteAffiliate } from '../redux/affiliate/affiliateActions';
import { CopyToClipboardCmp } from '../components/CopyToClipboardCmp';
import { SortWrapper, useTableSorting } from '../helpers/sortHelper';
import SortIcon from '../components/SortIcon';
import { omitColumn, sortColumnByOrderConfig } from './helper';

export const AffiliateColumns = ({
  columnConfig,
  handleSort,
  fullNameFilter,
  setFullNameFilter,
  idFilter, 
  setIdFilter,
  emailFilter,
  setEmailFilter,
}) => {
  const dispatch = useDispatch();

  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const isUserCanDeleteAffiliate = permissionName && permissionName.length > 0 && permissionName.includes('affiliate_delete');

  const { sortFields, getSortIcon } = useTableSorting(handleSort);

  const deleteAction = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed === true) {
        dispatch(deleteAffiliate(id));
      }
    });
  };

  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };

  const columns = [
    {
      name: (
        <div className="d-flex align-items-start flex-column search-input-des pt-2">
          <SortWrapper handleSort={() => sortFields('customId')}>
            <div className="d-flex flex-row">
              <span>ID</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('customId')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            name="ID"
            autoComplete="off"
            value={idFilter || ''}
            onChange={({ target: { value } }) => setIdFilter(value)}
          />
        </div>
      ),
      selector: ({ customId }) => <CopyToClipboardCmp label={customId} />,
      width: '230px',
      label: 'ID',
      reorder: true,
      omit: omitColumn(columnConfig, 'ID'),
    },
    {
      name: (
        <div className="d-flex align-items-start flex-column search-input-des pt-2">
          <SortWrapper handleSort={() => sortFields('firstName lastName')}>
            <div className="d-flex flex-row">
              <span>Full Name</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('firstName lastName')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            name="fullName"
            autoComplete="off"
            value={fullNameFilter || ''}
            onChange={({ target: { value } }) => setFullNameFilter(value)}
          />
        </div>
      ),
      cell: ({
        fullName, _id, firstName, lastName, 
      }) => (
        <Link
          to={`/affiliate-detail/${_id}`}
          style={{ color: '#fff' }}
        >
          { fullName ?? `${firstName} ${lastName}` }
        </Link>
      ),
      minWidth: '230px',
      label: 'Full Name',
      reorder: true,
      omit: omitColumn(columnConfig, 'Full Name'),
    },
    {
      name: (
        <div className="d-flex align-items-start flex-column search-input-des pt-2">
          <SortWrapper handleSort={() => sortFields('email')}>
            <div className="d-flex flex-row">
              <span>Email</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('email')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            name="Email"
            autoComplete="off"
            value={emailFilter || ''}
            onChange={({ target: { value } }) => setEmailFilter(value)}
          />
        </div>
      ),
      minWidth: '230px',
      selector: (row) => `${row?.email}`,
      omit: omitColumn(columnConfig, 'Email'),
      label: 'Email',
      reorder: true,
      cell: (row) => (
        <CopyToClipboard text={row?.email}>
          <span data-tip={row?.email}>
            {row?.email}
            <FontAwesomeIcon
              icon={faClone}
              onClick={() => copyReferral()}
              className="ms-2"
            />
          </span>
        </CopyToClipboard>
      ),
    },
    {
      name: 'Actions',
      label: 'Actions',
      reorder: true,
      omit: omitColumn(columnConfig, 'Actions'),
      cell: (row) => (
        isUserCanDeleteAffiliate && (
        <button
          type="button"
          className="btn btn-danger btn-sm me-1 p-1"
          onClick={() => deleteAction(row?._id)}
        >
          <BsTrashFill size={18} />
        </button>
        )
      ),
    },
  ];

  return sortColumnByOrderConfig(columns, columnConfig);
};
