import React, { useEffect, useState } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { ChipsButtonComponent } from '../components/ChipsButtonComponent';
import { SearchableCheckboxList } from '../components/SearchableCheckboxList';
import { getBrands } from '../../../redux/brand/brandActions';
import { DatePickerComponent } from '../components/DatePickerComponent';
import { getDateRange, periodOptions, parseISOString } from '../utils/timelineHelper';
import { getSalesStatusOptions } from '../utils/optionsHelper';

import styles from './SalesDashboardFilter.module.css';


export function SalesDashboardFilter({ 
  setFilterObject, 
  fromDate, 
  fromTime, 
  setFromDate, 
  setFromTime, 
  toDate,
  toTime,
  setToDate,
  setToTime,
  selectedBrand,
  setSelectedBrand,
  selectedSalesStatus,
  setSelectedSalesStatus,
  desksIbsItems,
  setDesksIbsItems,
  amountOfFilters,
  setAmountOfFilters,
  refresh,
  selectedPeriod, 
  setSelectedPeriod,
  closeMobileFilter,
}) {
  const dispatch = useDispatch();
  const brands = useSelector((state) => state.brands);
  const desks = useSelector((state) => state.crmUser.deskIbs);
  const crmUserSalesRole = useSelector((state) => state.crmUser.crmUserInfo.salesRole);
  const [updatedPeriodOptions, setUpdatedPeriodOptions] = useState([]);

  const salesDashboardFilterStorage = 'Sales_Dashboard/filters'; 
  const customStorage = 'SalesDashboard/Custom';

  const changePeriodHandler = (period) => {
    if (period.label === 'All') { 
      setFilterObject((prevState) => ({ ...prevState, period: [...period.dateRange, period.label] }));
      setFromDate('');
      setFromTime('');
      setToDate('');
      setToTime('');
      localStorage.removeItem(customStorage);
    }
    if (period.label === 'Custom') { 
      setFromDate('');
      setFromTime('');
      setToDate('');
      setToTime('');
      localStorage.setItem(customStorage, JSON.stringify('Custom'));
    }
    if (period.label !== 'All' && period.label !== 'Custom') {
      setFilterObject((prevState) => ({ ...prevState, period: [...period.dateRange, period.label] }));
      const [fromDateParsed, fromTimeParsed] = parseISOString(period.dateRange[0]);
      const [toDateParsed, toTimeParsed] = parseISOString(period.dateRange[1]);
      setFromDate(fromDateParsed);
      setFromTime(fromTimeParsed);
      setToDate(toDateParsed);
      setToTime(toTimeParsed);
      localStorage.removeItem(customStorage);
    } 
    setSelectedPeriod(period.label);
  };

  const handleChangeDeskIBs = (id) => {
    const newSelectedItems = new Set(desksIbsItems);
    if (newSelectedItems.has(id)) {
      newSelectedItems.delete(id);
    } else {
      newSelectedItems.add(id);
    }
    setDesksIbsItems(newSelectedItems);
    setFilterObject((prevState) => ({ ...prevState, deskRole: [...newSelectedItems] }));
  };

  const changeSalesStatusHandler = (salesStatus) => {
    setSelectedSalesStatus(salesStatus.value);
    setFilterObject((prevState) => ({ ...prevState, managerStatus: salesStatus.value }));
  };

  const changeBrandHandler = (brandItem) => {
    setSelectedBrand(brandItem ? brandItem._id : null);
    setFilterObject((prevState) => ({ ...prevState, brand: (brandItem ? brandItem._id : '') }));
  };

  const deleteFilterHandler = () => {
    setSelectedPeriod(periodOptions[0].label);
    setSelectedSalesStatus(crmUserSalesRole);
    setSelectedBrand(null);
    setDesksIbsItems(new Set());
    setFromDate('');
    setFromTime('');
    setToDate('');
    setToTime('');
    setFilterObject({});
    setAmountOfFilters(0);
    localStorage.removeItem(salesDashboardFilterStorage);
  };

  const refreshFilterHandler = () => {
    refresh();
  };

  useEffect(() => {
    const fetchData = () => {
      dispatch(getBrands());
    };
    fetchData();
    const newPeriodOptions = periodOptions.map((option) => {
      if (option.label === 'All') {
        return {
          ...option,
          dateRange: ['', ''],
        };
      } 
      if (option.label !== 'Custom') {
        const { startDate, endDate } = getDateRange(option.label);
        return {
          ...option,
          dateRange: [(startDate ? startDate.toISOString() : ''), (endDate ? endDate.toISOString() : '')],
        };
      }
      return option;
    });
    setUpdatedPeriodOptions(newPeriodOptions);
    setSelectedPeriod(newPeriodOptions[0].label);
  }, []);

  return (
    <div className={styles.filterDashboardWrapper}>
      <div className={styles.filterDashboard}>
        <div className={styles.filterDashboardHeader}>
          <div className={styles.filterDashboardHeaderRow}>
            <button type="button" className={styles.closeBtn} onClick={closeMobileFilter}>
              <FontAwesomeIcon icon={faClose} />
            </button>
            <p className={styles.filterDashboardHeaderTitle}>Filter Dashboard</p>
          </div>
          <p className={styles.filterDashboardHeaderText}>Select a filter to control which data show in this dashboard</p>
        </div>
        <div className={styles.filterDashboardContainer}>
          <div className={styles.filterDashboardPeriodCont}>
            <p className={styles.filterDashboardPeriodTitle}>Period</p>
            <div className={styles.filterDashboardPeriodChips}>
              { updatedPeriodOptions?.map((el) => (
                <ChipsButtonComponent label={el.label} isSelected={selectedPeriod === el.label} onClick={() => changePeriodHandler(el)} key={el.label} />
              )) }
            </div>
            <DatePickerComponent 
              valueDatePicker={fromDate} 
              labelTitle="From" 
              datePickerId="dateFrom"
              timePickerId="timeFrom"
              valueTimePicker={fromTime}
              setDate={setFromDate}
              setTime={setFromTime}
              disabled={selectedPeriod !== 'Custom'}
            />
            <DatePickerComponent 
              valueDatePicker={toDate} 
              labelTitle="To" 
              datePickerId="dateTo"
              timePickerId="timeTo"
              valueTimePicker={toTime}
              setDate={setToDate}
              setTime={setToTime}
              disabled={selectedPeriod !== 'Custom'}
            />
            <hr className={styles.lineDivider} />
          </div>
          <div className={styles.filterDashboardPeriodCont}>
            <p className={styles.filterDashboardPeriodTitle}>Sales status:</p>
            <div className={styles.filterDashboardPeriodChips}>
              { getSalesStatusOptions(crmUserSalesRole).map((el) => (
                <ChipsButtonComponent label={el.label} isSelected={selectedSalesStatus === el.value} onClick={() => changeSalesStatusHandler(el)} key={el.label} />
              )) }
            </div>
            <hr className={styles.lineDivider} />
          </div>
          <div className={styles.filterDashboardPeriodCont}>
            <p className={styles.filterDashboardPeriodTitle}>Brand:</p>
            <div className={styles.filterDashboardPeriodChips}>
              <ChipsButtonComponent label="All" isSelected={selectedBrand === null} onClick={() => changeBrandHandler(null)} />
              { brands?.map((el) => (
                <ChipsButtonComponent label={el.name} isSelected={selectedBrand === el._id} onClick={() => changeBrandHandler(el)} key={el._id} />
              )) }
            </div>
            <hr className={styles.lineDivider} />
          </div>
          <div className={styles.filterDropDownContainer}>
            <p className={styles.filterDashboardPeriodTitle}>Desks/IBs:</p>
            <div className={styles.filterDropDown}>
              { desks.length > 0 && <SearchableCheckboxList data={desks} selectedItems={desksIbsItems} setSelectedItems={setDesksIbsItems} handleChange={handleChangeDeskIBs} setFilterObject={setFilterObject} /> }
            </div> 
          </div>
        </div>
        <div className={styles.filterDashboardFooter}>
          <p className={styles.filterDashboardFooterFilterText}>
            Filter:
            {amountOfFilters}
          </p>
          <div className={styles.filterDashboardFooterBtnsContainer}>
            <button type="button" className={styles.filterDashboardFooterBtn} onClick={deleteFilterHandler} disabled={amountOfFilters === 0}>
              <DeleteOutlineIcon sx={{ color: '#C4C6D0' }} />
            </button>
            <button type="button" className={styles.filterDashboardFooterBtn} onClick={refreshFilterHandler}>
              <RefreshIcon sx={{ color: '#C4C6D0' }} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
