import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencil,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import {
  getOptions,
  getSavings,
  deleteSaving,
} from '../../redux/saving/savingActions';
import { generateSortParams, SortWrapper, useTableSorting } from '../../helpers/sortHelper';
import SortIcon from '../../components/SortIcon';

function Saving() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [loader, setLoader] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filterCurrency, setFilterCurrency] = useState(null);
  const [filterDeposit, setFilterDeposit] = useState(null);
  const [sortParams, setSortParams] = useState(JSON.stringify({ createdAt: -1 }));

  const savings = useSelector((state) => state.saving.savings);
  const savingOptions = useSelector((state) => state.saving.options);

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      await Promise.allSettled([dispatch(getOptions()), dispatch(getSavings())]);
      setLoader(false);
    };

    fetchData();
  }, [location]);

  useEffect(async () => {
    setLoader(true);
    dispatch(getSavings(sortParams));
    setLoader(false);
  }, [sortParams]);

  const deleteAction = async (savingId) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed === true) {
        await dispatch(deleteSaving(savingId));
      }
    });
  };

  useEffect(() => {
    const searchResult = savings.filter((item) => {
      const matchesCurrency = filterCurrency && filterCurrency.trim() 
        ? (
          item.currency.name.toLowerCase().includes(filterCurrency.trim().toLowerCase())
            || item.currency.symbol.toLowerCase().includes(filterCurrency.trim().toLowerCase())
        )
        : true;
      const matchesDeposit = filterDeposit && filterDeposit.trim() 
        ? item.minDeposit.toString().toLowerCase().includes(filterDeposit.trim().toLowerCase()) 
        : true;

      return matchesCurrency && matchesDeposit;
    });

    setFilteredItems(searchResult);
  }, [filterCurrency, filterDeposit, savings]);

  const handleSort = async (sortField, sortDirection) => {
    const sort = generateSortParams(sortField, sortDirection);
    setSortParams(sort);
  };

  const savingsSorts = {
    Flexible: 'pct0',
    '1 Week': 'pct30',
    '1 Month': 'pct90',
    '3 Months': 'pct180',
    '6 Months': 'pct365',
    '1 Year': 'pct730',
  };

  const { sortFields, getSortIcon } = useTableSorting(handleSort);

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3 className="mb-3 text-capilatize">Saving</h3>
        <div className="action__btn-row">
          <div className="main_btn-row">
            <div className="secondary_btn-row">
              <Link to="/add-saving" className="btn-primary_light">
                <FontAwesomeIcon icon={faPlus} size="sm" />
                Add New
              </Link>
              <Link to="/add-custom-saving" className="btn-primary_light">
                <FontAwesomeIcon icon={faPlus} size="sm" />
                Add New Custom
              </Link>
            </div>
          </div>
        </div>
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper staking-tbl mt-3">
          <div className="table-responsive">
            <table>
              <tr>
                <th>
                  <SortWrapper handleSort={() => sortFields('currency.name')}>
                    <div className="d-flex flex-row align-items-center">
                      <span>Currency</span>
                      <button type="button" className="sort-btn">
                        <SortIcon image={getSortIcon('currency.name')} />
                      </button>
                    </div>
                  </SortWrapper>
                </th>
                <th>
                  <SortWrapper handleSort={() => sortFields('minDeposit')}>
                    <div className="d-flex flex-row align-items-center">
                      <span>Minimum Deposit</span>
                      <button type="button" className="sort-btn">
                        <SortIcon image={getSortIcon('minDeposit')} />
                      </button>
                    </div>
                  </SortWrapper>
                </th>
                {savingOptions.map((option) => (
                  <th key={option._id}>
                    <SortWrapper handleSort={() => sortFields(`${savingsSorts[option.label]}.percent`)}>
                      <div className="d-flex flex-row align-items-center">
                        <span>
                          {option.isFlexible ? option.label : `Locked ${option.label}`}
                        </span>
                        <button type="button" className="sort-btn">
                          <SortIcon image={getSortIcon(`${savingsSorts[option.label]}.percent`)} />
                        </button>
                      </div>
                    </SortWrapper>
                  </th>
                ))}
                <th>Actions</th>
              </tr>
              <tr className="search-holder">
                <th>
                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Search"
                      name="filterCurrency"
                      value={filterCurrency}
                      onChange={({ target: { value } }) => setFilterCurrency(value)}
                    />
                  </div>
                </th>
                <th>
                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Search"
                      name="filterDeposit"
                      value={filterDeposit}
                      onChange={({ target: { value } }) => setFilterDeposit(value)}
                    />
                  </div>
                </th>
                <th colSpan={100} />
              </tr>
              {filteredItems.length ? (
                filteredItems.map((item) => (
                  <tr key={`saving-${item?._id}`}>
                    <td>{item?.currency ? `${item?.currency?.name} (${item?.currency.symbol})` : item?.customCurrency?.name}</td>
                    <td>{item?.minDeposit}</td>
                    {item.options?.map(({ _id, percent }) => (
                      <td key={`${item?.currency?.name ?? item?.customCurrency?.name}-${_id}`}>{`${percent} %`}</td>
                    ))}
                    <td>
                      <div className="action-btn">
                        <Link
                          className="btn-complete"
                          to={`/edit-saving/${item?._id}`}
                        >
                          <FontAwesomeIcon icon={faPencil} />
                        </Link>
                        <button
                          type="button"
                          className="btn-cancel"
                          onClick={() => deleteAction(item?._id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={100}>No Record Found</td>
                </tr>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Saving;
