import React from 'react';
import styles from './styles.module.css';

export function CalendarHeader({ data = [] }) {
  return (
    <div className={styles.calendarHeader}>
      {data.map(({ dayOfWeek }, key = 0) => <span key={key} className={styles.calendarHeaderCell}>{dayOfWeek}</span>)}
    </div>
  );
}
