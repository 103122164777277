import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


const TooltipCustom = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxHeight: '350px',
    overflowY: 'auto',
    maxWidth: 450,
    borderRadius: '4px',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    padding: '4px 8px',
    backgroundColor: '#434353',
    color: '#F0F0F7',
    fontFamily: 'Play',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px', 
    letterSpacing: '0.5px',
    textAlign: 'start',
  },
}));

function addLineBreaks(str = '', maxLength) {
  const regex = new RegExp(`.{1,${maxLength}}`, 'g');
  const match = str.match(regex);

  if (!match) return str;

  return str.match(regex).join('\n');
}

function formatTitle(title) {
  if (Array.isArray(title)) {
    return title.map((string) => addLineBreaks(string, 50));
  } if (typeof title === 'string') {
    return addLineBreaks(title, 50);
  }

  return title;
}

export function TooltipComponent({
  title,
  children,
}) {
  return (
    <TooltipCustom title={formatTitle(title)}>
      <span>{children}</span>
    </TooltipCustom>
  );
}

