import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

export function CopyToClipboardInputDecorator({ children, value }) {
  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };
  
  return (
    <div className="d-block position-relative">
      { children }
      <div className="position-absolute" style={{ right: '6px', top: '12px' }}>
        <CopyToClipboard text={value}>
          <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
        </CopyToClipboard>
      </div>
    </div>

  );
}
