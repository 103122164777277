import { toast } from 'react-toastify';
import { 
  DISPLAY_NOTIFICATIONS, 
  DISPLAY_UNREAD_NOTIFICATIONS, 
  ADD_NOTIFICATION, 
  REMOVE_READ_NOTIFICATION, 
  REMOVE_ALL_READ_NOTIFICATION, 
  GET_NOTIFICATION_MODULES, 
  NOTIFICATION_MODULES_STATE,
  DISPLAY_ALL_NOTIFICATIONS_BY_ID,
  MARK_NOTIFICATION_AS_SEEN,
} from './notificationTypes';
import { apiHelper } from '../apiHelper';

export const displayUnreadNotifications = (userId) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/notification/unread/${userId}`, '');
    if (res?.data?.notifications) {
      dispatch({
        type: DISPLAY_UNREAD_NOTIFICATIONS,
        payload: res.data.notifications,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const displayAllNotificationsById = (userId) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/notification/all-notifications/${userId}`, '');
    if (res?.data?.notifications) {
      dispatch({
        type: DISPLAY_ALL_NOTIFICATIONS_BY_ID,
        payload: res.data.notifications,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const getNotificationsByModules = (requestBody) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/notification/by-modules ', requestBody);
    if (res?.data?.notifications) {
      dispatch({
        type: DISPLAY_UNREAD_NOTIFICATIONS,
        payload: res.data.notifications,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const displayNotifications = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/client-notification/all', '');
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: DISPLAY_NOTIFICATIONS,
        payload: data,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const readNotification = (id, userId) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/notification/${id}`, { userId });

    if (res?.data?.notification) {
      const { _id } = res.data.notification;
      dispatch({
        type: REMOVE_READ_NOTIFICATION,
        payload: _id,
      });
    }
  } catch (error) {
    toast.error('Failed to mark notification as read.');
  }
};

export const readAllNotification = (ids, userId) => async (dispatch) => {
  try {
    const res = await apiHelper('put', '/api/notification/readAll', { ids, userId });

    if (res?.data?.success) {
      dispatch({
        type: REMOVE_ALL_READ_NOTIFICATION,
        payload: [],
      });
    }
  } catch (error) {
    toast.error('Failed to mark all notifications as read.');
  }
};

export const addNotification = (data) => ({
  type: ADD_NOTIFICATION,
  payload: data,
});

export const getNotificationModule = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/notification/modules', '');

    if (res && res.data && res.data.notificationModules) {
      dispatch({
        type: GET_NOTIFICATION_MODULES,
        payload: res.data.notificationModules,
      }); 
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const updateNotificationModuleState = () => ({
  type: NOTIFICATION_MODULES_STATE,
  payload: false,
});

export const markNotificationAsSeen = (notificationIds) => async (dispatch) => {
  try {
    dispatch({
      type: MARK_NOTIFICATION_AS_SEEN,
      payload: notificationIds,
    });
    await apiHelper('post', '/api/notification/mark-as-seen', {
      ids: notificationIds,
    });
  } catch (error) {
    toast.error('Failed to mark notifications as seen.');
  }
};
