export const currenciesRulesColumns = [
  {
    name: 'Currency',
    field: 'symbol',
    selected: true,
    order: 1,
  },
  {
    name: 'Included Countries',
    field: 'countries',
    selected: true,
    order: 2,
  },
  {
    name: 'Actions',
    field: '',
    selected: true,
    order: 3,
  },
];
    
    
  
