import React from 'react';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { ListSubheader } from '@mui/material';
import { prettyCutOutputByCellWidth } from '../../DataTableSchemas/helper';

const CustomizedMenuItem = styled(MenuItem)(({ selected }) => ({
  color: '#DAE2F9',
  minHeight: '20px',
  fontFamily: 'Play',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px', /* 142.857% */
  letterSpacing: '0.25px',
  padding: '10px 14.5px',
  backgroundColor: selected ? '#2D3152 !important' : '#232640 !important',
  '&:hover': {
    backgroundColor: '#22253C',
    opacity: '0.92',
  },
  '&.Mui-selected': {
    backgroundColor: '#232934',
    opacity: '0.88',
    '&:hover': {
      backgroundColor: '#262C38',
      opacity: '0.92',
    },
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#222731',
    opacity: '0.88',
  },
}));

const CustomizedListSubheader = styled(ListSubheader)(({ theme }) => ({
  color: '#DAE2F9',
  minHeight: '20px',
  fontFamily: 'Play',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
  letterSpacing: '0.25px',
  padding: '10px 14.5px',
  backgroundColor: '#17192b !important',
  '&:hover': {
    backgroundColor: '#22253C',
  },
  '&.Mui-selected': {
    backgroundColor: '#232934',
    opacity: '0.88',
    '&:hover': {
      backgroundColor: '#262C38',
      opacity: '0.92',
    },
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#222731',
    opacity: '0.88',
  },
}));

export function TableSelectWithGroupComponent({
  value, onChange, emptyLabel, selectedOption, optionGroups, placeholder,
}) {
  return (
    <FormControl variant="outlined" style={{ width: '100%' }}>
      <Select
        value={value}
        onChange={onChange}
        label={placeholder}
        variant="standard"
        displayEmpty
        renderValue={() => (
          <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '8px', paddingLeft: '8px',
          }}
          >
            <span>{selectedOption?.value || emptyLabel}</span>
          </div>
        )}
        MenuProps={{
          MenuListProps: {
            disablePadding: true,
          },
          PaperProps: {
            style: {
              maxHeight: '50vh', // Set the height to 50% of the viewport height
            },
          },
        }}
        sx={{
          height: '32px',
          padding: '4px 8px',
          fontSize: '13px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '20px',
          letterSpacing: '0.1px',
          textAlign: 'left',
          border: 'none',
          maxWidth: '100%',
          width: '100%',
          outline: 'none',
          backgroundColor: '#1b3155ba',
          color: '#FFFFFF',
          ' .MuiSelect-select': {
            padding: 0,
          },
          '.MuiOutlinedInput-notchedOutline': {
            outline: 'none',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
      >
        {emptyLabel
              && (
              <CustomizedMenuItem value="" selected={!value}>
                {emptyLabel}
              </CustomizedMenuItem>
              )}
        {selectedOption?.key && (
          <CustomizedMenuItem value={selectedOption.key} selected={selectedOption.value === value}>
            {selectedOption.value}
          </CustomizedMenuItem>
        )}
        {optionGroups.map((group) => [
          <CustomizedListSubheader key={group.id}>
            {group.label}
          </CustomizedListSubheader>,
          ...group.options.map((option) => (
            <CustomizedMenuItem key={option.value} value={option.value} selected={option.value === value}>
              {prettyCutOutputByCellWidth(option.label, 'selectMenuItem')}
            </CustomizedMenuItem>
          )),
        ])}
      </Select>
    </FormControl>
  );
}
