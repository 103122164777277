import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Switch } from '@mui/material';
import { ModalContext } from '../context/ModalContext';
import { EditVariableModal } from '../pages/Variables/modals/EditVariableModal/EditVariableModal';
import { deleteAlertMessageVariables, updateVariables } from '../redux/variables/variablesActions';
import { TooltipComponent } from '../components/TooltipComponent/TooltipComponent';
import { SortWrapper, useTableSorting } from '../helpers/sortHelper';
import SortIcon from '../components/SortIcon';
import styles from '../pages/Variables/Variables.module.css';
import { getSubCRMUsers } from '../redux/crmUser/crmUserActions';

export const VariablesSchema = (
  processFilter, 
  setProcessFilter,
  typeValue, 
  setTypeValue,
  valueFilter, 
  setValueFilter,
  brandNameFilter,
  setBrandNameFilter,
  handleSort,
) => {
  const dispatch = useDispatch();
  const { showModal } = useContext(ModalContext);

  const { crmUsers } = useSelector((state) => state.crmUser);
  const allCrmUsersNames = crmUsers.map((crmUser) => `${crmUser.firstName} ${crmUser.lastName}`);
  const { sortFields, getSortIcon } = useTableSorting(handleSort);

  useEffect(() => {
    const loginData = localStorage.getItem('user');
    const data = JSON.parse(loginData);
    const id = data?.roleId;
    const uid = data?._id;

    if (crmUsers && !crmUsers.length) {
      dispatch(getSubCRMUsers(id, uid));
    }
  }, [crmUsers]);

  const handleRunningAdsSelect = (checked, id, actions) => {
    dispatch(updateVariables({
      id,
      actions,
      value: checked,
      variant: null,
    }));
  };

  const editAction = (id, brandName, action, value, variant) => {
    showModal({
      headerContent: <h3>Edit Variables</h3>,
      bodyContent: <EditVariableModal 
        brandName={brandName} 
        id={id} 
        variablesAction={action} 
        propsValue={value} 
        variant={variant} 
      />,
    });
  };

  const deleteAction = (id) => {
    dispatch(deleteAlertMessageVariables(id));
  };

  const booleanActions = [
    'Running Advertisement', 
    'Email Verification', 
    'Create CFD account by default',
    'Auto-approve BTC Deposits',
    'CFD Disable Edit User Personal Info',
  ];

  const valueOutput = (value, actions, managerNames, id) => {
    if (actions === 'Self User Registration Assign') {
      const filteredManagerNames = managerNames?.filter((managerName) => allCrmUsersNames.includes(managerName));

      return (
        filteredManagerNames?.length > 2 ? (
          <TooltipComponent
            title={
              filteredManagerNames.map((name, idx) => {
                if (filteredManagerNames.length - 1 === idx) {
                  return name;
                }

                return `${name}, `;
              })
            }
          >
            {filteredManagerNames?.slice(0, 2).map((name) => (
              <p key={name} className={styles.brandNameText}>{name}</p>
            ))}
            <p className={styles.brandNameText}>
              {`and ${filteredManagerNames.length - 2} more...`}
            </p>
          </TooltipComponent>
        ) : (
          filteredManagerNames?.slice(0, 2).map((name) => (
            <p key={name} className={styles.brandNameText}>{name}</p>
          ))
        )
      );
    }

    if (actions === 'Hide columns on platform') {
      return (
        value?.length > 2 ? (
          <TooltipComponent
            title={
              value?.map((name, idx) => {
                if (value.length - 1 === idx) {
                  return name;
                }

                return `${name}, `;
              })
            }
          >
            {value?.slice(0, 2).map((name) => (
              <p key={name} className={styles.brandNameText}>{name}</p>
            ))}
            <p className={styles.brandNameText}>
              {`and ${value.length - 2} more...`}
            </p>
          </TooltipComponent>
        ) : (
          value?.slice(0, 2).map((name) => (
            <p key={name} className={styles.brandNameText}>{name}</p>
          ))
        )
      );
    }

    if (booleanActions.includes(actions)) {
      return (
        <Switch checked={value} color="success" onChange={(_, checked) => handleRunningAdsSelect(checked, id, actions)} />
      );
    }

    return (
      <p className={styles.brandNameText}>
        {value.length > 20 ? `${value.slice(0, 20)}...` : value}
      </p>
    );
  };

  const columns = [
    {
      name: (
        <div className="d-flex align-items-center flex-column search-input-des">
          <SortWrapper handleSort={() => sortFields('actions')}>
            <div className="d-flex flex-row">
              <span className="variableHeaderText">Process</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('actions')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="Actions"
            value={processFilter || ''}
            onChange={({ target: { value } }) => setProcessFilter(value)}
          />
        </div>
      ),
      minWidth: '190px',
      cell: ({ actions }) => (
        <span>{actions}</span>
      ),
    },
    {
      name: (
        <div className="d-flex align-items-center flex-column search-input-des">
          <SortWrapper handleSort={() => sortFields('type')}>
            <div className="d-flex flex-row">
              <span className="variableHeaderText">Type</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('type')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="Type"
            value={typeValue || ''}
            onChange={({ target: { value } }) => setTypeValue(value)}
          />
        </div>
      ),
      minWidth: '174px',
      cell: ({ type }) => (type === 'managerIds' ? 'Manager Id\'s' : type),
    },
    {
      name: (
        <div className="d-flex align-items-center flex-column search-input-des">
          <SortWrapper handleSort={() => sortFields('value')}>
            <div className="d-flex flex-row">
              <span className="variableHeaderText">Value</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('value')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="Value"
            value={valueFilter || ''}
            onChange={({ target: { value } }) => setValueFilter(value)}
          />
        </div>
      ),
      minWidth: '150px',
      cell: ({
        value, actions, managerNames, _id, 
      }) => (
        <div className={styles.brandNameWrapper}>
          { valueOutput(value, actions, managerNames, _id) }
        </div>
      ),
    },
    {
      name: (
        <div className="d-flex align-items-center flex-column search-input-des">
          <SortWrapper handleSort={() => sortFields('processedBrandName')}>
            <div className="d-flex flex-row">
              <span className="variableHeaderText">Brand Name</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('processedBrandName')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="Brand Name"
            value={brandNameFilter || ''}
            onChange={({ target: { value } }) => setBrandNameFilter(value)}
          />
        </div>
      ),
      minWidth: '174px',
      cell: ({ brandName }) => {
        if (brandName?.length > 1) return 'All brands';
        if (brandName?.length === 0) return '-';
        return (
          <div className={styles.brandNameWrapper}>
            {brandName?.map((name) => (
              <p key={name} className={styles.brandNameText}>{name}</p>
            ))}
          </div>
        );
      },
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('variant')}>
          <div className="d-flex flex-row">
            <span className="variableHeaderText">Variant</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('variant')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '100px',
      cell: ({ variant }) => (variant ? `${variant.charAt(0).toUpperCase()}${variant.slice(1)}` : '-'),
    },
    {
      name: 'Actions',
      minWidth: '200px',
      cell: ({
        brandName, _id, actions, value, variant,
      }) => (
        <>
          {
            (!booleanActions.includes(actions)) && (
              <button 
                type="button"  
                className="btn btn-success btn-sm me-1 p-1" 
                onClick={() => editAction(_id, brandName, actions, value, variant)}
              >
                <FontAwesomeIcon icon={faPencil} />
              </button>
            )
          }

          {brandName.length <= 1 && (
            <button 
              type="button" 
              className="btn btn-danger btn-sm me-1 p-1"
              onClick={() => deleteAction(_id)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          ) }
        </>
      ),
    },
  ];

  return columns;
};

