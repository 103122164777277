let navigator;

export const setNavigator = (nav) => {
  navigator = nav;
};

export const logout = () => {
  localStorage.removeItem('token');
  sessionStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('userId');

  navigator.push('/login');
  window.location.reload();
};

export const login = () => {
  navigator.push('/');
};
