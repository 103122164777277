import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { deleteCurrencyRule } from '../../redux/currencyRule/currencyRuleActions';
import EditCurrencyModal from './modals/EditCurrencyModal';
import { ModalContext } from '../../context';
import {
  prettyCutOutputByCellWidth, valueOutputCutLength, omitColumn, sortColumnByOrderConfig, 
} from '../../DataTableSchemas/helper';
import { TooltipComponent } from '../../components/TooltipComponent/TooltipComponent';
import { SortWrapper, useTableSorting } from '../../helpers/sortHelper';
import SortIcon from '../../components/SortIcon';

export function CurrenciesSchema({
  columnConfig,
  handleSort,
  currencyFilter,
  setCurrencyFilter,
  countriesFilter,
  setCountriesFilter,
}) {
  const dispatch = useDispatch();
  const { showModal } = useContext(ModalContext);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const allCountries = useSelector((state) => state.users.countries);
  const isUserCanDeleteCurrency = permissionName && !!permissionName.length && permissionName.includes('delete_currency');
  const isUserCanEditCurrency = permissionName && !!permissionName.length && permissionName.includes('edit_currency');

  const { sortFields, getSortIcon } = useTableSorting(handleSort);

  const deleteCurrency = (id) => {
    Swal.fire({
      title: 'Are you sure want to delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCurrencyRule(id));
      }
    });
  };

  const makeListOfRecords = ({ state = [], names = [], nameField = '' }) => {
    let list = '';

    // eslint-disable-next-line no-restricted-syntax
    for (const record of state) {
      if (names.includes(record.nicename)) {
        list += `${record[nameField]}, `;
      }
    }
    
    list = list.length ? list.slice(0, -2) : '';

    return list;
  };

  const columns = [
    {
      name: (
        <div className="d-flex align-items-start flex-column search-input-des pt-2">
          <SortWrapper handleSort={() => sortFields('symbol')}>
            <div className="d-flex flex-row">
              <span>Currency</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('symbol')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            name="Currency"
            autoComplete="off"
            value={currencyFilter || ''}
            onChange={({ target: { value } }) => setCurrencyFilter(value)}
          />
        </div>
      ),
      cell: ({ symbol }) => symbol,
      label: 'Currency',
      reorder: true,
      omit: omitColumn(columnConfig, 'Currency'),
    }, 
    {
      name: (
        <div className="d-flex align-items-start flex-column search-input-des pt-2">
          <SortWrapper handleSort={() => sortFields('country_first_name')}>
            <div className="d-flex flex-row">
              <span>Included Countries</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('country_first_name')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            name="Countries"
            autoComplete="off"
            value={countriesFilter || ''}
            onChange={({ target: { value } }) => setCountriesFilter(value)}
          />
        </div>
      ),
      cell: ({ countries }) => {
        const listOfIncludesCountry = makeListOfRecords({ state: allCountries, names: countries, nameField: 'nicename' });

        if (listOfIncludesCountry.length >= valueOutputCutLength.pspCountries) {
          return (
            <TooltipComponent
              title={listOfIncludesCountry}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                  {prettyCutOutputByCellWidth(listOfIncludesCountry, 'pspCountries')}
                </span>
            )}
            />
          );
        }
        return (
          <span style={{ whiteSpace: 'nowrap' }}>
            {listOfIncludesCountry}
          </span>
        );
      },
      minWidth: '170px',
      label: 'Included Countries',
      reorder: true,
      omit: omitColumn(columnConfig, 'Included Countries'),
    }, 
  ];

  if (isUserCanEditCurrency || isUserCanDeleteCurrency) {
    columns.push({
      name: 'Actions',
      cell: (rule) => (
        <div className="action-buttons">
          {
                isUserCanEditCurrency && (
                <button 
                  type="button"
                  className="btn btn-success btn-sm me-1 p-1"
                  onClick={() => showModal({
                    headerContent: <h3>{`Edit ${rule.symbol}`}</h3>,
                    bodyContent: <EditCurrencyModal rule={rule} />, 
                  })}
                >
                  <FontAwesomeIcon icon={faPencil} className="header-icon text-white" />
                </button>
                )
            }
          {
                isUserCanDeleteCurrency && (
                <button 
                  type="button"
                  className="btn btn-danger btn-sm me-1 p-1"
                  onClick={() => deleteCurrency(rule._id)}
                >
                  <FontAwesomeIcon icon={faTrash} className="header-icon text-white" />
                </button>
                )
            }
        </div>
      ),
      label: 'Actions',
      reorder: true,
      omit: omitColumn(columnConfig, 'Actions'),
    });
  }

  return sortColumnByOrderConfig(columns, columnConfig);
}
