export const whitelistColumns = [
  {
    name: 'Name',
    field: 'name',
    selected: true,
    order: 1,
  },
  {
    name: 'CRM User',
    field: 'managerName',
    selected: true,
    order: 2,
  },
  {
    name: 'IP Address',
    field: 'ip',
    selected: true,
    order: 3,
  },
  {
    name: 'Created',
    field: 'createdAt',
    selected: true,
    order: 4,
  },
  {
    name: 'Actions',
    field: '',
    selected: true,
    order: 5,
  },
];
    
    
  
