import React, { useEffect, useState } from 'react';
import Header from './Header';
import NavigationMenu from './NavigationMenu';
import useIsSuperUser from '../hooks/isSuperAdmin';
import { ENV } from '../config/config';

function PrivateLayout({ title, children }) {
  const isSuperUser = useIsSuperUser();
  const [authToken, setAuthToken] = useState();

  useEffect(() => {
    if (title && title !== 'User Detail') document.title = title;
    else document.title = 'RelateXpert';
    if (isSuperUser) document.title = ENV.brandName;
  }, [title, isSuperUser]);
  
  useEffect(() => {
    const token = localStorage.token ?? sessionStorage.token;
    setAuthToken(token);

    if (!token) {
      window.location.href = '/login';
    } else if (localStorage.userId) {
      // const validateUserId = localStorage.userId.slice(1, -1);
      // dispatch(userLastActivity(validateUserId));
      // setInterval(() => {
      // dispatch(userLastActivity(validateUserId));
      // }, 60000);
    }
  }, []);

  return (
    authToken
      ? (
        <>
          <Header />
          <div className="dashboard-wrapper main-padding">
            <div className="row">
              <NavigationMenu />
              {children}
            </div>
          </div>
        </>
      )
      : null
  );
}
export default PrivateLayout;
