export const AdminWalletsColumns = [
  {
    name: 'Address',
    field: 'address',
    order: 1,
    selected: true,
  },
  {
    name: 'Currency',
    field: 'currency',
    order: 2,
    selected: true,
  },
  {
    name: 'Network',
    field: 'network',
    order: 3,
    selected: true,
  },
  {
    name: 'In Use',
    order: 4,
    selected: true,
  },
  {
    name: 'Actions',
    field: '',
    order: 5,
    selected: true,
  },
];

