import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faExternalLink, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import Swal from 'sweetalert2';
import { 
  setUserNameFilterCreator,
  setVerifyStatusFilterCreator,
  setTimeUploadedFilterCreator, 
  deleteKycAmlRequest,
} from '../redux/kycAml/kycAmlActions';
import { 
  selectUserNameSearchFilter,
  selectVerifyStatusSearchFilter,
  selectTimeUploadedFilter,
  selectKycStatuses, 
} from '../redux/kycAml/kycAmlSelectors';
import { ModalContext } from '../context';
import KycAmlRequests from '../pages/KycAml/KycAmlRequests/KycAmlRequests';
import {
  omitColumn, formatDate, dateOptions, prettyCutOutputByCellWidth, valueOutputCutLength, sortColumnByOrderConfig,
} from './helper';
import { CopyToClipboardCmp, ErrorSpan, ModalFilters } from '../components';
import { TooltipComponent } from '../components/TooltipComponent/TooltipComponent';
import SortIcon from '../components/SortIcon';
import { SortWrapper, useTableSorting } from '../helpers/sortHelper';

export const KycAmlColumns = ({
  columnConfig, setKycQuery, kycQuery, handleSort, idFilter, setIdFilter,
}) => {
  const dispatch = useDispatch();

  const { showModal } = useContext(ModalContext);
  
  // filters variables
  const nameSearchFilter = useSelector(selectUserNameSearchFilter);
  const timeUploadedFilter = useSelector(selectTimeUploadedFilter);
  const verifyStatusSearchFilter = useSelector(selectVerifyStatusSearchFilter);
  const verifiedStatuses = useSelector(selectKycStatuses);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const isUserCanDeleteKYC = permissionName && permissionName.length > 0 && permissionName.includes('delete_kyc');

  const deleteKycAmlAndRequests = (id, userId) => {
    Swal.fire({
      title: 'Are you sure want to delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteKycAmlRequest(id, userId));
      }
    });
  };

  const openVerifyRequestsPage = (userId) => {
    showModal({
      headerContent: <h3>KYC/AML Requests</h3>,
      bodyContent: <KycAmlRequests userId={userId} />,
    });
  };

  const { sortFields, getSortIcon } = useTableSorting(handleSort);

  const columns = [
    {
      name: (
        <div className="d-flex align-items-start flex-column" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('customId')}>
            <span>ID</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('customId')} />
            </button>
          </SortWrapper>
          <input
            type="text"
            name="id"
            autoComplete="off"
            value={idFilter || ''}
            placeholder="Search"
            className="email-templates-search-bar-filter"
            onChange={({ target: { value } }) => setIdFilter(value)}
          />
        </div>
      ),
      minWidth: '80px',
      reorder: true,
      label: 'ID',
      cell: ({ customId }) => <span><CopyToClipboardCmp label={`${customId}`} /></span>,
      omit: omitColumn(columnConfig, 'ID'), 
    },
    {
      name: (
        <div className="d-flex align-items-start flex-column" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('userInfo.firstName userInfo.lastName')}>
            <div className="d-flex flex-row">
              <span>Client</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('userInfo.firstName userInfo.lastName')} />
              </button>
            </div>
          </SortWrapper>
          <input
            type="text"
            name="name"
            autoComplete="off"
            value={nameSearchFilter || ''}
            placeholder="Filter by user name"
            className="email-templates-search-bar-filter"
            onChange={({ target: { value } }) => dispatch(setUserNameFilterCreator(value))}
          />
        </div>
      ),
      minWidth: '200px',
      reorder: true,
      cell: ({ userInfo: { firstName, lastName }, userId }) => {
        const fullName = `${firstName} ${lastName}`;
        return (
          <div>
            {
              fullName.length >= valueOutputCutLength.client
                ? (
                  <TooltipComponent
                    title={fullName}
                    // eslint-disable-next-line react/no-children-prop
                    children={(
                      <Link to={`/user-detail/${userId}`} target="blank" className="text-white" style={{ whiteSpace: 'nowrap' }}>
                        <FontAwesomeIcon icon={faExternalLink} size="xs" />
                        <span 
                          className="cfdSccounts-row_fullName"
                        >
                          {prettyCutOutputByCellWidth(fullName, 'client')}
                        </span>
                      </Link>
                    )}
                  />
                )
                : (
                  <Link to={`/user-detail/${userId}`} target="blank" className="text-white" style={{ whiteSpace: 'nowrap' }}>
                    <FontAwesomeIcon icon={faExternalLink} size="xs" />
                    <span 
                      className="cfdSccounts-row_fullName"
                    >
                      {fullName}
                    </span>
                  </Link>
                )
            }
          </div>
        );
      },
      label: 'Client',
      omit: omitColumn(columnConfig, 'Client'), 
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('verifiedStatus')}>
            <div className="d-flex flex-row">
              <span>Status</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('verifiedStatus')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Status" 
            data={verifiedStatuses}
            filters={verifyStatusSearchFilter}
            setFilters={(value) => dispatch(setVerifyStatusFilterCreator(value))}
            searchParams={{ id: 'value', params: ['name'], optionsName: ['name'] }}
            usersFilters={kycQuery}
            setUserFilters={setKycQuery}
          />
        </div>
      ),
      reorder: true,
      minWidth: '190px',
      cell: ({ verifiedStatus }) => (
        verifiedStatus.length >= valueOutputCutLength.status
          ? (
            <TooltipComponent
              title={verifiedStatus}
                // eslint-disable-next-line react/no-children-prop
              children={(
                <span 
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {prettyCutOutputByCellWidth(verifiedStatus, 'status')}
                </span>
                )}
            />
          )
          : (
            <span 
              style={{ whiteSpace: 'nowrap' }}
            >
              {prettyCutOutputByCellWidth(verifiedStatus, 'status')}
            </span>
          )
      ),
      label: 'Status',
      omit: omitColumn(columnConfig, 'Status'), 
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('createdAt')}>
            <div className="d-flex flex-row">
              <span>Time Uploaded</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('createdAt')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Time Uploaded" 
            data={dateOptions} 
            filters={timeUploadedFilter} 
            setFilters={(value) => dispatch(setTimeUploadedFilterCreator(value))}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={kycQuery}
            setUserFilters={setKycQuery}
            isDateOptions
          />
        </div>
      ),
      cell: ({ createdAt }) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {createdAt
            ? prettyCutOutputByCellWidth(formatDate(new Date(createdAt)), 'localTime') 
            : '-'}
        </span>
      ),
      reorder: true,
      minWidth: '160px',
      label: 'Time Uploaded',
      omit: omitColumn(columnConfig, 'Time Uploaded'), 
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('updatedAt')}>
          <div className="d-flex flex-row">
            <div className="d-flex flex-column search-input-des" style={{ alignItems: 'start', whiteSpace: 'nowrap' }}>
              Time Processed
            </div>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('updatedAt')} />
            </button>
          </div>
        </SortWrapper>
      ),
      cell: ({ updatedAt }) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {updatedAt
            ? prettyCutOutputByCellWidth(formatDate(new Date(updatedAt)), 'localTime') 
            : '-'}
        </span>
      ),
      reorder: true,
      minWidth: '160px',
      label: 'Time Processed',
      omit: omitColumn(columnConfig, 'Time Processed'),
      selector: (row) => row.updatedAt,
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('totalInfo')}>
          <div className="d-flex flex-row">
            <div className="d-flex flex-column search-input-des" style={{ alignItems: 'start', whiteSpace: 'nowrap' }}>
              Info
            </div>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('totalInfo')} />
            </button>
          </div>
        </SortWrapper>
      ),
      cell: ({ totalInfo = '' }) => (
        totalInfo.length ? (
          <TooltipComponent title={totalInfo}>
            <InfoIcon fontSize="medium" />
          </TooltipComponent>
        ) : '-'
      ),
      reorder: true,
      label: 'Info',
      omit: omitColumn(columnConfig, 'Info'),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des" style={{ alignItems: 'start' }}>
          Actions
        </div>
      ),
      cell: ({ userId, _id }) => (
        <>
          <button 
            type="button" 
            className="btn btn-success btn-sm me-1 p-1"
            onClick={() => openVerifyRequestsPage(userId)}
          >
            <FontAwesomeIcon icon={faPencil} size="sm" style={{ marginRight: '5px' }} />
          </button>
          {
            isUserCanDeleteKYC && (
              <button 
                type="button" 
                className="btn btn-danger btn-sm me-1 p-1"
                onClick={() => deleteKycAmlAndRequests(_id, userId)}
              >
                <FontAwesomeIcon icon={faTrash} size="sm" />
              </button>
            )
          }
        </>
      ),
      reorder: true,
      minWidth: '100px',
      label: 'Actions',
      omit: omitColumn(columnConfig, 'Actions'), 
    },
  ];

  return sortColumnByOrderConfig(columns, columnConfig);
};
