export const SET_KYC_AML_DATA = 'SET_KYC_AML_DATA';
export const EDIT_KYC_AML_DATA = 'EDIT_KYC_AML_DATA';
export const SET_KYC_AML_REQUESTS = 'SET_KYC_AML_REQUEST';
export const SET_TOTAL_COUNT = 'SET_TOTAL_COUNT';
export const SET_USER_NAME_SEARCH_FILTER = 'SET_USER_NAME_SEARCH_FILTER';
export const SET_USER_EMAIL_SEARCH_FILTER = 'SET_USER_EMAIL_SEARCH_FILTER';
export const SET_USER_PHONE_SEARCH_FILTER = 'SET_USER_PHONE_SEARCH_FILTER';
export const SET_VERIFY_STATUS_SEARCH_FILTER = 'SET_VERIFY_STATUS_SEARCH_FILTER';
export const SET_TIME_UPLOADED_FILTER = 'SET_TIME_UPLOADED_FILTER';
export const SET_IS_KYC_AML_DATA_LOADING = 'SET_IS_KYC_AML_DATA_LOADING';
export const SET_IS_KYA_AML_REQUESTS_LOADING = 'SET_IS_KYA_AML_REQUESTS_LOADING';
export const GET_KYC_STATUSES = 'GET_KYC_STATUSES';
export const DELETE_KYC_AML_REQUEST = 'DELETE_KYC_AML_REQUEST';
export const DELETE_KYC_AML_DOCUMENT = 'DELETE_KYC_AML_DOCUMENT';
export const UPDATE_KYC_AML_STATUS_OF_USER = 'UPDATE_KYC_AML_STATUS_OF_USER';
