import {
  GET_ADMIN_BANK_ACCOUNTS,
  GET_ADMIN_BANK_ACCOUNT,
  ADD_ADMIN_BANK_ACCOUNT,
  EDIT_ADMIN_BANK_ACCOUNT,
  DELETE_ADMIN_BANK_ACCOUNT,
  SET_DEFAULT_ADMIN_BANK_ACCOUNT,
  SET_TOTAL_COUNT,
  SET_IS_LOADING,
  TOGGLE_STATE,
  ERROR_STATE,
} from './adminBankAccountTypes';

const initialState = {
  isLoading: false,
  totalCount: 0,
  adminBankAccounts: [],
  adminBankAccount: {},
  adminBankAccountsfetched: false,
  adminBankAccountAdded: false,
  adminBankAccountfetched: false,
  adminBankAccountEditted: false,
  adminBankAccountDeleted: false,
  adminBankAccountDefaultSet: false,
  error: false,
};

// eslint-disable-next-line default-param-last
const adminBankAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOTAL_COUNT:
      return {
        ...state,
        totalCount: action.payload,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case GET_ADMIN_BANK_ACCOUNTS:
      return {
        ...state,
        adminBankAccounts: action.payload,
        adminBankAccountsfetched: true,
      };
    case GET_ADMIN_BANK_ACCOUNT:
      return {
        ...state,
        adminBankAccount: action.payload,
        adminBankAccountfetched: true,
      };
    case ADD_ADMIN_BANK_ACCOUNT:
      return {
        ...state,
        adminBankAccounts: [action.payload, ...state.adminBankAccounts],
        totalCount: state.totalCount + 1,
        adminBankAccountAdded: true,
      };
    case EDIT_ADMIN_BANK_ACCOUNT:
      return {
        ...state,
        adminBankAccount: action.payload,
        adminBankAccounts: state.adminBankAccounts.map((item) => (item._id === action.payload._id ? action.payload : item)),
        adminBankAccountEditted: true,
      };
    case DELETE_ADMIN_BANK_ACCOUNT:
      return {
        ...state,
        adminBankAccounts: state.adminBankAccounts.filter((item) => item._id !== action.payload),
        totalCount: state.totalCount - 1,
        adminBankAccountDeleted: true,
      };
    case SET_DEFAULT_ADMIN_BANK_ACCOUNT:
      return {
        ...state,
        adminBankAccountDefaultSet: true,
      };
    case TOGGLE_STATE:
      return {
        ...state,
        adminBankAccountsfetched: false,
        adminBankAccountAdded: false,
        adminBankAccountfetched: false,
        adminBankAccountEditted: false,
        adminBankAccountDeleted: false,
        adminBankAccountDefaultSet: false,
        error: false,
      };
    case ERROR_STATE:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default adminBankAccountReducer;
