export const DISPLAY_NOTIFICATIONS = 'DISPLAY_NOTIFICATIONS';
export const DISPLAY_UNREAD_NOTIFICATIONS = 'DISPLAY_UNREAD_NOTIFICATIONS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_READ_NOTIFICATION = 'REMOVE_READ_NOTIFICATION';
export const REMOVE_ALL_READ_NOTIFICATION = 'REMOVE_ALL_READ_NOTIFICATION';
export const GET_NOTIFICATION_MODULES = 'GET_NOTIFICATION_MODULES';
export const NOTIFICATION_MODULES_STATE = 'NOTIFICATION_MODULES_STATE';
export const DISPLAY_ALL_NOTIFICATIONS_BY_ID = 'DISPLAY_ALL_NOTIFICATIONS_BY_ID';
export const MARK_NOTIFICATION_AS_SEEN = 'MARK_NOTIFICATION_AS_SEEN';
export const MARK_ALL_NOTIFICATIONS_AS_SEEN = 'MARK_ALL_NOTIFICATIONS_AS_SEEN';
