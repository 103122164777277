import React, { useMemo, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import {
  faClone, faComment, faCircleInfo, faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { editUser } from '../../redux/users/userActions';
import { getAdminComments } from '../../redux/adminComment/adminCommentActions';
import { ModalFilters } from '../../components';
import { RangeModalDebounceFilter } from '../../pages/Transactions/modalFilters';
import {
  omitColumn, 
  formatDate, 
  setTransactionActionColumn, 
  dateOptions, 
  prettyCutOutputByCellWidth, 
  valueOutputCutLength,
  sortColumnByOrderConfig,
} from '../helper';
import { getRateInUsdt } from '../../helpers/getRateInUSD';
import { TooltipComponent } from '../../components/TooltipComponent/TooltipComponent';
import SortIcon from '../../components/SortIcon';
import styles from './styles.module.css';
import { useTableSorting, SortWrapper } from '../../helpers/sortHelper';
import { updateTransactionFields } from '../../redux/transactions/transactionActions';
import useIsSuperUser from '../../hooks/isSuperAdmin';


export const ExternalTransactionsSchema = (
  setTransactionIdFilter,
  permissionName,
  currencies,
  transactionIdFilter,
  uidFilter,
  setUidFilter,
  nameFilter,
  setNameFilter,
  emailFilter, 
  secondEmailFilter, 
  setEmailFilter,
  setSecondEmailFilter,
  setAssetFilters,
  assetFilters,
  amountRange,
  setAmountRange,
  handleAdditionalInfoModalToggle,
  handleAdditionalCommentModalToggle,
  setManagerFilters,
  managerFilters,
  realFilter,
  setRealFilter,
  statusFilter,
  setStatusFilter,
  isAdditionalInfoModalShown,
  isAdditionalCommentModalShown,
  setIsAdditionalInfoModalShown,
  setIsAdditionalCommentModalShown,
  resolveCurrentDepositTransaction,
  handleRevertTransaction,
  assignToShow, 
  depositsFilters,
  setDepositsFilters,
  columnConfig,
  timeInitiatedFilter,
  setTimeInitiatedFilter,
  updateIsReal,
  handleDeleteTransaction,
  isDeleted,
  setDebounceAmountRange,
  brandsFilters,
  setBrandsFilters,
  balanceTypeFilter,
  setBalanceTypeFilter,
  handleSort,
  accountIdFilter,
  setAccountIdFilter,
  sourceFilter, 
  setSourceFilter,
  ibdeskUserFilter,
  setIbdeskUserFilter,
  allIbDesks,
  allAffiliates,
  affiliateFilter,
  setAffiliateFilter,
) => {
  const dispatch = useDispatch();
  const isSuperUser = useIsSuperUser();
  const [showComments, setShowComments] = useState(0);

  const adminComments = useSelector((state) => state?.adminComment?.adminComments);
  const [commentDate, setCommentDate] = useState(new Date());
  const currencyRates = useSelector((state) => state.currencyRates);
  const brands = useSelector((state) => state.brands);

  const isAffiliateRule = useMemo(() => (permissionName && permissionName.length && permissionName.includes('affiliates')), [permissionName]);

  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };

  function JSONparser(inputString) {
    if (!inputString) {
      console.warn('Empty string received for JSON parsing');
      return null;
    }
    try {
      const parsed = JSON.parse(inputString);
      return parsed;
    } catch (e) {
      console.error('JSON parse error:', e);
      return null;
    }
  }

  const handleShowComment = (userId, rowId) => {
    dispatch(getAdminComments(userId));
    if (adminComments.length > 0) {
      setCommentDate(new Date(adminComments[adminComments.length - 1].createdAt));
    } 

    setShowComments(rowId);
  };

  const handleChangeAssignTo = async (e, id) => {
    try {
      const data = {
        assignedTo: e.target.value,
      };
      await dispatch(updateTransactionFields(id, data));
    } catch (error) {
      toast.error(error);
    }
  };

  const isUserCanSeeClientCard = permissionName && permissionName.includes('clients');
  const isUserCanAssignToAgent = permissionName && permissionName.includes('assign_to_agent');

  const { sortFields, getSortIcon } = useTableSorting(handleSort);

  const columns = [
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des pt-2 ${styles.wrapperInput}`}>
          <SortWrapper handleSort={() => sortFields('customId')}>
            <div className="d-flex flex-row">
              <span>Transaction ID</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('customId')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="Transaction ID"
            value={transactionIdFilter || ''}
            onChange={({ target: { value } }) => setTransactionIdFilter(value)}
          />
          <span style={{ height: '20px' }} />
        </div>
      ),
      minWidth: '150px',
      reorder: true,
      label: 'Transaction ID',
      omit: omitColumn(columnConfig, 'Transaction ID'), 
      cell: ({ customId }) => (
        customId.length >= valueOutputCutLength.id
          ? (
            <TooltipComponent
              title={customId}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <CopyToClipboard text={customId}>
                  <span>      
                    <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                    {prettyCutOutputByCellWidth(customId, 'id')}
                  </span>
                </CopyToClipboard>
            )}
            />
          )
          : (
            <CopyToClipboard text={customId}>
              <span>      
                <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                {customId}
              </span>
            </CopyToClipboard>
          )
      ),
    },
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des pt-2 ${styles.wrapperInput}`}>
          <SortWrapper handleSort={() => sortFields('user.customId')}>
            <div className="d-flex flex-row">
              <span>Client ID</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('user.customId')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="Client ID"
            value={uidFilter || ''}
            onChange={(e) => setUidFilter(e.target.value)}
          />
        </div>
      ),
      minWidth: '160px',
      omit: omitColumn(columnConfig, 'Client ID'), 
      reorder: true,
      label: 'Client ID',
      cell: ({ user }) => (
        user?.customId.length >= valueOutputCutLength.id
          ? (
            <TooltipComponent
              title={user?.customId}
            // eslint-disable-next-line react/no-children-prop
              children={(
                <CopyToClipboard text={user?.customId}>
                  <span>      
                    <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                    {prettyCutOutputByCellWidth(user?.customId, 'id')}
                  </span>
                </CopyToClipboard>
          )}
            />
          )
          : (
            <CopyToClipboard text={user?.customId}>
              <span>
                <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                {user?.customId}
              </span>
            </CopyToClipboard>
          )
      ),
    },
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des pt-2 ${styles.wrapperInput}`}>
          <SortWrapper handleSort={() => sortFields('user.firstName user.lastName')}>
            <div className="d-flex flex-row">
              <span>Client</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('user.firstName user.lastName')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="Client"
            value={nameFilter || ''}
            onChange={({ target: { value } }) => setNameFilter(value)}
          />
        </div>
      ),
      omit: omitColumn(columnConfig, 'Client'), 
      reorder: true,
      label: 'Client',
      cell: ({ _id, user }) => {
        const fullName = `${user.firstName} ${user.lastName}`;
        return (
          <div style={{
            width: '100%',
            display: 'flex',
            gap: '3px',
            position: 'relative',
            justifyContent: 'flex-start',
          }}
          >
            {
              fullName.length >= valueOutputCutLength.fullName
                ? (
                  <TooltipComponent
                    title={fullName}
                    // eslint-disable-next-line react/no-children-prop
                    children={(
                        isUserCanSeeClientCard ? (
                          <Link to={`/user-detail/${user._id}`}>
                            <span data-tip={fullName} className="full-name-text">
                              {prettyCutOutputByCellWidth(fullName, 'fullName')}
                            </span>
                          </Link>
                        ) : (
                          <span data-tip={fullName} className="full-name-text">
                            {prettyCutOutputByCellWidth(fullName, 'fullName')}
                          </span>
                        )
                    )}
                  />
                )
                : (isUserCanSeeClientCard ? (
                  <Link to={`/user-detail/${user._id}`}>
                    <span data-tip={fullName} className="full-name-text">
                      {fullName}
                    </span>
                  </Link>
                ) : (
                  <span data-tip={fullName} className="full-name-text">
                    {fullName}
                  </span>
                ))
            }
          </div>
        );
      },
      minWidth: '194px',
    },
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des pt-2 ${styles.wrapperInput}`}>
          <SortWrapper handleSort={() => sortFields('user.email')}>
            <div className="d-flex flex-row">
              <span>Email</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('user.email')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            name="Email"
            autoComplete="off"
            value={emailFilter || ''}
            onChange={({ target: { value } }) => setEmailFilter(value)}
          /> 
        </div>
      ),
      omit: omitColumn(columnConfig, 'Email'), 
      minWidth: '180px',
      reorder: true,
      label: 'Email',
      cell: ({ user }) => (
        user?.email.length >= valueOutputCutLength.email 
          ? (
            <TooltipComponent
              title={user?.email}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <div>
                  <CopyToClipboard text={user.email}>
                    <span style={{ margin: '5px', whiteSpace: 'nowrap', cursor: 'pointer' }} data-tip={user.email}>
                      <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                      {prettyCutOutputByCellWidth(user?.email, 'email')}
                    </span>
                  </CopyToClipboard>
                </div>
                )}
            />
          )
          : (
            <div>
              <CopyToClipboard text={user?.email}>
                <span style={{ margin: '5px', whiteSpace: 'nowrap', cursor: 'pointer' }} data-tip={user?.email}>
                  <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                  {user?.email}
                </span>
              </CopyToClipboard>
            </div>
          )
      ),
    },
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des pt-2 ${styles.wrapperInput}`}>
          <SortWrapper handleSort={() => sortFields('user.secondEmail')}>
            <div className="d-flex flex-row">
              <span>Second Email</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('user.secondEmail')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            name="Second Email"
            autoComplete="off"
            value={secondEmailFilter || ''}
            onChange={({ target: { value } }) => setSecondEmailFilter(value)}
          />
        </div>
      ),
      reorder: true,
      label: 'Second Email',
      omit: omitColumn(columnConfig, 'Second Email'), 
      minWidth: '180px',
      cell: ({ user: { secondEmail = '' } = {} }) => (
        secondEmail.length >= valueOutputCutLength.secondEmail 
          ? (
            <TooltipComponent
              title={secondEmail}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <div>
                  <CopyToClipboard text={secondEmail}>
                    <span style={{ margin: '5px', whiteSpace: 'nowrap', cursor: 'pointer' }} data-tip={secondEmail}>
                      <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                      {prettyCutOutputByCellWidth(secondEmail, 'secondEmail')}
                    </span>
                  </CopyToClipboard>
                </div>
                )}
            />
          ) : secondEmail.length === 0 ? '-' : (
            <div>
              <CopyToClipboard text={secondEmail}>
                <span style={{ margin: '5px', whiteSpace: 'nowrap', cursor: 'pointer' }} data-tip={secondEmail}>
                  <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                  {secondEmail}
                </span>
              </CopyToClipboard>
            </div>
          )
      ),
    },
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des pt-2 ${styles.wrapperInput}`}>
          <SortWrapper handleSort={() => sortFields('account.customId')}>
            <div className="d-flex flex-row">
              <span>Account ID</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('account.customId')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="Account ID"
            value={accountIdFilter || ''}
            onChange={(e) => setAccountIdFilter(e.target.value)}
          />
        </div>
      ),
      minWidth: '160px',
      omit: omitColumn(columnConfig, 'Account Id'), 
      reorder: true,
      label: 'Account Id',
      cell: ({ accountId = '' }) => (
        accountId.length >= valueOutputCutLength.id
          ? (
            <TooltipComponent
              title={accountId}
            // eslint-disable-next-line react/no-children-prop
              children={(
                <CopyToClipboard text={accountId}>
                  <span>      
                    <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                    {prettyCutOutputByCellWidth(accountId, 'id')}
                  </span>
                </CopyToClipboard>
          )}
            />
          )
          : (
            <CopyToClipboard text={accountId}>
              <span>
                <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                {accountId}
              </span>
            </CopyToClipboard>
          )
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('ibdesk.fullName')}>
            <div className="d-flex flex-row">
              <span>Desk/IB</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('ibdesk.fullName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Desk/IB"
            filterName="ibdeskUser"
            data={allIbDesks}
            filters={ibdeskUserFilter}
            setFilters={setIbdeskUserFilter}
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={depositsFilters}
            setUserFilters={setDepositsFilters}
            addNonSelected
          />
        </div>
      ),
      minWidth: '170px',
      cell: ({ ibdesk }) => {
        if (!ibdesk) return '-';
        const fullManagerName = ibdesk?.fullName;

        return (
          fullManagerName.length >= valueOutputCutLength.ibdesk
            ? (
              <TooltipComponent
                title={fullManagerName}
                              // eslint-disable-next-line react/no-children-prop
                children={(
                  <Link
                    to={`/edit-admin/${ibdesk?._id}`}
                    className="text-decoration-none"
                  >
                    {prettyCutOutputByCellWidth(fullManagerName, 'ibdesk')}
                  </Link>
                              )}
              />
            )
            : (
              <Link
                to={`/edit-admin/${ibdesk?._id}`}
                className="text-decoration-none"
              >
                {fullManagerName}
              </Link>
            )
        );
      },
      reorder: true,
      label: 'Desk/IB',
      omit: omitColumn(columnConfig, 'Desk/IB'),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('assignedToUser.firstName assignedToUser.lastName')}>
            <div className="d-flex flex-row">
              <span>Assigned To</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('assignedToUser.firstName assignedToUser.lastName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Assigned To" 
            data={assignToShow}
            filters={managerFilters}
            setFilters={setManagerFilters}
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={depositsFilters}
            storageName="Assigned To"
            setUserFilters={setDepositsFilters}
            addNonSelected
          />
        </div>
      ),
      minWidth: '190px',
      omit: omitColumn(columnConfig, 'Assigned To'), 
      reorder: true,
      label: 'Assigned To',
      cell: ({ _id, assignedToUser }) => (
        assignedToUser
          ? (
            isUserCanAssignToAgent ? (
              <select 
                className="form-control user-status-select leads-status-select"
                name="type"
                defaultValue={assignedToUser?._id}
                style={{ color: assignedToUser?.color ? assignedToUser?.color : '#fff' }}
                onChange={(e) => handleChangeAssignTo(e, _id)}
              >
                <option value="" style={{ color: '#fff' }} color="#fff">Select Status</option>
                <option value={assignedToUser._id} style={{ color: '#fff' }} color="#fff">{`${assignedToUser.firstName} ${assignedToUser.lastName}`}</option>
                {assignToShow?.length > 0 && assignToShow.map((item) => {
                  if (item._id !== assignedToUser._id) {
                    return (
                      <option key={item._id} value={item._id}>{`${item.firstName} ${item.lastName}`}</option>
                    ); 
                  }
                  return null;
                }) }
              </select>
            ) : (
              <div>{`${assignedToUser.firstName} ${assignedToUser.lastName}`}</div>
            )
          ) : '-'
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('amount')}>
            <div className="d-flex flex-row">
              <span>Amount</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('amount')} />
              </button>
            </div>
          </SortWrapper>
          <RangeModalDebounceFilter 
            value={amountRange ?? [0, 1000000]}
            setValue={setAmountRange}
            setFilters={setDebounceAmountRange}
            filters={{}}
            filedName="amount"
            debounceAmountRange
          />
        </div>
      ),
      minWidth: '110px',
      omit: omitColumn(columnConfig, 'Amount'), 
      reorder: true,
      label: 'Amount',
      selector: ({ amount }) => (
        amount?.toString()?.length >= valueOutputCutLength.balance 
          ? (
            <TooltipComponent 
              title={amount.toString()}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(amount, 'balance')}
                </span>
              )}
            />
          )
          : (
            <span>
              {amount}
            </span>
          )
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('currency')}>
            <div className="d-flex flex-row">
              <span>Currency</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('currency')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Currency" 
            data={currencies} 
            filters={assetFilters} 
            setFilters={setAssetFilters} 
            searchParams={{ id: '_id', params: ['name', '_id', 'symbol'], optionsName: ['symbol'] }}
            usersFilters={depositsFilters}
            setUserFilters={setDepositsFilters}
          />
        </div>
      ),
      omit: omitColumn(columnConfig, 'Currency'), 
      reorder: true,
      label: 'Currency',
      minWidth: '120px',
      selector: ({ currency }) => currency,
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('balanceType')}>
            <div className="d-flex flex-row">
              <span>Type</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('balanceType')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Type" 
            data={[{ name: 'Balance', _id: 'true' }, { name: 'Credit', _id: 'false' }]}
            filters={balanceTypeFilter}
            setFilters={setBalanceTypeFilter}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={depositsFilters}
            setUserFilters={setDepositsFilters}
          />
        </div>
      ),
      omit: omitColumn(columnConfig, 'Type'), 
      reorder: true,
      label: 'Type',
      minWidth: '120px',
      selector: ({ balanceType = true }) => (balanceType ? 'Balance' : 'Credit'),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('brand.name')}>
            <div className="d-flex flex-row">
              <span>Brand</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('brand.name')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Brand" 
            data={brands} 
            filters={brandsFilters} 
            setFilters={setBrandsFilters} 
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={depositsFilters}
            setUserFilters={setDepositsFilters}
            addNonSelected
          />
        </div>
      ),
      omit: omitColumn(columnConfig, 'Brand'), 
      reorder: true,
      label: 'Brand',
      minWidth: '130px',
      selector: ({ brand }) => (
        brand?.name?.length >= valueOutputCutLength.brand 
          ? (
            <TooltipComponent 
              title={brand?.name}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(brand?.name ?? '', 'brand')}
                </span>
              )}
            />
          )
          : (
            <span>
              {brand?.name ?? ''}
            </span>
          )
      ),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('convertedAmount')}>
          <div className="d-flex flex-row">
            <span>In USD</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('convertedAmount')} />
            </button>
          </div>
        </SortWrapper>
      ),
      selector: (row) => parseFloat(row?.amount).toFixed(2),
      minWidth: '110px',
      reorder: true,
      label: 'In USD',
      omit: omitColumn(columnConfig, 'In USD'), 
      cell: ({ currency, amount, inUSD }) => {
        const usdtValue = inUSD ?? getRateInUsdt(currency, amount, currencyRates);
        return (
          usdtValue?.toFixed(2).toString().length >= valueOutputCutLength.balance 
            ? (
              <TooltipComponent 
                title={usdtValue?.toFixed(2).toString()}
              // eslint-disable-next-line react/no-children-prop
                children={(
                  <span style={{ cursor: 'pointer' }}>
                    {prettyCutOutputByCellWidth(usdtValue?.toFixed(2).toString(), 'balance')}
                  </span>
              )}
              />
            )
            : (
              <span>
                {usdtValue?.toFixed(2) ?? '-'}
              </span>
            )
        );
      },
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('user.affiliateFullName')}>
            <div className="d-flex flex-row">
              <span>Affiliate Name</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('user.affiliateFullName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Affiliate Name"
            data={allAffiliates}
            filters={affiliateFilter}
            setFilters={setAffiliateFilter}
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={depositsFilters}
            setUserFilters={setDepositsFilters}
            addNonSelected
          />
        </div>
      ),
      minWidth: '180px',
      selector: ({ user }) => user?.affiliateFullName ?? '-',
      cell: ({ _id, user }) => {
        const affiliateFullName = user?.affiliateFullName;

        if (affiliateFullName) {
          return (
            affiliateFullName.length >= valueOutputCutLength.affiliateFullName
              ? (
                <TooltipComponent
                  title={affiliateFullName}
                  // eslint-disable-next-line react/no-children-prop
                  children={(
                    <div className="truncate-text" style={{ cursor: 'pointer' }} data-for={`aff-${_id}`} data-tip={`${affiliateFullName}`}>
                      {prettyCutOutputByCellWidth(affiliateFullName, 'affiliateFullName')}
                    </div>
                  )}
                />
              )
              : (
                <div
                  className="truncate-text"
                  data-for={`aff-${_id}`}
                  data-tip={`${affiliateFullName}`}
                >
                  {affiliateFullName}
                </div>
              )
          );
        }
        return '-';
      },
      reorder: true,
      label: 'Affiliate Name',
      omit: omitColumn(columnConfig, 'Affiliate Name') || !isAffiliateRule,
    },
    {
      name: (
        <div className="d-flex flex-row search-input-des p-2 pl-0" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('user.source')}>
            <div className="d-flex flex-row">
              <span>Funnel Name</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('user.source')} />
              </button>
            </div>
          </SortWrapper>
        </div>
      ),
      minWidth: '170px',
      omit: omitColumn(columnConfig, 'Funnel Name'),
      reorder: true,
      label: 'Funnel Name',
      selector: (row) => (
        <div>
          {
                      row?.user?.source?.length >= valueOutputCutLength.funnelName
                        ? (
                          <TooltipComponent
                            title={row?.user?.source}
                            // eslint-disable-next-line react/no-children-prop
                            children={(
                              <div className="truncate-text" style={{ cursor: 'pointer', margin: '5px' }} data-for={`aff-${row._id}`} data-tip={`${row?.user?.source}`}>
                                {prettyCutOutputByCellWidth(row?.user?.source, 'funnelName')}
                              </div>
                                  )}
                          />
                        )
                        : (
                          <div className="truncate-text" style={{ margin: '5px' }} data-for={`funnel-${row._id}`} data-tip={row?.user?.source}>
                            {row?.user?.source ?? '-'}
                          </div>
                        )
              }
        </div>
      ),
    },
    {
      name: (
        <div className="d-flex flex-row search-input-des p-2 pl-0" style={{ alignItems: 'start' }}>
          <SortWrapper handleSort={() => sortFields('user.additionalInfo')}>
            <div className="d-flex flex-row">
              <span>Additional Info</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('user.additionalInfo')} />
              </button>
            </div>
          </SortWrapper>
        </div>
      ),
      minWidth: '170px',
      omit: omitColumn(columnConfig, 'Additional Info'),
      reorder: true,
      label: 'Additional Info',
      selector: (row) => (
        <div>
          {
              row?.user?.additionalInfo?.length >= valueOutputCutLength.additionalInfo
                ? (
                  <TooltipComponent
                    title={row?.user?.additionalInfo}
                    // eslint-disable-next-line react/no-children-prop
                    children={(
                      <div className="truncate-text" style={{ cursor: 'pointer', margin: '5px' }} data-for={`aff-${row._id}`} data-tip={`${row?.user?.additionalInfo}`}>
                        {prettyCutOutputByCellWidth(row?.user?.additionalInfo, 'additionalInfo')}
                      </div>
                    )}
                  />
                )
                : (
                  <div className="truncate-text" style={{ margin: '5px' }} data-for={`funnel-${row._id}`} data-tip={row?.user?.additionalInfo}>
                    {row?.user?.additionalInfo || '-'}
                  </div>
                )
          }
        </div>
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('isResolved')}>
            <div className="d-flex flex-row">
              <span>Status</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('isResolved')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Status" 
            data={[{ name: 'Pending', _id: '0' }, { name: 'Completed', _id: '1' }, { name: 'Declined', _id: '2' }]}
            filters={statusFilter} 
            setFilters={setStatusFilter}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={depositsFilters}
            setUserFilters={setDepositsFilters}
          />
        </div>
      ),
      minWidth: '175px',
      omit: omitColumn(columnConfig, 'Status'), 
      reorder: true,
      label: 'Status',
      selector: ({
        _id, isResolved, additionalInfo, comment,
      }) => {
        const additionalInfoObj = JSONparser(additionalInfo);
        return (
          <>
            {isResolved === 0 ? (
              <span className="badge rounded-pill bg-warning">Pending</span>
            ) : isResolved === 1 ? (
              <span className="badge rounded-pill bg-success">Completed</span>
            ) : (
              <span className="badge rounded-pill bg-danger">Declined</span>
            )}
      
            <FontAwesomeIcon
              icon={faCircleInfo}
              className="ms-2"
              size="lg"
              color="#6E7F95"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleAdditionalInfoModalToggle(_id);
              }}
            />
      
            {isAdditionalInfoModalShown === _id ? (
              <div className="depositsAdditionalInfoModal">
                <h5>Additional Info</h5>
                <FontAwesomeIcon
                  icon={faTimes}
                  size="lg"
                  color="#6E7F95"
                  onClick={() => setIsAdditionalInfoModalShown('')}
                />
                {additionalInfoObj ? (
                  Object.hasOwnProperty.call(additionalInfoObj, 'name') ? (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                      <span>
                        Name:
                        {additionalInfoObj.name}
                      </span>
                      <span>
                        Card:
                        {additionalInfoObj.number}
                      </span>
                      <span>
                        CVC:
                        {additionalInfoObj.cvc.value}
                      </span>
                      <span>
                        Expire Date:
                        {additionalInfoObj.expiry}
                      </span>
                    </div>
                  ) : (
                    additionalInfo
                  )
                ) : (
                  additionalInfo?.length ? additionalInfo : 'No Info'
                )}
              </div>
            ) : null}
      
            <FontAwesomeIcon
              icon={faComment}
              className="ms-2"
              size="lg"
              color="#6E7F95"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleAdditionalCommentModalToggle(_id);
              }}
            />
      
            {isAdditionalCommentModalShown === _id && (
            <div className="depositsAdditionalCommentModal">
              <h5>Comment</h5>
              <FontAwesomeIcon
                icon={faTimes}
                size="lg"
                color="#6E7F95"
                onClick={() => setIsAdditionalCommentModalShown('')}
              />
              <div className="depositsAdditionalCommentModalIn">
                {comment && comment.length ? comment : 'No comments'}
              </div>
            </div>
            )}
          </>
        );
      },
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('createdAt')}>
            <div className="d-flex flex-row">
              <span>Time Initiated</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('createdAt')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Time Initiated" 
            data={dateOptions} 
            filters={timeInitiatedFilter} 
            setFilters={setTimeInitiatedFilter}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={depositsFilters}
            setUserFilters={setDepositsFilters}
            isDateOptions
          />
        </div>
      ),
      selector: ({ createdAt }) => (createdAt ? formatDate(new Date(createdAt)) : ''),
      omit: omitColumn(columnConfig, 'Time Initiated'),
      reorder: true,
      label: 'Time Initiated',
      minWidth: '154px',
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('processedAt')}>
          <div className="d-flex flex-row">
            <span>Transaction Time</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('processedAt')} />
            </button>
          </div>
        </SortWrapper>
      ),
      omit: omitColumn(columnConfig, 'Transaction Time'), 
      reorder: true,
      label: 'Transaction Time',
      selector: ({ processedAt }) => (processedAt ? formatDate(new Date(processedAt)) : '-'),
      minWidth: '154px',
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('isReal')}>
            <div className="d-flex flex-row">
              <span>Real/Fake</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('isReal')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Real/Fake" 
            data={[{ name: 'Fake', _id: false }, { name: 'Real', _id: true }]}
            filters={realFilter} 
            setFilters={setRealFilter}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={depositsFilters}
            setUserFilters={setDepositsFilters}
          />
        </div>
      ),
      minWidth: '130px',
      reorder: true,
      label: 'Real/Fake',
      omit: omitColumn(columnConfig, 'Real/Fake'), 
      selector: ({ isReal }) => (isReal !== undefined ? (isReal === true ? <span className="clr-green">Real</span> : <span className="clr-red">Fake</span>) : '-'),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('type')}>
            <div className="d-flex flex-row">
              <span>Source</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('type')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Source" 
            data={[
              { name: 'Admin Transaction', _id: 'Admin Transaction' }, 
              { name: 'Crypto', _id: 'Crypto' },
              { name: 'Card', _id: 'Card' },
              { name: 'Bank', _id: 'Bank' },
            ]}
            filters={sourceFilter} 
            setFilters={setSourceFilter}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={depositsFilters}
            setUserFilters={setDepositsFilters}
          />
        </div>
      ),
      minWidth: '130px',
      reorder: true,
      label: 'Source',
      omit: omitColumn(columnConfig, 'Source'), 
      selector: ({ type }) => (type),
    },
    {
      name: 'Action(s)',
      minWidth: '250px',
      omit: omitColumn(columnConfig, 'Actions'), 
      reorder: true,
      label: 'Actions',
      cell: (row) => setTransactionActionColumn(
        row, 
        permissionName,
        resolveCurrentDepositTransaction,
        updateIsReal,
        handleRevertTransaction,
        handleDeleteTransaction,
        isDeleted,
        dispatch,
        isSuperUser,
      ),
    },
  ];

  return sortColumnByOrderConfig(columns, columnConfig);
};

