import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { getAffiliateRule, deleteAffiliateRule } from '../redux/affiliateRule/affiliateRuleActions';
import { TooltipComponent } from '../components/TooltipComponent/TooltipComponent';
import { prettyCutOutputByCellWidth, valueOutputCutLength } from './helper';
import { SortWrapper, useTableSorting } from '../helpers/sortHelper';
import SortIcon from '../components/SortIcon';

export const AffiliateRuleColumns = (handleSort) => {
  const dispatch = useDispatch();
  const { sortFields, getSortIcon } = useTableSorting(handleSort);

  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };

  const handleAffiliateRuleDelete = async (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed === true) {
        await dispatch(deleteAffiliateRule(id));
      }
    });
  };

  const ruleColumns = [
    {
      name: (
        <SortWrapper handleSort={() => sortFields('_id')}>
          <div className="d-flex flex-row">
            <span>UID</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('_id')} />
            </button>
          </div>
        </SortWrapper>
      ),
      selector: ({ _id }) => (
        _id.length >= valueOutputCutLength.mongoId
          ? (
            <TooltipComponent
              title={_id}
            // eslint-disable-next-line react/no-children-prop
              children={(
                <div>
                  <CopyToClipboard text={_id}>
                    <span style={{
                      whiteSpace: 'nowrap', cursor: 'pointer', 
                    }}
                    >
                      <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                      {prettyCutOutputByCellWidth(_id, 'mongoId')}
                    </span>
                  </CopyToClipboard>
                </div>
            )}
            />
          )
          : (
            <CopyToClipboard text={_id}>
              <span style={{ margin: '5px', whiteSpace: 'nowrap' }}>
                <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                {_id}
              </span>
            </CopyToClipboard>
          )
      ),
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('isEqual value')}>
          <div className="d-flex flex-row">
            <span>Rule</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('isEqual value')} />
            </button>
          </div>
        </SortWrapper>
      ),
      selector: (row) => <span>{`${row?.key} ${row?.isEqual ? ' is' : ' is not'} "${row?.value}"`}</span>,
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('assignTo.firstName assignTo.lastName')}>
          <div className="d-flex flex-row">
            <span>Assign To</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('assignTo.firstName assignTo.lastName')} />
            </button>
          </div>
        </SortWrapper>
      ),
      selector: (row) => {
        if (Object.keys(row?.assignTo)?.length > 0) {
          return (
            <Link to={`/edit-admin/${row?.assignTo?._id}`} className="text-decoration-none">
              {`${row?.assignTo?.firstName} ${row?.assignTo?.lastName}`}
            </Link>
          );
        }
        return '-';
      },
    },
    {
      name: 'Action(s)',
      cell: (row) => (
        <>
          <button type="button" className="btn btn-success btn-sm me-1 p-1" onClick={() => dispatch(getAffiliateRule(row?._id))}>Edit</button>
          <button type="button" className="btn btn-danger btn-sm me-1 p-1" onClick={() => handleAffiliateRuleDelete(row?._id)}>Remove</button>
        </>
      ),
    },
  ];
  return ruleColumns;
};
