export const affiliateColumns = [
  {
    name: 'ID',
    field: 'customId',
    selected: true,
    order: 1,
  },
  {
    name: 'Full Name',
    field: 'fullName',
    selected: true,
    order: 2,
  },
  {
    name: 'Email',
    field: 'email',
    selected: true,
    order: 3,
  },
  {
    name: 'Actions',
    field: '',
    selected: true,
    order: 4,
  },
];
