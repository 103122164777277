import { toast } from 'react-toastify';
import {
  SHOW_ALL_CURRENCIES, GET_CURRENCY, ADD_CURRENCY, DELETE_CURRENCY, EDIT_CURRENCY, WALLET_CURRENCIES,
  TOGGLE_STATE, ERROR_STATE, SHOW_FOREX_CURRENCIES, FIAT_CURRENCY,
} from './currencyTypes';
import { apiHelper } from '../apiHelper';

export const showAllCurrencies = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/currency', '');
    if (res?.data) {
      const { data } = res;
      await dispatch({
        type: SHOW_ALL_CURRENCIES,
        payload: data,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const fiatCurrencies = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/currency/fiat');
    if (res?.data && res?.data?.allCurrencies) {
      const { data } = res;
      await dispatch({
        type: FIAT_CURRENCY,
        payload: data.allCurrencies,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const showForexCurrencies = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/currency/forex', '');
    if (res?.data && res?.data?.forexCurrencies) {
      const { data } = res;
      await dispatch({
        type: SHOW_FOREX_CURRENCIES,
        payload: data?.forexCurrencies,
      });
    } else {
      await dispatch({
        type: SHOW_FOREX_CURRENCIES,
        payload: [],
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const getCurrency = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/currency/${id}`, '');
    if (res?.data) {
      const { data } = res;
      await dispatch({
        type: GET_CURRENCY,
        payload: data,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const getCryptoCurrency = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/currency/crypto', '');

    if (res && res.data && res.data) {
      dispatch({
        type: GET_CURRENCY,
        payload: res.data,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const addCurrency = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/currency/add', data);

    if (res?.data) {
      const { data } = res;
      toast.success(res.data.message);
      await dispatch({
        type: ADD_CURRENCY,
        payload: data,
      });
    } else {
      await dispatch({
        type: ERROR_STATE,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const editCurrency = (id, data) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/currency/${id}`, data);
    if (res?.data) {
      const { data } = res;
      toast.success(res.data.message);
      await dispatch({
        type: EDIT_CURRENCY,
        payload: data,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const deleteCurrency = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/currency/${id}`, '');
    if (res?.data) {
      const { data } = res;
      toast.success(res.data.message);
      await dispatch({
        type: DELETE_CURRENCY,
        payload: data,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const walletCurrencies = (data) => async (dispatch) => {
  try {
    if (data) {
      toast.success(data.message);
      await dispatch({
        type: WALLET_CURRENCIES,
        payload: data,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const updateState = () => async (dispatch) => {
  try {
    await dispatch({
      type: TOGGLE_STATE,
    });
  } catch (error) {
    toast.error(error.message);
  }
};
