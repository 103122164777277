import React, { useState, useEffect } from 'react';
import styles from './CustomPaginationFooter.module.css';

export function CustomPaginationFooter({
  currentPage, 
  totalCount, 
  onPageChange, 
  rowsPerPage, 
  onRowsPerPageChange, 
  paginationRowsPerPageOptions = [],
}) {
  const [inputValue, setInputValue] = useState(currentPage);
  const totalPages = Math.ceil(totalCount / rowsPerPage);
  const offset = ((currentPage * rowsPerPage) - rowsPerPage) + 1;
  const toOffset = offset + rowsPerPage - 1 > totalCount ? totalCount : offset + rowsPerPage - 1;
  
  const handleInputChange = (e) => {
    const { value } = e.target;
    if (value === '' || /^[0-9\b]+$/.test(value)) {
      setInputValue(value);
    }
  };
  
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };
  
  const handleInputBlur = () => {
    const page = parseInt(inputValue, 10);
    if (!Number.isNaN(Number(page))) {
      handlePageChange(page);
    } else {
      setInputValue(currentPage);
    }
  };
  
  useEffect(() => {
    setInputValue(currentPage);
  }, [currentPage]);
  
  return (
    <nav className={styles.customPagination}>
      <span className={styles.title}>Rows per page:</span>
      <div className={styles.selectContainer}>
        <select 
          value={rowsPerPage} 
          onChange={(e) => onRowsPerPageChange(Number(e.target.value), currentPage)} 
          aria-label="Rows per page:" 
          className={styles.sSelect}
        >
          {paginationRowsPerPageOptions.map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="white">
          <path d="M7 10l5 5 5-5z" />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      </div>
      <span className={styles.title} style={{ margin: '0 24px' }}>
        {`${offset}-${toOffset} of ${totalCount}`}
      </span>
      <span className={styles.title} style={{ marginRight: '12px' }}>Page:</span>
      <input
        type="text"
        className={styles.input}
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            handleInputBlur();
          }
        }}
      />
      <div className={styles.btnRow}>
        <button onClick={() => handlePageChange(1)} id="pagination-first-page" type="button" aria-label="First Page">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation" fill="white">
            <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z" />
            <path fill="none" d="M24 24H0V0h24v24z" />
          </svg>
        </button>
        <button onClick={() => handlePageChange(currentPage - 1)} id="pagination-previous-page" type="button" aria-label="Previous Page">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation" fill="white">
            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </button>
        <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} id="pagination-next-page" type="button" aria-label="Next Page">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation" fill="white">
            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </button>
        <button onClick={() => handlePageChange(totalPages)} id="pagination-last-page" type="button" aria-label="Last Page">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation" fill="white">
            <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z" />
            <path fill="none" d="M0 0h24v24H0V0z" />
          </svg>
        </button>
      </div>
    </nav>
  );
}
