import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { showAllCurrencies } from '../../redux/currency/currencyActions';
import { addCustomSaving, getOptions } from '../../redux/saving/savingActions';

function AddCustomSaving() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [currencyName, setCurrencyName] = useState(null);
  const [currencyRate, setCurrencyRate] = useState(null);
  const [logo, setLogo] = useState(null);

  const savingOptions = useSelector((state) => state.saving.options);

  const { register, handleSubmit, formState: { errors } } = useForm();

  useEffect(() => {
    Promise.allSettled([dispatch(getOptions()), dispatch(showAllCurrencies())]);
  }, []);

  const handleFileChange = (e) => {
    setLogo(e.target.files[0]);
  };

  const setNewValueToOptions = (formData = {}) => {
    if (!Object.keys(formData).length || !savingOptions.length) return [];

    const options = savingOptions.map((option) => {
      const updatedPercent = formData[option._id] || 0;

      return { ...option, percent: Number(updatedPercent) };
    });

    return options;
  };

  const handleAddSavingCurrency = (formData) => {
    const data = {
      options: setNewValueToOptions(formData),
      currencyName,
      currencyRate,
    };
    const formDataToSend = new FormData();
    formDataToSend.append('data', JSON.stringify(data));
    if (logo) {
      formDataToSend.append('logo', logo);
    }

    dispatch(addCustomSaving(formDataToSend));
    history.goBack();
  };

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <FontAwesomeIcon className="faArrowLeftIcon" icon={faArrowLeft} onClick={() => history.goBack()} />
        <h3>Add New Custom Stalking</h3>
        <form onSubmit={handleSubmit(handleAddSavingCurrency)}>
          <div className="form-group col-md-12 pt-2 ">
            <label className="control-label">Currency</label>
            <input
              type="text"
              className="form-control"
              name="currency"
              placeholder="Enter custom currency name"
              onChange={(e) => setCurrencyName(e.target.value)}
            />
          </div>
          <div className="form-group col-md-12 pt-2 ">
            <label className="control-label">Rates (Currency to usd)</label>
            <input
              type="number"
              className="form-control"
              name="rateToUsd"
              min="0"
              step="0.000000001"
              placeholder="Enter worth of the currency to usd"
              onChange={(e) => setCurrencyRate(e.target.value)}
            />
          </div>
          {
                savingOptions.map((option) => (
                  <div className="form-group col-md-12 pt-2" key={option._id}>
                    <label className="control-label">{option.label}</label>
                    <input
                      type="number"
                      className="form-control"
                      {...register(`${option._id}`)}
                      name={option._id}
                      defaultValue={option.percent}
                    />
                    {errors[`${option._id}`] && <span className="errMsg">{errors[`${option._id}`].message}</span>}
                  </div>
                ))
              }
          <div className="form-group">
            <label>Logo</label>
            <input
              type="file"
              className="form-control"
              name="logo"
              accept="image/*"
              onChange={handleFileChange}
            />
          </div>
          <div>
            <button className="btn btn-default" type="submit">Save</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddCustomSaving;
