import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';
import { getSalesStatusesTableData } from '../../../redux/salesStatus/salesStatusActions';
import styles from './SegmentButtons.module.css';

const RETENTION_ROLE = 2;
const BOTH_ROLE = 3;

function SegmentButtons({ activeButton, setActiveButton, selectedBrand }) {
  const dispatch = useDispatch();

  const { salesRole: crmUserSalesRole } = useSelector((state) => state.crmUser.crmUserInfo);

  useEffect(() => {
    if (selectedBrand) dispatch(getSalesStatusesTableData(activeButton, selectedBrand));
  }, [activeButton, selectedBrand]);

  useEffect(() => {
    setActiveButton(crmUserSalesRole === RETENTION_ROLE ? 'retention-list' : 'sales-list');
  }, [crmUserSalesRole]);

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  return (
    <div className={styles.segmentButtons}>
      <button
        type="button"
        disabled={crmUserSalesRole !== BOTH_ROLE && activeButton !== 'sales-list'}
        className={`${styles.button} ${styles.leadsButton} ${activeButton === 'sales-list' ? styles.active : ''}`}
        onClick={() => handleButtonClick('sales-list')}
      >
        <div className={styles.buttonContent}>
          {activeButton === 'sales-list' ? <CheckIcon sx={{ height: '20px' }} /> : null}
          Leads
        </div>
      </button>
      <button
        type="button"
        disabled={crmUserSalesRole !== BOTH_ROLE && activeButton !== 'retention-list'}
        className={`${styles.button} ${activeButton === 'retention-list' ? styles.active : ''}`}
        onClick={() => handleButtonClick('retention-list')}
      >
        <div className={styles.buttonContent}>
          {activeButton === 'retention-list' ? <CheckIcon sx={{ height: '20px' }} /> : null}
          Clients
        </div>
      </button>
    </div>
  );
}

export default SegmentButtons;
