import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { getLeadsReport } from '../../redux/leadsReport/leadsReportActions';

function getErrorRowNumber(data) {
  const line = data.trim();
  const columnRegex = /Column: (\d+)/;


  const match = line.match(columnRegex);

  return match && match[1] ? parseInt(match[1], 10) : '-';
}

const columns = [
  {
    name: 'Error Row',
    selector: (row) => getErrorRowNumber(row?.message),
    sortable: true,
  },
  {
    name: 'Email',
    selector: (row) => row?.email ?? '-',
    sortable: true,
  },
  {
    name: 'Phone',
    selector: (row) => row?.phone ?? '-',
    sortable: true,
  },
  {
    name: 'Message',
    cell: (row) => (
      <div className="d-flex gap-3">
        <span>{row?.message}</span>
      </div>
    ),
    sortable: true,
  },
];

export default function LeadsReport() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const leadsReport = useSelector((state) => state?.leadsReport?.leadsReportData);
  const totalItems = useSelector((state) => state?.leadsReport?.totalItems);

  useEffect(() => {
    dispatch(getLeadsReport(id, page, rowsPerPage));
  }, [id, page, rowsPerPage]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(1);
  };

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>Leads Upload Report</h3>
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
          <DataTable
            columns={columns}
            data={leadsReport?.invalidObject ?? []}
            highlightOnHover
            persistTableHead
            theme="solarizedd"
            pagination
            paginationServer
            paginationTotalRows={totalItems}
            paginationPerPage={rowsPerPage}
            paginationRowsPerPageOptions={[25, 50, 100, 500]}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
          />
        </div>
      </div>
    </div>
  );
}
