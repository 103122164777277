import React, {
  useEffect, 
  useMemo, 
  useState, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { MultiSelect } from 'react-multi-select-component';
import { editAdminBankAccount } from '../../../redux/psp/adminBankAccountActions';
import { updateState } from '../../../redux/adminAddress/adminAddressActions';


export function ModalEditAdminBankAccounts({
  id, row, hideModal, allIbDesks, countries, fiatCurrency,
}) {
  const dispatch = useDispatch();

  const adminBankAccountAdded = useSelector((state) => state?.adminBankAccount?.adminBankAccountAdded);
  const error = useSelector((state) => state?.adminBankAccount?.error);

  const ibDeskIds = useMemo(() => allIbDesks.map((ib) => (ib._id)), [allIbDesks]) ?? [];

  const [formData, setFormData] = useState({
    name: row?.name ?? '',
    iban: row?.iban ?? '',
    accountNumber: row?.accountNumber ?? '',
    bankAddress: row?.bankAddress ?? '',
    swiftCode: row?.swiftCode ?? '',
    currencyName: row?.currencyName ?? '',
    ibDeskId: row?.ibDeskId ?? [],
    alias: row?.alias ?? '',
    beneficiary: row?.beneficiary ?? '',
    beneficiaryAddress: row?.beneficiaryAddress ?? '',
    correspondentAccount: row?.correspondentAccount ?? '',
    inn: row?.inn ?? '',
    kpp: row?.kpp ?? '',
    reference: row?.reference ?? '',
    included_countries: row?.included_countries ?? [],
    excluded_countries: row?.excluded_countries ?? [],
  });
  const [includedCountries, setIncludedCountries] = useState([]);
  const [excludedCountries, setExcludedCountries] = useState([]);
  const [ibDeskSelect, setIbDeskSelect] = useState('');

  const countriesOptions = useMemo(() => countries.map(({ _id, nicename }) => ({ value: _id, label: nicename })), [countries]) ?? [];

  const { handleSubmit } = useForm();

  const handleUpdate = async () => {
    let data = { ...formData };
    if (formData.ibDeskId.length === 0) {
      data = { ...data, ibDeskId: JSON.stringify([]) };
    }

    await dispatch(editAdminBankAccount(id, data));
    hideModal();
  };

  useEffect(() => {
    if (row?.included_countries) {
      const included = row.included_countries.map((countryId) => {
        const match = countriesOptions.find((option) => option.value === countryId);
        return match;
      });
      setIncludedCountries(included);
    }

    if (row?.excluded_countries) {
      const excluded = row.excluded_countries.map((countryId) => {
        const match = countriesOptions.find((option) => option.value === countryId);
        return match; 
      });
      setExcludedCountries(excluded);
    }
  }, [row, countriesOptions]);

  useEffect(() => {
    if (row?.included_countries) {
      const included = row.included_countries.map((countryId) => {
        const match = countriesOptions.find((option) => option.value === countryId);
        return match;
      });
      setIncludedCountries(included);
    }
  }, [row, countriesOptions]);

  useEffect(() => {
    if (row?.ibDeskId && row.ibDeskId.length > 0) {
      if (row.ibDeskId.length === 1) {
        setIbDeskSelect(row.ibDeskId[0]); 
        setFormData((prevState) => ({ ...prevState, ibDeskId: JSON.stringify([row.ibDeskId[0]]) }));
      } else {
        setIbDeskSelect('All');
        setFormData((prevState) => ({ ...prevState, ibDeskId: JSON.stringify(row.ibDeskId) }));
      }
    }
  }, [row]);

  useEffect(() => {
    if (adminBankAccountAdded) {
      dispatch(updateState());
    }
  }, [adminBankAccountAdded]);

  useEffect(() => {
    if (error) {
      dispatch(updateState());
    }
  }, [error]);

  const countriesOnChangeHandler = (countryData, name) => {
    const idsArray = countryData.map(({ value }) => value);
    if (name === 'included_countries') {
      setIncludedCountries(countryData);
      setFormData((prev) => ({ ...prev, included_countries: JSON.stringify([...idsArray]) }));
    } else {
      setExcludedCountries(countryData);
      setFormData((prev) => ({ ...prev, excluded_countries: JSON.stringify([...idsArray]) }));
    }
  };

  const ibDeskSelectHandler = (value) => {
    const ibDeskId = value === 'All' ? [] : [value];
    setFormData((prevState) => ({ ...prevState, ibDeskId: JSON.stringify(ibDeskId) }));
    setIbDeskSelect(value);
  };
  
  return (
    <div>
      <form onSubmit={handleSubmit(handleUpdate)}>
        <div className="form-group col-md-12">
          <div className="select-row">
            <div className="form-group col-md-6 pt-2">
              <label className="control-label" htmlFor="currency">Currency</label>
              <select
                name="currency"
                value={formData.currencyName}
                className="form-control user-status-select"
                onChange={({ target: { value } }) => setFormData((prevState) => ({ ...prevState, currencyName: value }))}
              >
                {fiatCurrency.map(({ symbol, _id }) => <option key={_id} value={symbol}>{symbol}</option>)}
              </select>
            </div>
            <div className="form-group col-md-6 pt-2">
              <label className="control-label" htmlFor="ib">IB</label>
              <select
                name="ib"
                value={ibDeskSelect}
                className="form-control user-status-select"
                onChange={({ target: { value } }) => ibDeskSelectHandler(value)}
              >
                <option key="1" value={null}>All</option>
                {allIbDesks.map(({ firstName, lastName, _id }) => (
                  <option key={_id} value={_id}>
                    {`${firstName} ${lastName}`}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="select-row">
            <div className="form-group col-md-12 pt-2">
              <label className="control-label" htmlFor="alias">Alias</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Alias"
                name="alias"
                value={formData.alias}
                onChange={({ target: { value } }) => setFormData((prevState) => ({ ...prevState, alias: value }))}
              />
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Account Number</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Account Number"
                name="accountNumber"
                value={formData.accountNumber}
                onChange={({ target: { value } }) => setFormData((prevState) => ({ ...prevState, accountNumber: value }))}
              />
            </div>
          </div>
          <div className="select-row">
            <div className="form-group col-md-12 pt-2">
              <label className="control-label" htmlFor="bankName">Bank Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Bank Name"
                name="bankName"
                value={formData.name}
                onChange={({ target: { value } }) => setFormData((prevState) => ({ ...prevState, name: value }))}
              />
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label" htmlFor="iban">IBAN</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter IBAN"
                name="iban"
                value={formData.iban}
                onChange={({ target: { value } }) => setFormData((prevState) => ({ ...prevState, iban: value }))}
              />
            </div>
          </div>
          <div className="select-row">
            <div className="form-group col-md-12 pt-2">
              <label className="control-label" htmlFor="beneficiary">Beneficiary</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Beneficiary"
                name="beneficiary"
                value={formData.beneficiary}
                onChange={({ target: { value } }) => setFormData((prevState) => ({ ...prevState, beneficiary: value }))}
              />
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label" htmlFor="beneficiaryAddress">Beneficiary Address</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Beneficiary Address"
                name="beneficiaryAddress"
                value={formData.beneficiaryAddress}
                onChange={({ target: { value } }) => setFormData((prevState) => ({ ...prevState, beneficiaryAddress: value }))}
              />
            </div>
          </div>
          <div className="select-row">
            <div className="form-group col-md-12 pt-2">
              <label className="control-label" htmlFor="correspondentAccount">Correspondent Account</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Correspondent Account"
                name="correspondentAccount"
                value={formData.correspondentAccount}
                onChange={({ target: { value } }) => setFormData((prevState) => ({ ...prevState, correspondentAccount: value }))}
              />
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label" htmlFor="swiftCode">SWIFT</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter SWIFT"
                name="swiftCode"
                value={formData.swiftCode}
                onChange={({ target: { value } }) => setFormData((prevState) => ({ ...prevState, swiftCode: value }))}
              />
            </div>
          </div>
          <div className="select-row">
            <div className="form-group col-md-12 pt-2">
              <label className="control-label" htmlFor="inn">INN (RU)</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter INN"
                name="inn"
                value={formData.inn}
                onChange={({ target: { value } }) => setFormData((prevState) => ({ ...prevState, inn: value }))}
              />
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label" htmlFor="kpp">KPP (RU)</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter KPP"
                name="kpp"
                value={formData.kpp}
                onChange={({ target: { value } }) => setFormData((prevState) => ({ ...prevState, kpp: value }))}
              />
            </div>
          </div>
          <div className="select-row">
            <div className="form-group col-md-12 pt-2">
              <div className="form-group custom-milti-select"> 
                <label className="control-label" htmlFor="included_countries">Included Countries</label>
                <MultiSelect
                  name="included_countries"
                  options={countriesOptions}
                  value={includedCountries}
                  onChange={(value) => countriesOnChangeHandler(value, 'included_countries')}
                  labelledBy="Select"
                  includeSelectAllOption="false"
                />
              </div>  
            </div>
            <div className="form-group col-md-12 pt-2">
              <div className="form-group custom-milti-select"> 
                <label className="control-label" htmlFor="excluded_countries">Excluded Countries</label>
                <MultiSelect
                  name="excluded_countries"
                  options={countriesOptions}
                  value={excludedCountries}
                  onChange={(value) => countriesOnChangeHandler(value, 'excluded_countries')}
                  labelledBy="Select"
                  includeSelectAllOption="false"
                />
              </div>  
            </div>
          </div>
          <div className="select-row">
            <div className="form-group col-md-12 pt-2">
              <label className="control-label" htmlFor="reference">Reference</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Reference"
                name="reference"
                value={formData.reference}
                onChange={({ target: { value } }) => setFormData((prevState) => ({ ...prevState, reference: value }))}
              />
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label" htmlFor="bankAddress">Bank Address</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Bank Address"
                name="bankAddress"
                value={formData.bankAddress}
                onChange={({ target: { value } }) => setFormData((prevState) => ({ ...prevState, bankAddress: value }))}
              />
            </div>
          </div>
        </div>
        <div className="m-2 d-flex justify-content-end">
          <button className="btn btn-default" type="submit">Save</button>
        </div>
      </form>
    </div>
  );
}
