import {
  SET_KYC_AML_DATA,
  EDIT_KYC_AML_DATA,
  SET_KYC_AML_REQUESTS,
  GET_KYC_STATUSES,
  SET_TOTAL_COUNT,
  SET_USER_NAME_SEARCH_FILTER,
  SET_USER_EMAIL_SEARCH_FILTER,
  SET_USER_PHONE_SEARCH_FILTER,
  SET_VERIFY_STATUS_SEARCH_FILTER,
  SET_TIME_UPLOADED_FILTER,
  SET_IS_KYC_AML_DATA_LOADING,
  SET_IS_KYA_AML_REQUESTS_LOADING,
  DELETE_KYC_AML_REQUEST,
  DELETE_KYC_AML_DOCUMENT,
  UPDATE_KYC_AML_STATUS_OF_USER,
} from './kycAmlTypes';

const initialState = {
  kycAmlData: [],
  kycAmlRequests: [],
  kycStatuses: [],
  totalCount: 0,
  userNameSearchFilter: null,
  timeUploadedFilter: [],
  verifyStatusSearchFilter: '',
  isKycAmlDataLoading: false,
  isKycAmlRequestsLoading: false,
};

const updateRequests = (requests, payload, prevInfoValue) => (requests.length > 0
  ? requests.map((req) => {
    if (req._id === payload._id) {
      prevInfoValue.current = req.info;
      return payload;
    }
    return req;
  })
  : [payload]);
  
const updateTotalInfo = (totalInfo, payload, prevInfoValue) => (totalInfo.length > 0
  ? totalInfo.map((info) => {
    const prevValue = `${prevInfoValue.current}.`;
    if (info.trim() === prevValue.trim()) {
      return `${payload.info}.`;
    }
    return info;
  })
  : [payload.info]);

const kycAmlReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_KYC_AML_REQUEST:
      return {
        ...state,
        kycAmlData: state.kycAmlData.filter((item) => item._id !== action.payload),
      };
    case SET_IS_KYC_AML_DATA_LOADING:
      return {
        ...state,
        isKycAmlDataLoading: action.payload,
      };
    case SET_IS_KYA_AML_REQUESTS_LOADING:
      return {
        ...state,
        isKycAmlRequestsLoading: action.payload,
      };
    case SET_KYC_AML_DATA:
      return {
        ...state,
        kycAmlData: action.payload,
      };
    case EDIT_KYC_AML_DATA:
      const prevInfoValue = { current: '' };

        
      const kycAmlData = state.kycAmlData.map((el) => {
        if (el.kycRequestIds.includes(action.payload._id)) {
          el.requests = updateRequests(el.requests, action.payload, prevInfoValue);
          el.totalInfo = updateTotalInfo(el.totalInfo, action.payload, prevInfoValue);
        }
        return el;
      });

      return {
        ...state,
        kycAmlRequests: state.kycAmlRequests.map((item) => {
          if (item._id === action.payload._id) {
            return action.payload;
          }
          return item;
        }),
        kycAmlData,
      };
    case SET_TOTAL_COUNT:
      return {
        ...state,
        totalCount: action.payload,
      };
    case SET_USER_NAME_SEARCH_FILTER:
      return {
        ...state,
        userNameSearchFilter: action.payload,
      };
    case SET_USER_EMAIL_SEARCH_FILTER:
      return {
        ...state,
        userEmailSearchFilter: action.payload,
      };
    case SET_USER_PHONE_SEARCH_FILTER:
      return {
        ...state,
        userPhoneSearchFilter: action.payload,
      };
    case SET_TIME_UPLOADED_FILTER:
      return {
        ...state,
        timeUploadedFilter: action.payload,
      };
    case SET_VERIFY_STATUS_SEARCH_FILTER:
      return {
        ...state,
        verifyStatusSearchFilter: action.payload,
      };
    case SET_KYC_AML_REQUESTS:
      return {
        ...state,
        kycAmlRequests: action.payload,
      };
    case GET_KYC_STATUSES:
      return {
        ...state,
        kycStatuses: action.payload,
      }; 
    case DELETE_KYC_AML_DOCUMENT:
      return {
        ...state,
        kycAmlData: state.kycAmlData
          .map((el) => {
            if (el.userId === action.payload.userId) {
              const updatedKycRequestIds = el.kycRequestIds.filter((id) => id !== action.payload.docsId);

              if (updatedKycRequestIds.length === 0) return null;

              return { ...el, kycRequestIds: updatedKycRequestIds };
            }

            return el;
          })
          .filter((el) => el !== null),
        kycAmlRequests: state.kycAmlRequests.filter((el) => el._id !== action.payload.docsId),
      };
    case UPDATE_KYC_AML_STATUS_OF_USER:
      return {
        ...state,
        kycAmlData: state.kycAmlData
          .map((el) => {
            if (el.userId === action.payload.userId) {  
              return { ...el, verifiedStatus: action.payload.userKycVerifyStatus };
            }
  
            return el;
          }),
      };
    default:
      return {
        ...state,
      };
  }
};

export default kycAmlReducer;
