import React, {
  useEffect, useState, useContext, useRef, 
} from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getCfdSettingsByQuery, exportFilteredDataToFile } from '../../redux/cfdSettings/cfdActions';
import { ModalContext } from '../../context';
import { CfdSettingsSchema } from '../../DataTableSchemas/CfdSettingsSchema';
import { CfdSettingsModal, MassEditCFDSettingsModal } from './modals';
import { getCfdMarketTypes } from '../../redux/cfdMarketTypes/cfdMarketTypesActions';
import { generateSortParams } from '../../helpers/sortHelper';
import { useDebounce } from '../../hooks/useDebounce';
import { DatatableFilters, DatatableColumns, DownloadFile } from '../../components';
import { createCRMFilter, deleteCRMFilter, updateCRMFilter } from '../../redux/crmUser/crmUserActions';
import { cfdSettingColumns } from '../../columnsDefaultConfig';
import { setNewOrderedColumns } from '../../DataTableSchemas/helper';

export function CfdSettings() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { showModal, hideModal } = useContext(ModalContext);

  const [sortParams, setSortParams] = useState(JSON.stringify({ label: 1 }));
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filters, setFilters] = useState({});
  const [tableLoading, setTableLoading] = useState(false);
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [selectedSettingsRows, setSelectedSettingsRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [searchByAlias, setSearchByAlias] = useState(null);
  const [marketTypesFilter, setMarketTypesFilter] = useState(null);  
  const [columnConfig, setColumnConfig] = useState(cfdSettingColumns);

  const { cfdSettings, totalCount } = useSelector((state) => state.cfdSettings);
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);

  const filtersStorageName = 'DataTable_cfd-settings/filters';
  const filtersStorageJSON = localStorage.getItem(filtersStorageName);
  const paginationStorageName = 'DataTable_cfd-settings/pagination';
  const paginationStorageJSON = localStorage.getItem(paginationStorageName);
  const columnsStorageName = 'DataTable_cfd-settings/columns';
  const columnsStorageJSON = localStorage.getItem(columnsStorageName);
  const filterIdStorageName = 'DataTable_cfd-settings/filters_id'; 

  const columnsJSON = localStorage.getItem(columnsStorageName);

  const datatableFiltersRef = useRef(null);

  const debounceCallback = ({ value, key }) => {
    if (value !== null && (value.length >= 0) && filters[key] !== value) {
      setFilters((prev) => ({ ...prev, [key]: value }));
    }
  };

  const debounceArrayCallback = ({ value, key }) => {
    if (value !== null && (value.length >= 0 || Array.isArray(value)) && filters[key] !== value) {
      setFilters((prev) => ({ ...prev, [key]: value }));
    }
  };

  useDebounce(searchByAlias, 1000, (value) => debounceCallback({ value, key: 'symbol' }));
  useDebounce(marketTypesFilter, 200, (value) => debounceArrayCallback({ value, key: 'types' }));

  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const setStoredFilterData = () => {
    const filters = JSON.parse(filtersStorageJSON) ?? {};
    setFilters(filters);

    setSearchByAlias(filters.symbol || '');
    setMarketTypesFilter(filters.types || []);
  };

  const setStoredPagination = () => {
    if (paginationStorageJSON) {
      const filterRows = JSON.parse(paginationStorageJSON);
      setRowsPerPage(filterRows.limit || 25);
      setPage(filterRows.page || 1);
    }

    setIsPaginationDT(true);
  };

  const setStoredColumnsData = () => {
    if (columnsJSON) {
      const columns = JSON.parse(columnsJSON);
  
      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  const getAllStoredData = () => {
    setStoredColumnsData();
    setStoredFilterData();
    setStoredPagination();
  };

  useEffect(async () => {
    getAllStoredData();
    dispatch(getCfdMarketTypes());
  }, []);

  useEffect(async () => {
    if (!isPaginationDT) return;

    localStorage.setItem(filtersStorageName, JSON.stringify(filters));
    setTableLoading(true);

    await dispatch(getCfdSettingsByQuery({
      page, 
      limit: rowsPerPage, 
      sort: sortParams, 
      query: filters,
    }));

    setTableLoading(false);
  }, [page, rowsPerPage, sortParams, filters, isPaginationDT]);

  const handleSort = async (sortField, sortDirection) => {
    const sort = generateSortParams(sortField, sortDirection);
    setSortParams(sort);
  };

  const handleSettingsTableSelect = async ({ selectedRows }) => {
    setSelectedSettingsRows(selectedRows);
  };

  const handlePageChange = (page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: rowsPerPage, page }));
    setPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage, page }));
    setRowsPerPage(currentRowsPerPage);
  };

  const clearRows = () => {
    setToggleCleared((prev) => (!prev));
    setSelectedSettingsRows([]);
  };

  const handleClear = () => {
    setSearchByAlias('');
    setMarketTypesFilter([]);
    localStorage.removeItem(filtersStorageName);
    localStorage.removeItem(filterIdStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  const handleRefresh = async () => {
    setTableLoading(true);
    await dispatch(getCfdSettingsByQuery({
      page, 
      limit: rowsPerPage, 
      sort: sortParams, 
      query: filters,
    }));
    setTableLoading(false);
  };

  const setCRMFilters = (filter) => {
    const {
      fullName,
      type,
    } = filter;

    setSearchByAlias(fullName || null);
    setMarketTypesFilter(type || []);
    setFilters({
      symbol: fullName,
      types: type,
    });
    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);
    
    const data = {
      name,
      crmUserId,
      pathname,
      type: filters.types || [],
      fullName: filters.symbol || '',
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        type: filters.types || [],
        fullName: filters.symbol || '',
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const storeColumnConfig = (config) => {
    setColumnConfig(config);
    localStorage.setItem(columnsStorageName, JSON.stringify(config));
  };

  const handleEditMassSettingTable = () => {
    showModal({
      headerContent: <h3>Mass Edit Settings</h3>,
      bodyContent: <MassEditCFDSettingsModal settings={selectedSettingsRows} hideModal={hideModal} clearRows={clearRows} />,
    });
  };

  const handleExportStatuses = async (fileType) => {
    const columns = columnConfig.filter((obj) => obj.selected && obj.field);
    if (cfdSettings.length && columns.length) {
      toast.success('Data export in progress. Please wait while we prepare the file.', {
        autoClose: 2000,
      });
      exportFilteredDataToFile(filters, columns, fileType); 
    } else {
      toastError('There is nothing to download.');
    }
  };

  const columns = CfdSettingsSchema({
    columnConfig,
    showModal, 
    CfdSettingsModal, 
    handleSort,
    searchByAlias, 
    setSearchByAlias,
    marketTypesFilter, 
    setMarketTypesFilter,
  });

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3 className="mb-3 text-capilatize">CFD Settings</h3>
        <div className="action__btn-row">
          <div className="main_btn-row">
            <div className="secondary_btn-row">
              <DatatableFilters
                ref={datatableFiltersRef}
                filters={crmFilters}
                setFilters={setCRMFilters}
                createFilter={createUserCRMFilter}
                deleteFilter={deleteUserCRMFilter}
                updateFilter={updateUserCRMFilter}
                storageKey={filterIdStorageName}
                pathname={pathname}
              />
              <button type="button" className="btn-secondary_dark iconed" onClick={handleClear}>
                <CloseOutlinedIcon sx={{ fontSize: '20px' }} />
              </button>
              <button type="button" className="btn-secondary_dark iconed" onClick={handleRefresh}>
                <RefreshIcon sx={{ fontSize: '20px' }} />
              </button>
            </div>
            <div className="secondary_btn-row">
              <DatatableColumns setColumns={storeColumnConfig} columnConfig={columnConfig} defaultConfig={cfdSettingColumns} />
            </div>
            <div className="secondary_btn-row">
              <DownloadFile handleExport={handleExportStatuses} />
            </div>
          </div>
          {
            selectedSettingsRows.length > 0 && (
              <div className="dt-actions__container">
                <div className="dt-actions__selected-counter">{`Actions: selected ${selectedSettingsRows.length} ${selectedSettingsRows.length > 1 ? 'records' : 'record'}`}</div>
                <div className="main_actions-row">
                  <button 
                    disabled={!selectedSettingsRows.length}
                    type="button"
                    onClick={handleEditMassSettingTable}
                    className="secondary-btn"
                  >
                    Mass Edit Settings
                  </button>
                </div>
              </div>
            )
          }
        </div>
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper staking-tbl mt-3">
          <div className="table-responsive">
            {
              isPaginationDT && (
                <DataTable
                  data={cfdSettings}
                  columns={columns}
                  pagination
                  paginationRowsPerPageOptions={[25, 50, 200, 500]}
                  paginationServer
                  paginationPerPage={rowsPerPage}
                  paginationDefaultPage={page}
                  paginationTotalRows={totalCount}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  onSelectedRowsChange={handleSettingsTableSelect}
                  onColumnOrderChange={(newColumns) => setNewOrderedColumns(
                    newColumns,
                    columnConfig,
                    storeColumnConfig,
                  )}
                  selectableRows
                  persistTableHead
                  theme="solarizedd"
                  progressPending={tableLoading}
                  clearSelectedRows={toggleCleared}
                  progressComponent={<div className="datatable-loader__background" />}
                />
              )
            }
          </div>
        </div>
      </div>
    </div>
          
  );
}
