import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { EditWhitelistIPModal } from './EditWhitelistIPModal';
import { deleteWhitelistIP } from '../../../redux/security/whitelistIPActions';
import { ModalContext } from '../../../context';
import { SortWrapper, useTableSorting } from '../../../helpers/sortHelper';
import { omitColumn, formatDate, sortColumnByOrderConfig } from '../../../DataTableSchemas/helper';
import SortIcon from '../../../components/SortIcon';

export const WhiteListIPSchema = ({
  columnConfig,
  nameFilter,
  setNameFilter,
  crmUserNameFilter,
  setCrmUserNameFilter,
  ipFilter,
  setIpFilter,
  handleSort,
}) => {
  const dispatch = useDispatch();
  const { showModal } = useContext(ModalContext);
  const { sortFields, getSortIcon } = useTableSorting(handleSort);

  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const isUserCanEditIP = permissionName && !!permissionName.length && permissionName.includes('edit_whitelist_ip');
  const isUserCanDeleteIP = permissionName && !!permissionName.length && permissionName.includes('delete_whitelist_ip');

  const deleteAction = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed === true) {
        dispatch(deleteWhitelistIP(id));
      }
    });
  };

  const columns = [
    {
      name: (
        <div className="d-flex align-items-start flex-column search-input-des pt-2">
          <SortWrapper handleSort={() => sortFields('name')}>
            <div className="d-flex flex-row">
              <span>Name</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('name')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            name="Name"
            autoComplete="off"
            value={nameFilter}
            onChange={({ target: { value } }) => setNameFilter(value)}
          />
        </div>
      ),
      label: 'Name',
      cell: ({ name }) => name,
      omit: omitColumn(columnConfig, 'Name'),
      reorder: true,
    },
    {
      name: (
        <div className="d-flex align-items-start flex-column search-input-des pt-2">
          <SortWrapper handleSort={() => sortFields('managerName')}>
            <div className="d-flex flex-row">
              <span>CRM User</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('managerName')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            name="CRMUser"
            autoComplete="off"
            value={crmUserNameFilter}
            onChange={({ target: { value } }) => setCrmUserNameFilter(value)}
          />
        </div>
      ),
      label: 'CRM User',
      cell: ({ managerName }) => managerName,
      omit: omitColumn(columnConfig, 'CRM User'),
      reorder: true,
    },
    {
      name: (
        <div className="d-flex align-items-start flex-column search-input-des pt-2">
          <SortWrapper handleSort={() => sortFields('ip')}>
            <div className="d-flex flex-row">
              <span>IP Address</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('ip')} />
              </button>
            </div>
          </SortWrapper>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            name="IPAddress"
            autoComplete="off"
            value={ipFilter}
            onChange={({ target: { value } }) => setIpFilter(value)}
          />
        </div>
      ),
      label: 'IP Address',
      cell: ({ ip }) => ip,
      omit: omitColumn(columnConfig, 'IP Address'),
      reorder: true,
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('createdAt')}>
          <div className="d-flex flex-row">
            <span>Created</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('createdAt')} />
            </button>
          </div>
        </SortWrapper>
      ),
      label: 'Created',
      cell: ({ createdAt }) => formatDate(new Date(createdAt)),
      omit: omitColumn(columnConfig, 'Created'),
      reorder: true,
    },
  ];

  if (isUserCanEditIP || isUserCanDeleteIP) {
    columns.push({
      name: 'Actions',
      label: 'Actions',
      reorder: true,
      cell: (record) => (
        <div className="action-buttons">
          {isUserCanEditIP
            && (
            <button 
              type="button"
              className="btn btn-success btn-sm me-1 p-1"
              onClick={() => showModal({ 
                bodyContent: <EditWhitelistIPModal whitelistIP={record} />, 
                headerContent: <h5>Edit IP To Whitelist</h5>, 
              })}
            >
              <FontAwesomeIcon icon={faPencil} className="header-icon text-white" />
            </button>
            )}
          {isUserCanDeleteIP
            && (
            <button 
              type="button"
              className="btn btn-danger btn-sm me-1 p-1"
              onClick={() => deleteAction(record._id)}
            >
              <FontAwesomeIcon icon={faTrash} className="header-icon text-white" />
            </button>
            )}
        </div>
      ),
      omit: omitColumn(columnConfig, 'Actions'),
    });
  }

  return sortColumnByOrderConfig(columns, columnConfig);
};
