import { toast } from 'react-toastify';
import {
  GET_AFFILIATES,
  GET_AFFILIATE,
  ADD_AFFILIATE,
  EDIT_AFFILIATE,
  DELETE_AFFILIATE,
  GET_AFFILIATED_DEPOSITS,
  UPDATE_AFFILIATE_TOKEN,
  TOGGLE_AFFILIATE_STATE,
  ERROR_AFFILIATE_STATE,
  GET_AFFILIATES_TABLE_DATA,
} from './affiliateTypes';
import { apiHelper } from '../apiHelper';

export const exportFilteredDataToFile = async (query, columns, fileType) => {
  try {
    const res = await apiHelper('post', '/api/affiliate/export', {
      query, columns, fileType,
    });

    if (res.data && res.data.success) {
      window.open(res.data.fileUrl);
    }

    return res.data;
  } catch (error) {
    toast.error(error.response.message);

    return error;
  }
};

export const getAffiliatesTableData = ({
  limit,
  page, 
  sort = '{ "createdAt": -1 }', 
  query = {}, 
}) => async (dispatch) => {
  try {
    let url = `?page=${page}&limit=${limit}&sort=${sort}`;

    Object.entries(query).forEach(([key, value]) => {
      url += `&${key}=${value}`;
    });

    const res = await apiHelper('get', `/api/affiliate/table${url}`, '');

    if (res?.data?.success) {
      await dispatch({
        type: GET_AFFILIATES_TABLE_DATA,
        payload: res.data,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const getAffiliates = ({ sortParams } = { sortParams: '{ "customId": -1 }' }) => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/affiliate', '', {
      sort: sortParams,
    });
    if (res?.data && res?.data?.affiliates) {
      const { data } = res;
      await dispatch({
        type: GET_AFFILIATES,
        payload: data?.affiliates,
      });
    } else {
      await dispatch({
        type: GET_AFFILIATES,
        payload: [],
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const getAffiliate = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/affiliate/${id}`, '');
    if (res?.data && res?.data?.affiliate) {
      const { data } = res;

      await dispatch({
        type: GET_AFFILIATE,
        payload: data?.affiliate,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const addAffiliate = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/affiliate/add', data);

    if (res?.data && res?.data?.newAffiliateRecord) {
      const { data } = res;
      toast.success(data?.message);
      await dispatch({
        type: ADD_AFFILIATE,
        payload: data?.newAffiliateRecord,
      });
    } else {
      await dispatch({
        type: ERROR_AFFILIATE_STATE,
      });
    }
  } catch (error) {
    // console.log(error.message);
    toast.error(error.message);
  }
};

export const editAffiliate = (id, data) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/affiliate/${id}`, data);
    if (res?.data && res?.data?.affiliate) {
      const { data } = res;
      toast.success(data?.message);
      await dispatch({
        type: EDIT_AFFILIATE,
        payload: data?.affiliate,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const deleteAffiliate = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/affiliate/${id}`, '');
    if (res?.data && res?.data?.affiliate) {
      const { data } = res;
      toast.success(data?.message);
      await dispatch({
        type: DELETE_AFFILIATE,
        payload: data?.affiliate?._id,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const displayAffiliateDeposits = (roleId, userId, affiliateId, sortParams = '{"createdAt": -1}') => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/user/affiliated-deposits', {
      role_id: roleId, user_id: userId, affiliateId, sortParams, 
    });

    if (res?.data && res?.data?.deposits) {
      const { data } = res;

      await dispatch({
        type: GET_AFFILIATED_DEPOSITS,
        payload: data?.deposits,
      });
    } else {
      await dispatch({
        type: GET_AFFILIATED_DEPOSITS,
        payload: [],
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const updateAffiliateToken = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/affiliate/update-token/${id}`, '');
    if (res?.data) {
      const { data } = res;
      toast.success(res.data.message);
      if (data?.token) {
        dispatch({
          type: UPDATE_AFFILIATE_TOKEN,
          payload: data?.token,
        });
      }
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const updateAffiliateState = () => async (dispatch) => {
  try {
    await dispatch({
      type: TOGGLE_AFFILIATE_STATE,
    });
  } catch (error) {
    // console.log(error.response.message);
  }
};
