import React from 'react';
import EditUser from './UserDetailComponents/EditUser';
import Accounts from './UserDetailComponents/Accounts/Accounts';
import Deposit from './UserDetailComponents/Deposit';
import WithdrawalComponent from './UserDetailComponents/WithdrawalComponent';
import ActiveOrder from './UserDetailComponents/ActiveOrder';
import TradeHistory from './UserDetailComponents/TradeHistory';
import ClientLoginActivity from '../Activity/ClientLoginActivity';
import ClientActivityTable from '../Activity/ClientActivityTable';
import { SavingInvestmentsDatatable } from '../Saving/SavingInvestmentsDatatable';
import { TradingSettings } from './UserDetailComponents/TradingSettings';
import UserDetailsKycAmlRequests from '../KycAml/UserDetailsKycAmlRequests/UserDetailsKycAmlRequests';
import PSP from './UserDetailComponents/PSP/PSP';
import { MonthlyInactivityFee } from './UserDetailComponents/MonthlyInactivityFee/MonthlyInactivityFee';
import { UserWithdrawalMethods } from './UserDetailComponents/UserWithdrawalMethods/UserWithdrawalMethods';

function UserTabs({
  activeTab, setTabWithHistory, user, permissionName,
}) {
  const isUserCanSeeTradeSettings = permissionName?.includes('trade_management');
  const isUserCanSeeKyc = permissionName?.includes('kyc_aml');
  const isUserCanSeeSaving = permissionName?.includes('saving_management');
  const isUserCanSeeAccounts = permissionName?.includes('cfd_accounts');
  const isUserCanSeeDeposits = permissionName?.includes('deposits');
  const isUserCanSeeWithdrawals = permissionName?.includes('withdrawals');
  const isUserCanSeeActivity = permissionName?.includes('clients_report');
  const isUserCanSeeOpenOrders = permissionName?.includes('open_orders');
  const isUserCanSeeOrdersHistory = permissionName?.includes('orders_history');
  const isUserCanSeePSP = permissionName?.includes('user_psp');

  return (
    <div style={{ color: 'white', width: '100%' }}>
      <div className="user-details-tabs">
        <div className={activeTab === 'edit' ? 'tab-active' : 'tab'} onClick={() => setTabWithHistory('edit')}>Personal</div>
        {isUserCanSeeAccounts && <div className={activeTab === 'accounts' ? 'tab-active' : 'tab'} onClick={() => setTabWithHistory('accounts')}>Accounts</div>}
        {isUserCanSeeDeposits && <div className={activeTab === 'deposits' ? 'tab-active' : 'tab'} onClick={() => setTabWithHistory('deposits')}>Deposits</div>}
        {isUserCanSeeWithdrawals && <div className={activeTab === 'withdrawals' ? 'tab-active' : 'tab'} onClick={() => setTabWithHistory('withdrawals')}>Withdrawals</div>}
        {isUserCanSeeOpenOrders && <div className={activeTab === 'active-orders' ? 'tab-active' : 'tab'} onClick={() => setTabWithHistory('active-orders')}>Open Orders</div>}
        {isUserCanSeeOrdersHistory && <div className={activeTab === 'trade-history' ? 'tab-active' : 'tab'} onClick={() => setTabWithHistory('trade-history')}>Orders History</div>}
        {isUserCanSeeActivity && <div className={activeTab === 'user-login' ? 'tab-active' : 'tab'} onClick={() => setTabWithHistory('user-login')}>Login Log</div>}
        {isUserCanSeeActivity && <div className={activeTab === 'user-activity' ? 'tab-active' : 'tab'} onClick={() => setTabWithHistory('user-activity')}>Activity</div>}
        {isUserCanSeeSaving && <div className={activeTab === 'saving-history' ? 'tab-active' : 'tab'} onClick={() => setTabWithHistory('saving-history')}>Saving History</div>}
        {isUserCanSeeTradeSettings && <div className={activeTab === 'trading-settings' ? 'tab-active' : 'tab'} onClick={() => setTabWithHistory('trading-settings')}>Trading Settings</div>}
        {isUserCanSeeKyc && <div className={activeTab === 'kyc-aml' ? 'tab-active' : 'tab'} onClick={() => setTabWithHistory('kyc-aml')}>Kyc/Aml</div>}
        {isUserCanSeePSP && <div className={activeTab === 'psp' ? 'tab-active' : 'tab'} onClick={() => setTabWithHistory('psp')}>PSP</div>}
        <div className={activeTab === 'monthlyInactivityFee' ? 'tab-active' : 'tab'} onClick={() => setTabWithHistory('monthlyInactivityFee')}>Monthly Inactivity Fee</div>
        <div className={activeTab === 'withdrawalMethod' ? 'tab-active' : 'tab'} onClick={() => setTabWithHistory('withdrawalMethod')}>Withdrawals Method</div>
      </div>
      <div>
        <br />
        {(activeTab === 'edit' && <EditUser />)
                    || (activeTab === 'accounts' && isUserCanSeeAccounts && <Accounts userId={user?._id} />)
                    || (activeTab === 'deposits' && isUserCanSeeDeposits && <Deposit />)
                    || (activeTab === 'withdrawals' && isUserCanSeeWithdrawals && <WithdrawalComponent />)
                    || (activeTab === 'active-orders' && isUserCanSeeOpenOrders && <ActiveOrder />)
                    || (activeTab === 'trade-history' && isUserCanSeeOrdersHistory && <TradeHistory />)
                    || (activeTab === 'user-login' && isUserCanSeeActivity && <ClientLoginActivity userId={user?._id} isSeparatePage isPositionUnset />)
                    || (activeTab === 'user-activity' && isUserCanSeeActivity && <ClientActivityTable userId={user?._id} isSeparatePage isPositionUnset />)
                    || (activeTab === 'saving-history' && isUserCanSeeSaving && <SavingInvestmentsDatatable userId={user?._id} />)
                    || (activeTab === 'trading-settings' && isUserCanSeeTradeSettings && <TradingSettings />)
                    || (activeTab === 'kyc-aml' && isUserCanSeeKyc && <UserDetailsKycAmlRequests userId={user?._id} />)
                    || (activeTab === 'psp' && isUserCanSeePSP && <PSP />)
                    || (activeTab === 'monthlyInactivityFee' && <MonthlyInactivityFee />)
                    || (activeTab === 'withdrawalMethod' && <UserWithdrawalMethods />)}
      </div>
    </div>
  );
}

export default UserTabs;
