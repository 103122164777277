import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Form, FormGroup, Modal, Button, Row, Col,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinner, faPlus,
} from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import {
  removeCurrencyAmountFromUserAccount,
} from '../../../redux/users/userActions';
import { getCurrencyRates } from '../../../redux/currencyRate/currencyRateActions';
import { showAllCurrencies } from '../../../redux/currency/currencyActions';
import { getAccountOptionsByUserId } from '../../../redux/cfdAccount/cfdAccountActions';
import PendingWithdraws from '../../PendingWithdraws/PendingWithdraws';

function WithdrawalComponent() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const tableRef = useRef(null);

  const [withdrawalCurrency, setWithdrawalCurrency] = useState('');

  const [removeAmountInfo, setRemoveAmountInfo] = useState('');
  const [comment, setComment] = useState('');
  const [removeAmountInfoErr] = useState('');
  const [commentErr] = useState('');
  const [isReal, setIsReal] = useState('1');
  const [isRealErr, setIsRealErr] = useState('');
  const [isResolved, setIsResolved] = useState(1);
  const [isResolvedErr, setIsResolvedErr] = useState('');
  const [balanceType, setBalanceType] = useState(0);
  const [balanceTypeErr, setBalanceTypeErr] = useState('');
  const [apiCall, setApiCall] = useState(false);
  const [removeCurrencyAccountSelectedErr, setRemoveCurrencyAccountSelectedErr] = useState('');
  const [accountErr, setAccountErr] = useState('');
  const [selectedAccount, setSelectedAccount] = useState('');

  const currencies = useSelector((state) => state.currency?.currencies?.allCurrencies);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);

  const accountOptions = useSelector((state) => state.cfdAccounts.options);
  const isUserCanWithdrawalManual = permissionName && permissionName.includes('withdrawal_manual');

  const refetchTableData = useCallback(() => {
    if (tableRef.current) {
      tableRef.current.refetchTable();
    }
  }, [tableRef]);


  useEffect(() => {
    if (currencies && currencies.length) {
      const currency = currencies.find((currency) => currency.symbol === 'USD');

      setWithdrawalCurrency({ value: currency._id, label: `${currency.name}  (${currency.symbol})` });
    }
  }, [currencies]);

  useEffect(() => {
    const usdAccount = accountOptions.find((account) => account.label.includes('USD')) || accountOptions[0];
    setSelectedAccount(usdAccount?.value);
  }, [accountOptions]);

  const {
    register: register2, handleSubmit: handleSubmit2, control: control2, reset: reset2, formState: { errors: errors2 },
  } = useForm();

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setRemoveAmountInfo('');
    setComment('');
    setIsRealErr('');
    setIsResolvedErr('');
    setBalanceTypeErr('');
    setRemoveCurrencyAccountSelectedErr('');
    setAccountErr('');
    setIsReal('1');
    setIsResolved(1);
    setBalanceType(0);
    reset2();
    setApiCall(false);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    Promise.allSettled([
      dispatch(showAllCurrencies()),
      dispatch(getCurrencyRates('USD')),
    ]);
  }, []);

  useEffect(async () => {
    await dispatch(getAccountOptionsByUserId(id));
  }, [id]);

  const removeCurrencyAmount = {
    amountForRemoveCurrency: {
      required: 'Amount is required',
      pattern: {
        value: /^[0-9]\d*(\.\d+)?$/,
        message: 'Only numbers and decimals are allowed',
      },
    },
  };

  const handleCurrencyAmountRemove = async (formData) => {
    let errorsOccured = false;

    if (!withdrawalCurrency.value) {
      setRemoveCurrencyAccountSelectedErr('Select currency of transaction');
      errorsOccured = true;
    }

    if (balanceType === 3) {
      setBalanceTypeErr('Select type of transaction');
      errorsOccured = true;
    }

    if (isResolved === 3) {
      setIsResolvedErr('Select status of transaction');
      errorsOccured = true;
    }

    if (isReal === 3) {
      setIsRealErr('Select if transaction is real or fake');
      errorsOccured = true;
    }

    if (!selectedAccount || !selectedAccount.length) {
      setAccountErr('Select account');
      errorsOccured = true;
    }

    if (!errorsOccured) {
      setApiCall(true);
      const data = {
        userId: id,
        currencyId: withdrawalCurrency.value,
        amount: formData.amountForRemoveCurrency,
        additionalInfo: removeAmountInfo,
        comment,
        isReal,
        isResolved,
        selectedAccount,
        balanceType: Number(balanceType) === 0,
        addedBy: localStorage.getItem('userId').slice(1, -1),
      };

      await dispatch(removeCurrencyAmountFromUserAccount(data)).then(refetchTableData);
      await dispatch(getAccountOptionsByUserId(id));
      handleClose();
    }
  };

  const handleWithdrawalCurrencyChange = (withdrawalCurrencyChange) => {
    setWithdrawalCurrency(withdrawalCurrencyChange);
  };

  const colourStyles = {
    control: (styles, { isDisabled, isSelected }) => ({
      ...styles,
      background: isDisabled ? '#aaa' : '#374057',
      color: isDisabled ? '#aaa' : 'fff',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      border: '1px solid #374057',
      boxShadow: isSelected ? 'none' : 'none',
      borderColor: isSelected ? '#374057' : '#374057',
      '&:hover': {
        boxShadow: 'none',
      },
    }),
    input: (styles) => ({
      ...styles,
      color: '#fff',
    }),
    singleValue: (styles) => ({
      ...styles,
      color: '#fff',
    }),
    menuList: (styles) => ({
      ...styles,
      background: '#374057',
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? '#16202e'
        : isSelected
          ? '#16202e'
          : undefined,
      color: 'fff',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      zIndex: 1,
      '&:hover': {
        background: isDisabled ? '#aaa' : '#16202e',
      },
    }),
  };

  return (
    <div>
      <div>
        <FormGroup role="form">
          <Modal show={show} onHide={handleClose} className="widthdrawal-modal">
            <Modal.Header closeButton>
              <Modal.Title className="text-white">ADD WITHDRAWAL</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit2(handleCurrencyAmountRemove)} className="account-balance-currency me-0">
              <Modal.Body>
                <div className="withdrawal-data">
                  <Row>
                    <Col md={6}>
                      <div className="form-group me-2">
                        <label className="control-label mb-2">Amount</label>
                        <div className="input-wrapper">
                          <input type="text" className="form-control" {...register2('amountForRemoveCurrency', removeCurrencyAmount?.amountForRemoveCurrency)} name="amountForRemoveCurrency" defaultValue="" control={control2} />
                          {errors2?.amountForRemoveCurrency && <span className="errMsg">{errors2?.amountForRemoveCurrency?.message}</span>}
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group me-2">
                        <label className="control-label mb-2">Currency</label>
                        <div className="input-wrapper">
                          <Select
                            styles={colourStyles}
                            name="removeCurrencyAccountSelected"
                            value={withdrawalCurrency}
                            options={currencies && currencies.length > 0 && currencies.map((currency) => ({
                              value: currency._id,
                              label: `${currency?.name}  (${currency?.symbol})`,
                            }))}
                            isSearchable
                            onChange={handleWithdrawalCurrencyChange}
                          />
                          {removeCurrencyAccountSelectedErr ? (<span className="errMsg">{removeCurrencyAccountSelectedErr}</span>) : ('')}
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group me-2">
                        <label className="control-label mb-2">Account</label>
                        <div className="input-wrapper">
                          <Form.Select name="account" value={selectedAccount} onChange={({ target: { value } }) => setSelectedAccount(value)}>
                            <option value="">Select Account</option>
                            {
                                  accountOptions.map(({ value, label }) => (
                                    <option key={value} value={value}>{label}</option>
                                  ))
                                }
                          </Form.Select>
                          {accountErr ? (<span className="errMsg">{accountErr}</span>) : ('')}
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group me-2">
                        <label className="control-label mb-2">Type</label>
                        <div className="input-wrapper">
                          <Form.Select name="balanceType" value={balanceType} onChange={(event) => setBalanceType(event.target.value)}>
                            <option value="3">Select Type</option>
                            <option value="0">Balance</option>
                            {/* <option value="1">Credit</option> */}
                          </Form.Select>
                          {balanceTypeErr ? (<span className="errMsg">{balanceTypeErr}</span>) : ('')}
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group me-2">
                        <label className="control-label mb-2">Status</label>
                        <div className="input-wrapper">
                          <Form.Select name="isResolved" value={isResolved} onChange={(event) => setIsResolved(event.target.value)}>
                            <option value="3">Select Type</option>
                            <option value="0">Pending</option>
                            <option value="1">Approved</option>
                            <option value="2">Declined</option>
                          </Form.Select>
                          {isResolvedErr ? (<span className="errMsg">{isResolvedErr}</span>) : ('')}
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group me-2">
                        <label className="control-label mb-2">Real</label>
                        <div className="input-wrapper">
                          <Form.Select name="isReal" value={isReal} onChange={(event) => setIsReal(event.target.value)}>
                            <option value="3">Select Transactions </option>
                            <option value="1">Real</option>
                            <option value="0">Fake</option>
                          </Form.Select>
                          {isRealErr ? (<span className="errMsg">{isRealErr}</span>) : ('')}
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group me-2">
                        <label className="control-label mb-2">Additional Info</label>
                        <div className="input-wrapper">
                          <input type="text" className="form-control" name="removeAmountInfo" value={removeAmountInfo} onChange={(event) => setRemoveAmountInfo(event.target.value)} />
                          {removeAmountInfoErr ? (<span className="errMsg">{removeAmountInfoErr}</span>) : ('')}
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group me-2">
                        <label className="control-label mb-2">Comment</label>
                        <div className="input-wrapper">
                          <input type="text" className="form-control" name="comment" value={comment} onChange={(event) => setComment(event.target.value)} />
                          {commentErr ? (<span className="errMsg">{commentErr}</span>) : ('')}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
                {apiCall
                  ? (
                    <Button variant="primary" style={{ padding: '8px 40px' }}>
                      <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                    </Button>
                  )
                  : (
                    <Button variant="primary" type="submit">
                      Save Changes
                    </Button>
                  )}
              </Modal.Footer>
            </Form>
          </Modal>
        </FormGroup>
        <PendingWithdraws
          ref={tableRef}
          userIdFromDetail={id}
          disableHeader
          secondaryButtonRow={isUserCanWithdrawalManual && (
          <button type="button" className="btn-primary_light" onClick={handleShow}>
            <FontAwesomeIcon icon={faPlus} size="sm" />
            Add New
          </button>
          )}
        />
      </div>
    </div>
  );
}

export default WithdrawalComponent;
