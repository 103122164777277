import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createTheme, MenuItem, Select, ThemeProvider, 
} from '@mui/material';
import styled from 'styled-components';
import { getBrands } from '../../../../redux/brand/brandActions';

const StyledSelect = styled(Select)({
  display: 'inline-flex',
  height: '32px',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
  borderRadius: '8px',
  background: '#3E4759',
  color: '#DAE2F9',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.1px',
  '& .MuiSelect-icon': {
    color: '#DAE2F9',
  },
  boxShadow: 'none',
  '.MuiOutlinedInput-notchedOutline': { border: 0 },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export function BrandsSelector({ selectedBrand, setSelectedBrand }) {
  const dispatch = useDispatch();

  const brands = useSelector((state) => state.brands);

  useEffect(() => {
    if (brands !== undefined && !brands.length) {
      dispatch(getBrands());
    } else {
      setSelectedBrand(brands[0]?._id);
    }
  }, [brands]);

  const handleChange = (event) => {
    setSelectedBrand(event.target.value);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <StyledSelect
        value={selectedBrand}
        onChange={handleChange}
        displayEmpty
      >
        {brands.map(({ _id, name }) => <MenuItem key={_id} value={_id}>{name}</MenuItem>)}
      </StyledSelect>
    </ThemeProvider>
  );
}
