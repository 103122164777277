export const smsServicesColumns = [
  {
    name: 'Description',
    field: 'description',
    selected: true,
    order: 1,
  },
  {
    name: 'Agent',
    field: 'user.assigned_to',
    selected: true,
    order: 2,
  },
  {
    name: 'Service Name',
    field: 'service_name',
    selected: true,
    order: 3,
  },
  {
    name: 'Status',
    field: 'is_active',
    selected: true,
    order: 4,
  },
  {
    name: 'Auth Data',
    field: 'auth_data',
    selected: true,
    order: 5,
  },
  {
    name: 'Additional Params',
    field: 'additional_params',
    selected: true,
    order: 6,
  },
  {
    name: 'Actions',
    field: '',
    selected: true,
    order: 7,
  },
];

