import { GET_LEADS_REPORT, GET_LEADS_REPORTS } from './leadsReportTypes';

const initialState = {
  leadsReportData: undefined,
  leadsReportsData: [],
  totalItems: 0,
  totalPages: 0,
  currentPage: 1,
};

const leadsReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LEADS_REPORT:
      return {
        ...state,
        ...action.payload,
      };
    case GET_LEADS_REPORTS:
      return {
        ...state,
        leadsReportsData: action.payload,
      };
    default:
      return state;
  }
};

export default leadsReportReducer;
