import { 
  DISPLAY_NOTIFICATIONS, 
  DISPLAY_UNREAD_NOTIFICATIONS, 
  ADD_NOTIFICATION, 
  REMOVE_READ_NOTIFICATION, 
  REMOVE_ALL_READ_NOTIFICATION, 
  GET_NOTIFICATION_MODULES, 
  NOTIFICATION_MODULES_STATE,
  DISPLAY_ALL_NOTIFICATIONS_BY_ID,
  MARK_NOTIFICATION_AS_SEEN,
  MARK_ALL_NOTIFICATIONS_AS_SEEN,
} from './notificationTypes';

const initialState = {
  notifications: [],
  unreadNotifications: [],
  allUserNotifications: [],
  unseenNotifications: [],
  notificationModules: [],
  fetchedNotificationModules: false,
  success: false,
  fetched: false,
  error: false,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case DISPLAY_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        unreadNotifications: action.payload,
        unseenNotifications: action.payload.filter((notification) => !notification.isSeen),
        success: false,
        fetched: true,
      };
    case DISPLAY_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload.notifications,
        success: false,
        fetched: true,
      };
    case DISPLAY_ALL_NOTIFICATIONS_BY_ID:
      return {
        ...state,
        allUserNotifications: action.payload,
      };
    case ADD_NOTIFICATION:
      if (!state.unreadNotifications.map(({ _id }) => _id).includes(action.payload?._id)) {
        state.unreadNotifications.unshift(action.payload);
        if (action.payload?.isSeen === false) {
          state.unseenNotifications.push(action.payload);
        }
      }
      return {
        ...state,
      };
    case REMOVE_READ_NOTIFICATION:
      return {
        ...state,
        unreadNotifications: state.unreadNotifications.filter((notif) => notif._id !== action.payload),
      };
    case REMOVE_ALL_READ_NOTIFICATION:
      return {
        ...state,
        unreadNotifications: action.payload,
      };
    case GET_NOTIFICATION_MODULES:
      return {
        ...state,
        notificationModules: action.payload,
        fetchedNotificationModules: true,
      };
    case NOTIFICATION_MODULES_STATE:
      return {
        ...state,
        fetchedNotificationModules: action.payload,
      };
    case MARK_NOTIFICATION_AS_SEEN:
      return {
        ...state,
        unreadNotifications: state.unreadNotifications.map((notification) => (action.payload.includes(notification._id)
          ? { ...notification, isSeen: true }
          : notification)),
        // Make sure to update the unseenNotifications array as well
        unseenNotifications: state.unseenNotifications.filter((notification) => !action.payload.includes(notification._id)),
      };
    case MARK_ALL_NOTIFICATIONS_AS_SEEN:
      return {
        ...state,
        unreadNotifications: state.unreadNotifications.map((notification) => ({ ...notification, isSeen: true })),
        unseenNotifications: state.unseenNotifications.map((notification) => ({ ...notification, isSeen: true })),
      };
    default:
      return state;
  }
};
export default notificationReducer;
