import { toast } from 'react-toastify';
import { apiHelper } from '../apiHelper';
import {
  GET_LEADS_REPORT, GET_LEADS_REPORTS,
} from './leadsReportTypes';

export const getLeadsReports = () => async (dispatch) => {
  try {
    const { data: { leadsReports } } = await apiHelper('get', '/api/leads-report/', '');

    if (leadsReports?.length) {
      dispatch({
        type: GET_LEADS_REPORTS,
        payload: leadsReports,
      });
    }
  } catch (error) {
    toast.error(error?.response?.message);
  }
};

export const getLeadsReport = (id, page = 1, limit = 10) => async (dispatch) => {
  try {
    const response = await apiHelper('get', `/api/leads-report/${id}`, '', { page, limit });

    if (response?.data) {
      dispatch({
        type: GET_LEADS_REPORT,
        payload: {
          leadsReportData: response.data.leadsReport,
          totalItems: response.data.totalItems,
          totalPages: response.data.totalPages,
          currentPage: response.data.currentPage,
        },
      });
    }
  } catch (error) {
    toast.error(error?.response?.message);
  }
};
