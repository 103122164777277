import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { editSaving, getSaving } from '../../redux/saving/savingActions';

function EditSaving() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [loader, setLoader] = useState(false);
  const saving = useSelector((state) => state.saving.saving);
  const [logo, setLogo] = useState(null);
  const [minDeposit, setMinDeposit] = useState(saving?.minDeposit || 0);
  const [options, setOptions] = useState([]);
  const [rateInUsd, setRateInUsd] = useState(saving?.customCurrency?.rates?.USD || 0);

  useEffect(() => {
    const fetchSaving = async () => {
      setLoader(true);
      await dispatch(getSaving(id));
      setLoader(false);
    };
    fetchSaving();
  }, [id, location, dispatch]);

  useEffect(() => {
    setMinDeposit(saving?.minDeposit || 0);
    if (saving?.customCurrency?.rates?.USD) {
      setRateInUsd(saving.customCurrency.rates.USD);
    }
    if (saving?.options) {
      setOptions(saving.options);
    }
  }, [saving]);

  const handleOptionChange = (id, value) => {
    setOptions((prevOptions) => prevOptions.map((option) => (option._id === id ? { ...option, percent: value } : option)));
  };

  const setNewValueToOptions = (formData = new FormData()) => {
    const options = saving.options.map((option) => {
      const optionValue = formData[option._id];
      return { ...option, percent: optionValue ? Number(optionValue) : option.percent };
    });
    
    return options;
  };

  const handleFileChange = (e) => {
    setLogo(e.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const formData = new FormData(event.target);
    if (logo) {
      formData.append('logo', logo);
    }
    const formValues = Object.fromEntries(formData.entries());
    setLoader(true);

    const data = {
      options: setNewValueToOptions(formValues),
      minDeposit: formValues.minDeposit || saving.minDeposit,
      rateInUsd: formValues?.rateInUsd,
    };

    const formDataToSend = new FormData();
    formDataToSend.append('data', JSON.stringify(data));
    formDataToSend.append('logo', logo);

    await dispatch(editSaving(id, formDataToSend));
    setLoader(false);

    history.goBack();
  };

  return (
    <div className="content-wrapper right-content-wrapper">
      <form onSubmit={handleSubmit}>
        <div className="content-box">
          <FontAwesomeIcon className="faArrowLeftIcon" icon={faArrowLeft} onClick={history.goBack} />
          <h3>{`Edit Settings For ${saving?.currency ? `${saving?.currency?.name} ${saving?.currency?.symbol}` : saving?.customCurrency?.name ?? ''} Saving`}</h3>
          {saving?.customCurrency && (
          <>
            <div className="form-group col-md-12">
              <label className="control-label">Rates (Currency to usd)</label>
              <input
                type="number"
                className="form-control"
                name="rateInUsd"
                value={rateInUsd}
                onChange={(e) => setRateInUsd(e.target.value)}
                min="0"
                step="0.000000001"
                placeholder="Enter worth of the currency to usd"
              />
            </div>
            <div className="form-group">
              <label>Logo</label>
              <input
                type="file"
                className="form-control"
                name="logo"
                accept="image/*"
                onChange={handleFileChange}
              />
            </div>
          </>
          ) }  
          <div className="form-group col-md-12">
            <label className="control-label">Min Deposit</label>
            <input
              type="number"
              className="form-control"
              name="minDeposit"
              value={minDeposit}
              onChange={(e) => setMinDeposit(e.target.value)}
            />
          </div>
          {options.map((option) => (
            <div className="form-group col-md-12 pt-2" key={option._id}>
              <label className="control-label">{option.label}</label>
              <input
                type="number"
                className="form-control"
                name={option._id}
                value={option.percent}
                onChange={(e) => handleOptionChange(option._id, e.target.value)}
              />
            </div>
          ))}
          <div>
            <button className="btn btn-default" type="submit">Save</button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditSaving;
