export const cfdAccountsColumns = [
  {
    name: 'ID',
    field: 'customId',
    selected: true,
    order: 1,
  },
  {
    name: 'Client',
    field: 'user.firstName,user.lastName',
    selected: true,
    order: 2,
  },
  {
    name: 'Email',
    field: 'user.email',
    selected: true,
    order: 3,
  },
  {
    name: 'Assigned To',
    field: 'user.manager.firstName,user.manager.firstName',
    selected: true,
    order: 4,
  },
  {
    name: 'Status',
    field: 'user.status.name',
    selected: true,
    order: 5,
  },
  {
    name: 'Type',
    field: 'type.name',
    selected: true,
    order: 6,
  },
  {
    name: 'Amount',
    field: 'total',
    selected: true,
    order: 7,
  },
  {
    name: 'Currency',
    field: 'cfdAccountCurrency.symbol',
    selected: true,
    order: 8,
  },
  {
    name: 'Credit',
    field: 'credit',
    selected: true,
    order: 9,
  },
  {
    name: 'Balance',
    field: 'balance',
    selected: true,
    order: 10,
  },
  {
    name: 'Leverage',
    field: 'leverage',
    selected: true,
    order: 11,
  },
  {
    name: 'Lot Step',
    field: 'lotStep',
    selected: true,
    order: 12,
  },
  {
    name: 'Lot Size',
    field: 'lotSize',
    selected: true,
    order: 13,
  },
  {
    name: 'Commission',
    field: 'commission',
    selected: true,
    order: 14,
  },
  {
    name: 'Swap Long',
    field: 'swapLong',
    selected: true,
    order: 15,
  },
  {
    name: 'Swap Short',
    field: 'swapShort',
    selected: true,
    order: 16,
  },
  {
    name: 'Margin',
    field: 'margin',
    selected: true,
    order: 17,
  },
  {
    name: 'Margin Level',
    field: 'marginLevel',
    selected: true,
    order: 18,
  },
  {
    name: 'Free Margin',
    field: 'freeMargin',
    selected: true,
    order: 19,
  },
  {
    name: 'Equity',
    field: 'equity',
    selected: true,
    order: 20,
  },
  {
    name: 'PnL',
    field: 'pnl',
    selected: true,
    order: 21,
  },
  {
    name: 'Created',
    field: 'cfdAccountCurrency.createdAt',
    selected: true,
    order: 22,
  },
  {
    name: 'Enabled',
    field: 'isEnabled',
    selected: true,
    order: 23,
  },
  {
    name: 'Actions',
    field: '',
    selected: true,
    order: 24,
  },
  {
    name: 'Second Email',
    field: 'user.secondEmail',
    selected: true,
    order: 25,
  },
];

