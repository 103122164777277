export const SHOW_ALL_CURRENCIES = 'SHOW_ALL_CURRENCIES';
export const SHOW_FOREX_CURRENCIES = 'SHOW_FOREX_CURRENCIES';
export const GET_CURRENCY = 'GET_CURRENCY';
export const ADD_CURRENCY = 'ADD_CURRENCY';
export const EDIT_CURRENCY = 'EDIT_CURRENCY';
export const DELETE_CURRENCY = 'DELETE_CURRENCY';
export const WALLET_CURRENCIES = 'WALLET_CURRENCIES';
export const TOGGLE_STATE = 'TOGGLE_STATE';
export const ERROR_STATE = 'ERROR_STATE';
export const FIAT_CURRENCY = 'FIAT_CURRENCY';
