import React, {
  useEffect, useState, useContext,
  useRef, 
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { SmtpPresetsColumns } from '../../DataTableSchemas/SmtpPresetsColumns';
import { smtpColumns } from '../../columnsDefaultConfig/smtpColumns';
import { ModalContext } from '../../context';
import { DatatableColumns, DatatableFilters } from '../../components/index';
import { useMultiDebounce } from '../../hooks/useMultiDebounce';
import {
  selectIsSmtpPresetsLoading, 
  selectSmtpAgentSearchFilter, 
  selectSmtpDescriptionSearchFilter, 
  selectSmtpEmailSearchFilter, 
  selectSmtpPasswordSearchFilter, 
  selectSmtpPresets, 
  selectSmtpProviderSearchFilter, 
  selectSmtpFromSearchFilter,
  selectTotalCount, 
} from '../../redux/smtpPresets/smtpPresetsSelectors';
import { 
  fetchSmtpPresets,
  setSmtpAgentFilterCreator,
  setSmtpProviderFilterCreator,
  setSmtpEmailFilterCreator,
  setSmtpPasswordFilterCreator,
  setSmtpDescriptionFilterCreator,
  exportSMTPToFile,
  setSmtpFromFilterCreator,
} from '../../redux/smtpPresets/smtpPresetsActions';
import { createCRMFilter, deleteCRMFilter, updateCRMFilter } from '../../redux/crmUser/crmUserActions';
import { CreateSmtpPreset } from './CreateSmtpPreset/CreateSmtpPreset';
import { generateSortParams } from '../../helpers/sortHelper';
import { DownloadFile } from '../../components/DownloadFile';
import { setNewOrderedColumns } from '../../DataTableSchemas/helper';


function SmtpPresets() {
  const dispatch = useDispatch();
  const { showModal, hideModal } = useContext(ModalContext);
  const { pathname } = useLocation();
  const datatableFiltersRef = useRef(null);

  const paginationStorageName = 'DataTable_smtpPresets/listing_pagination';
  const filtersStorageName = 'DataTable_smtpPresets/filters'; 
  const filterIdStorageName = 'DataTable_smtpPresets/filters_id';
  const columnsStorageName = 'DataTable_smtpPresets/columns';

  const smtpColumnsJSON = localStorage.getItem(columnsStorageName);
  const smtpStorageFiltersJSON = localStorage.getItem(filtersStorageName);

  // templates variables
  const smtpPresets = useSelector(selectSmtpPresets);
  const isSmtpPresetsLoading = useSelector(selectIsSmtpPresetsLoading);

  // filters variables
  const agentSearchFilter = useSelector(selectSmtpAgentSearchFilter);
  const providerSearchFilter = useSelector(selectSmtpProviderSearchFilter);
  const fromSearchFilter = useSelector(selectSmtpFromSearchFilter);
  const emailSearchFilter = useSelector(selectSmtpEmailSearchFilter);
  const passwordSearchFilter = useSelector(selectSmtpPasswordSearchFilter);
  const descriptionSearchFilter = useSelector(selectSmtpDescriptionSearchFilter);

  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const totalTableCount = useSelector(selectTotalCount);
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [sortParams, setSortParams] = useState(JSON.stringify({ createdAt: -1 }));
  const [columnConfig, setColumnConfig] = useState(smtpColumns);
  const [debounce, setDebounce] = useState(false);

  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const handleCreateBtn = () => {
    showModal({
      headerContent: <h3>Create SMTP configuration</h3>,
      bodyContent: <CreateSmtpPreset hideModal={hideModal} />,
    });
  };

  const setStoredPagination = () => {
    const ListingPaginationRowsJSON = localStorage.getItem(paginationStorageName);
    if (ListingPaginationRowsJSON) {
      const filterRows = JSON.parse(ListingPaginationRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
    }

    setIsPaginationDT(true);
  };

  const setStoredColumnsData = () => {
    if (smtpColumnsJSON) {
      const columns = JSON.parse(smtpColumnsJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  const setStoredFilterData = () => {
    if (smtpStorageFiltersJSON) {
      const {
        agent,
        description,
        email,
        password,
        provider, 
        from,
      } = JSON.parse(smtpStorageFiltersJSON);
  
      dispatch(setSmtpAgentFilterCreator(agent));
      dispatch(setSmtpProviderFilterCreator(provider));
      dispatch(setSmtpEmailFilterCreator(email));
      dispatch(setSmtpPasswordFilterCreator(password));
      dispatch(setSmtpDescriptionFilterCreator(description));
      dispatch(setSmtpFromFilterCreator(from));
    }
  };

  const handleClear = () => {
    dispatch(setSmtpAgentFilterCreator(''));
    dispatch(setSmtpProviderFilterCreator(''));
    dispatch(setSmtpEmailFilterCreator(''));
    dispatch(setSmtpPasswordFilterCreator(''));
    dispatch(setSmtpDescriptionFilterCreator(''));
    dispatch(setSmtpFromFilterCreator(''));

    localStorage.removeItem(filtersStorageName);
    localStorage.removeItem(filterIdStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  const storeColumnConfig = (config) => {
    setColumnConfig(config);
    localStorage.setItem(columnsStorageName, JSON.stringify(config));
  };

  const refresh = async () => {
    dispatch(fetchSmtpPresets(page, rowsPerPage, sortParams, {
      agent: agentSearchFilter,
      provider: providerSearchFilter,
      from: fromSearchFilter,
      email: emailSearchFilter,
      password: passwordSearchFilter,
      description: descriptionSearchFilter,
    }));
  };

  const setCRMFilters = (filter) => {
    const {
      accountId: agent,
      extension: provider,
      email,
      phone: password,
      description, 
      from,
    } = filter;

    dispatch(setSmtpAgentFilterCreator(agent));
    dispatch(setSmtpProviderFilterCreator(provider));
    dispatch(setSmtpEmailFilterCreator(email));
    dispatch(setSmtpPasswordFilterCreator(password));
    dispatch(setSmtpDescriptionFilterCreator(description));
    dispatch(setSmtpFromFilterCreator(from));

    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);

    const data = {
      name,
      crmUserId,
      pathname,
      description: filters.description ?? '',
      accountId: filters.agent ?? '',
      email: filters.email ?? '',
      extension: filters.provider ?? '',
      phone: filters.password ?? '',
      from: filters.from ?? '',
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const handleExportUser = async (fileType) => {
    const columns = columnConfig.filter(({ selected, field }) => selected && field);

    const storageFilters = localStorage.getItem(filtersStorageName);
    const filters = JSON.parse(storageFilters);

    if (smtpPresets.length && columns.length) {
      const { roleId, _id: userId } = JSON.parse(localStorage.getItem('user'));

      const data = {
        roleId,
        columns,
        filters: {
          description: filters.description ?? '',
          accountId: filters.agent ?? '',
          email: filters.email ?? '',
          extension: filters.provider ?? '',
          phone: filters.password ?? '',
          from: filters.from ?? '',
        }, 
        fileType,
        clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      toast.success('Export in progress. Please wait.', {
        autoClose: 1000,
      });
      dispatch(exportSMTPToFile(data));
    } else {
      toastError('There is nothing to download.');
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        description: filters.description ?? '',
        from: filters.from ?? '',
        accountId: filters.agent ?? '',
        email: filters.email ?? '',
        extension: filters.provider ?? '',
        phone: filters.password ?? '',
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toastError('Select at least one filter to complete this action.');
    }
  };

  const handleRowsPerPageChange = (currentRowsPerPage) => {
    setRowsPerPage(currentRowsPerPage);
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage }));
  };

  const handlePageChange = (page) => setPage(page);

  useEffect(() => {
    localStorage.setItem(filtersStorageName, JSON.stringify({
      agent: agentSearchFilter,
      provider: providerSearchFilter,
      from: fromSearchFilter,
      email: emailSearchFilter,
      password: passwordSearchFilter,
      description: descriptionSearchFilter,
    }));
    if (isPaginationDT) {
      dispatch(fetchSmtpPresets(page, rowsPerPage, sortParams, {
        agent: agentSearchFilter,
        provider: providerSearchFilter,
        from: fromSearchFilter, 
        email: emailSearchFilter,
        password: passwordSearchFilter,
        description: descriptionSearchFilter,
      }));
      setDebounce(true);
    }
  }, [page, rowsPerPage, sortParams, isPaginationDT, agentSearchFilter]);

  useMultiDebounce([
    providerSearchFilter, 
    fromSearchFilter,
    emailSearchFilter, 
    passwordSearchFilter,
    descriptionSearchFilter,
  ], 300, () => {
    localStorage.setItem(filtersStorageName, JSON.stringify({
      agent: agentSearchFilter,
      provider: providerSearchFilter,
      from: fromSearchFilter,
      email: emailSearchFilter,
      password: passwordSearchFilter,
      description: descriptionSearchFilter,
    }));
    if (
      providerSearchFilter !== null 
      || emailSearchFilter !== null 
      || passwordSearchFilter !== null 
      || descriptionSearchFilter !== null
      || fromSearchFilter !== null
    ) {
      if (debounce) {
        dispatch(fetchSmtpPresets(page, rowsPerPage, sortParams, {
          agent: agentSearchFilter,
          provider: providerSearchFilter,
          from: fromSearchFilter,
          email: emailSearchFilter,
          password: passwordSearchFilter,
          description: descriptionSearchFilter,
        }));
      }
    }
  });

  useEffect(() => {
    setStoredPagination();
    setStoredColumnsData();
    setStoredFilterData();
  }, []);

  const handleSort = async (sortField, sortDirection) => {
    const sort = generateSortParams(sortField, sortDirection);
    setSortParams(sort);
  };

  const SmtpPresetsCol = SmtpPresetsColumns({ columnConfig, handleSort });

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>SMTP Configuration</h3>
        <div className="action__btn-row">
          <div className="main_btn-row">
            <div className="secondary_btn-row">
              <button 
                type="button" 
                className="btn-primary_light"
                onClick={handleCreateBtn}
              >
                <FontAwesomeIcon icon={faPlus} size="sm" />
                Add New
              </button>
            </div>
            <div className="secondary_btn-row">
              {crmFilters && (
                <DatatableFilters 
                  ref={datatableFiltersRef}
                  filters={crmFilters} 
                  setFilters={setCRMFilters}
                  createFilter={createUserCRMFilter}
                  deleteFilter={deleteUserCRMFilter}
                  updateFilter={updateUserCRMFilter}
                  storageKey={filterIdStorageName}
                  pathname={pathname}
                />
              )}

              <button type="button" className="btn-secondary_dark iconed" onClick={handleClear}>
                <CloseOutlinedIcon sx={{ fontSize: '20px' }} />
              </button>
              <button type="button" className="btn-secondary_dark iconed" onClick={refresh}>
                <RefreshIcon sx={{ fontSize: '20px' }} />
              </button>
            </div>

            <div className="secondary_btn-row">
              <DatatableColumns setColumns={storeColumnConfig} columnConfig={columnConfig} defaultConfig={smtpColumns} />
            </div>
            <DownloadFile handleExport={handleExportUser} />
          </div>
        </div>
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
          {
            isPaginationDT
              && (
                <DataTable
                  columns={SmtpPresetsCol}
                  data={smtpPresets}
                  pagination
                  highlightOnHover
                  paginationServer
                  paginationTotalRows={totalTableCount}
                  paginationPerPage={rowsPerPage}
                  paginationRowsPerPageOptions={[25, 50, 100, 500]}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  onChangePage={handlePageChange}
                  onColumnOrderChange={(newColumns) => setNewOrderedColumns(
                    newColumns,
                    columnConfig,
                    storeColumnConfig,
                  )}
                  persistTableHead
                  selectableRows
                  selectableRowsVisibleOnly
                  theme="solarizedd"
                  progressPending={isSmtpPresetsLoading}
                  progressComponent={<div className="datatable-loader__background" />}
                />
              )
          }
        </div>
        <br />
      </div>
    </div>
  );
}

export default SmtpPresets;
