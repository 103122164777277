import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClone, faExternalLink, faTrash, faPencil, 
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { ModalContext } from '../../context';
import { toFixed } from '../../helpers/utils';
import { RangeModalDebounceFilter } from '../../pages/Transactions/modalFilters';
import { ModalFilters } from '../../components';
import {
  omitColumn, 
  formatDate, 
  dateOptions, 
  prettyCutOutputByCellWidth, 
  valueOutputCutLength,  
  sortColumnByOrderConfig,
} from '../helper';
import { TooltipComponent } from '../../components/TooltipComponent/TooltipComponent';
import { useTableSorting, SortWrapper } from '../../helpers/sortHelper';
import SortIcon from '../../components/SortIcon';
import EditAccountModal from '../../pages/Users/UserDetailComponents/Accounts/EditAccountModal';
import './style.css';

export const CfdAccountsSchema = (
  columnConfig,
  amountRange,
  setAmountRange,
  cfdQuery,
  setCfdQuery,
  currencies,
  assetFilter,
  setAssetFilter,
  creditRange,
  setCreditRange,
  permissionName,
  balanceRange,
  setBalanceRange,
  setDebounceAmountRange,
  customIdFilter, 
  setCustomIdFilter,
  fullNameFilter, 
  setFullNameFilter,
  handleDeleteCfdAccounts,
  accountTypes,
  crmUsers,
  assignedToFilter,
  setAssignedToFilter,
  salesStatuses,
  statusFilter,
  setStatusFilter,
  createdAtFilter,
  setCreatedAtFilter,
  orderData,
  handleSort,
  emailFilter,
  secondEmailFilter,
  setEmailFilter,
  setSecondEmailFilter,
) => {
  const { showModal } = useContext(ModalContext);
  const isUserCanEditCFDAccount = permissionName.includes('cfd_accounts_edit');
  const isUserCanDeleteCFDAccount = permissionName.includes('cfd_accounts_delete');

  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };

  const calcMargin = (orders = []) => {
    if (!orders.length) return 0;

    const totalMargin = orders.reduce((acc, order) => {
      const margin = Number(orderData[order._id]?.margin || order.margin || 0);
      return margin + acc;
    }, 0);

    return toFixed(totalMargin, 2);
  };

  const calcPnL = (orders = []) => {
    if (!orders.length) return 0;

    const totalPnl = orders.reduce((acc, order) => {
      const pnl = Number(orderData[order._id]?.pnl || order.pnl || 0);
      return pnl + acc;
    }, 0);

    return toFixed(totalPnl, 2);
  };

  const styles = {
    positive: { color: 'green' },
    negative: { color: 'red' },
    default: {},
  };

  const { sortFields, getSortIcon } = useTableSorting(handleSort);
  
  const columns = [
    {
      name: (
        <div className="cfdAccount_idWrapper">
          <div style={{ display: 'flex', width: '100%' }}>
            <SortWrapper handleSort={() => sortFields('customId')}>
              <span>ID</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('customId')} />
              </button>
            </SortWrapper>
          </div>
          <input 
            type="text"
            placeholder="Search"
            autoComplete="off"
            className="cfdAccount_input"
            name="id"
            value={customIdFilter || ''}
            onChange={({ target: { value } }) => setCustomIdFilter(value)}
          />
          <span className="cfdAccount_errorInputWrapper" />
        </div>
      ),
      minWidth: '150px',
      omit: omitColumn(columnConfig, 'ID'), 
      reorder: true,
      label: 'ID',
      cell: ({ customId }) => (
        customId.length >= valueOutputCutLength.id
          ? (
            <TooltipComponent
              title={customId}
              // eslint-disable-next-line react/no-children-prop
              children={(
                <CopyToClipboard text={customId}>
                  <span className="cfdSccounts-row_Id">
                    <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                    { customId }
                  </span>
                </CopyToClipboard>
              )}
            />
          )
          : (
            <CopyToClipboard text={customId}>
              <span className="cfdSccounts-row_Id">
                <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                { customId }
              </span>
            </CopyToClipboard>
          )
      ),
    },
    {
      name: (
        <div className="cfdAccount_idWrapper">
          <SortWrapper handleSort={() => sortFields('userFirstName userLastName')}>
            <div style={{ display: 'flex', width: '100%' }}>
              <span>Client</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('userFirstName userLastName')} />
              </button>
            </div>
          </SortWrapper>
          <input 
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="client"
            className="cfdAccount_input"
            value={fullNameFilter || ''}
            onChange={({ target: { value } }) => setFullNameFilter(value)}
          /> 
        </div>
      ),
      minWidth: '180px',
      omit: omitColumn(columnConfig, 'Client'), 
      reorder: true,
      label: 'Client',
      cell: ({
        userId, userFirstName = '', userLastName = '', 
      }) => { 
        if (userFirstName && userLastName) {
          const userFullName = `${userFirstName} ${userLastName}`; 

          return (
            <div className="cfdSccounts-row_ClientWrapper">
              { 
               userFullName.length >= valueOutputCutLength.clientWithdrawal
                 ? (
                   <TooltipComponent
                     title={userFullName}
                   // eslint-disable-next-line react/no-children-prop
                     children={(
                       <Link to={`/user-detail/${userId}`} target="blank" className="text-white">
                         <FontAwesomeIcon icon={faExternalLink} size="xs" />
                         <span 
                           className="cfdSccounts-row_fullName"
                           style={{ whiteSpace: 'nowrap' }}
                         >
                           { prettyCutOutputByCellWidth(userFullName, 'clientWithdrawal') }
                         </span>
                       </Link>
                   )}
                   />
                 )
                 : (
                   <Link to={`/user-detail/${userId}`} target="blank" className="text-white">
                     <FontAwesomeIcon icon={faExternalLink} size="xs" />
                     <span 
                       className="cfdSccounts-row_fullName"
                       style={{ whiteSpace: 'nowrap' }}
                     >
                       {userFullName}
                     </span>
                   </Link>
                 )
            }
            </div>
          );
        }
        
        return <span className="cfdSccounts-row_fullName">-</span>;
      },
    },
    {
      name: (
        <div className="cfdAccount_idWrapper">
          <SortWrapper handleSort={() => sortFields('userEmail')}>
            <div style={{ display: 'flex', width: '100%' }}>
              <span>Email</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('userEmail')} />
              </button>
            </div>
          </SortWrapper>
          <input 
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="email"
            className="cfdAccount_input"
            value={emailFilter || ''}
            onChange={({ target: { value } }) => setEmailFilter(value)}
          />
        </div>
      ),
      minWidth: '180px',
      omit: omitColumn(columnConfig, 'Email'), 
      reorder: true,
      label: 'Email',
      cell: ({ userEmail = '', userId }) => { 
        if (userEmail) {
          return (
            <div className="cfdSccounts-row_ClientWrapper">
              { 
               userEmail.length >= valueOutputCutLength.clientWithdrawal
                 ? (
                   <TooltipComponent
                     title={userEmail}
                   // eslint-disable-next-line react/no-children-prop
                     children={(
                       <Link to={`/user-detail/${userId}`} target="blank" className="text-white">
                         <FontAwesomeIcon icon={faExternalLink} size="xs" />
                         <span 
                           className="cfdSccounts-row_fullName"
                           style={{ whiteSpace: 'nowrap' }}
                         >
                           { prettyCutOutputByCellWidth(userEmail, 'clientWithdrawal') }
                         </span>
                       </Link>
                   )}
                   />
                 )
                 : (
                   <Link to={`/user-detail/${userId}`} target="blank" className="text-white">
                     <FontAwesomeIcon icon={faExternalLink} size="xs" />
                     <span 
                       className="cfdSccounts-row_fullName"
                       style={{ whiteSpace: 'nowrap' }}
                     >
                       {userEmail}
                     </span>
                   </Link>
                 )
            }
            </div>
          );
        }
        
        return <span className="cfdSccounts-row_fullName">-</span>;
      },
    },
    {
      name: (
        <div className="cfdAccount_idWrapper">
          <SortWrapper handleSort={() => sortFields('userSecondEmail')}>
            <div style={{ display: 'flex', width: '100%' }}>
              <span>Second Email</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('userSecondEmail')} />
              </button>
            </div>
          </SortWrapper>
          <input 
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="secondEmail"
            className="cfdAccount_input"
            value={secondEmailFilter || ''}
            onChange={({ target: { value } }) => setSecondEmailFilter(value)}
          />
        </div>
      ),
      reorder: true,
      label: 'Second Email',
      minWidth: '180px',
      omit: omitColumn(columnConfig, 'Second Email'), 
      cell: ({ userSecondEmail = '', userId }) => (
        <div className="cfdAccounts-row_ClientWrapper">
          { 
           userSecondEmail.length >= valueOutputCutLength.secondEmail
             ? (
               <TooltipComponent
                 title={userSecondEmail}
                 // eslint-disable-next-line react/no-children-prop
                 children={(
                   <Link to={`/user-detail/${userId}`} target="blank" className="text-white">
                     <FontAwesomeIcon icon={faExternalLink} size="xs" />
                     <span 
                       className="cfdSccounts-row_fullName"
                       style={{ whiteSpace: 'nowrap' }}
                     >
                       { prettyCutOutputByCellWidth(userSecondEmail, 'secondEmail') }
                     </span>
                   </Link>
               )}
               />
             ) : userSecondEmail.length === 0 ? <span className="cfdSccounts-row_fullName">-</span> : (
               <Link to={`/user-detail/${userId}`} target="blank" className="text-white">
                 <FontAwesomeIcon icon={faExternalLink} size="xs" />
                 <span 
                   className="cfdSccounts-row_fullName"
                   style={{ whiteSpace: 'nowrap' }}
                 >
                   {userSecondEmail}
                 </span>
               </Link>
             )
        }
        </div>
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('userManagerFirstName userManagerLastName')}>
            <div className="d-flex flex-row">
              <span>Assigned To</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('userManagerFirstName userManagerLastName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Assigned To" 
            data={crmUsers} 
            filters={assignedToFilter} 
            setFilters={setAssignedToFilter}
            searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
            usersFilters={cfdQuery}
            setUserFilters={setCfdQuery}
            addNonSelected
          />
        </div>
      ),
      omit: omitColumn(columnConfig, 'Assigned To'), 
      reorder: true,
      label: 'Assigned To',
      minWidth: '180px',
      cell: ({ userManagerId = '', userManagerFirstName = '', userManagerLastName = '' }) => {
        if (userManagerFirstName && userManagerLastName) {
          const fullNameManager = `${userManagerFirstName} ${userManagerLastName}`;
          return (
            <div className="cfdSccounts-row_ClientWrapper">
              { fullNameManager.length >= valueOutputCutLength.assignedTo
                ? (
                  <TooltipComponent
                    title={fullNameManager}
                    // eslint-disable-next-line react/no-children-prop
                    children={(
                      <Link to={`/edit-admin/${userManagerId}`} target="blank" className="text-white">
                        <FontAwesomeIcon icon={faExternalLink} size="xs" />
                        <span 
                          className="cfdSccounts-row_fullName"
                        >
                          { prettyCutOutputByCellWidth(fullNameManager, 'assignedTo') }
                        </span>
                      </Link>
                    )}
                  />
                )
                : (
                  <Link to={`/edit-admin/${userManagerId}`} target="blank" className="text-white">
                    <FontAwesomeIcon icon={faExternalLink} size="xs" />
                    <span 
                      className="cfdSccounts-row_fullName"
                    >
                      {fullNameManager}
                    </span>
                  </Link>
                )}
            </div>
          );
        }
          
        return <span className="cfdSccounts-row_fullName">-</span>;
      },
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('userStatusName')}>
            <div className="d-flex flex-row">
              <span>Status</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('userStatusName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Status" 
            data={salesStatuses} 
            filters={statusFilter} 
            setFilters={setStatusFilter} 
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={cfdQuery}
            setUserFilters={setCfdQuery}
            addNonSelected
          />
        </div>
      ),
      omit: omitColumn(columnConfig, 'Status'), 
      reorder: true,
      label: 'Status',
      minWidth: '140px',
      cell: ({ userStatusName = '' }) => {
        if (userStatusName) {
          return (
            userStatusName.length >= valueOutputCutLength.managerStatus
              ? (
                <TooltipComponent
                  title={userStatusName}
                // eslint-disable-next-line react/no-children-prop
                  children={(
                    <span 
                      className="cfdSccounts-row_fullName"
                    >
                      { prettyCutOutputByCellWidth(userStatusName, 'managerStatus') }
                    </span>
                )}
                />
              )
              : (
                <span 
                  className="cfdSccounts-row_fullName"
                >
                  {userStatusName}
                </span>
              )
          );
        }
        return <span className="cfdSccounts-row_fullName">-</span>;
      },
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('cfdAccountTypeName')}>
            <div className="d-flex flex-row">
              <span>Type</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('cfdAccountTypeName')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters 
            tabName="Type" 
            data={accountTypes} 
            filters={assetFilter} 
            setFilters={setAssetFilter}
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={cfdQuery}
            setUserFilters={setCfdQuery}
            addNonSelected
          />
        </div>
      ),
      omit: omitColumn(columnConfig, 'Type'), 
      reorder: true,
      label: 'Type',
      minWidth: '90px',
      cell: ({ cfdAccountTypeName }) => cfdAccountTypeName,
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('total')}>
            <div className="d-flex flex-row">
              <span>Total Amount</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('total')} />
              </button>
            </div>
          </SortWrapper>
          <RangeModalDebounceFilter
            value={amountRange ?? [0, 1000000]} 
            setValue={setAmountRange} 
            setFilters={setDebounceAmountRange} 
            filters={{}} 
            filedName="amountRange"
            debounceAmountRange
          />
        </div>
      ),
      minWidth: '160px',
      reorder: true,
      label: 'Amount',
      omit: omitColumn(columnConfig, 'Amount'), 
      cell: ({ total, balance, credit }) => {
        const calculatedTotal = total || balance + credit;

        return calculatedTotal?.toFixed(2).toString().length >= valueOutputCutLength.netDeposits
          ? (
            <TooltipComponent
              title={calculatedTotal?.toFixed(2).toString()}
                  // eslint-disable-next-line react/no-children-prop
              children={(
                <span 
                  className="cfdSccounts-row_fullName"
                >
                  { prettyCutOutputByCellWidth(calculatedTotal?.toFixed(2).toString(), 'netDeposits') }
                </span>
                )}
            />
          )
          : (
            <span 
              className="cfdSccounts-row_fullName"
            >
              { calculatedTotal?.toFixed(2) }
            </span>
          );
      },
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('balance')}>
            <div className="d-flex flex-row">
              <span>Balance</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('balance')} />
              </button>
            </div>
          </SortWrapper>
          <RangeModalDebounceFilter 
            value={balanceRange ?? [0, 1000000]} 
            setValue={setBalanceRange} 
            setFilters={setDebounceAmountRange} 
            filters={{}} 
            filedName="balanceRange"
            debounceAmountRange
          />
        </div>
      ),
      minWidth: '110px',
      omit: omitColumn(columnConfig, 'Balance'), 
      reorder: true,
      label: 'Balance',
      cell: ({ balance }) => (
        balance.toFixed(2).toString()?.length >= valueOutputCutLength.balance 
          ? (
            <TooltipComponent 
              title={balance.toFixed(2).toString()}
               // eslint-disable-next-line react/no-children-prop
              children={(
                <span style={{ cursor: 'pointer' }}>
                  {prettyCutOutputByCellWidth(balance.toFixed(2).toString(), 'balance')}
                </span>
              )}
            />
          )
          : (
            <span>
              {balance.toFixed(2).toString()}
            </span>
          )
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('currencySymbol')}>
            <div className="d-flex flex-row">
              <span>Currency</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('currencySymbol')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Currency" 
            data={currencies} 
            filters={assetFilter} 
            setFilters={setAssetFilter} 
            searchParams={{ id: '_id', params: ['name', '_id', 'symbol'], optionsName: ['symbol'] }}
            usersFilters={cfdQuery}
            setUserFilters={setCfdQuery}
          />
        </div>
      ),
      omit: omitColumn(columnConfig, 'Currency'), 
      reorder: true,
      label: 'Currency',
      minWidth: '120px',
      cell: ({ currencySymbol, isSaving, currency }) => (!isSaving ? currencySymbol : currency?.symbol),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('credit')}>
            <div className="d-flex flex-row">
              <span>Credit</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('credit')} />
              </button>
            </div>
          </SortWrapper>
          <RangeModalDebounceFilter
            value={creditRange ?? [0, 1000000]} 
            setValue={setCreditRange} 
            setFilters={setCfdQuery} 
            filters={cfdQuery}
            filedName="creditRange"
          />
        </div>
      ),
      minWidth: '140px',
      omit: omitColumn(columnConfig, 'Credit'), 
      reorder: true,
      label: 'Credit',
      cell: ({ credit, currencySymbol, isSaving }) => {
        if (!isSaving) {
          return (
            credit?.toFixed(2).toString()?.length >= valueOutputCutLength.credit 
              ? (
                <TooltipComponent 
                  title={credit?.toFixed(2).toString()}
                 // eslint-disable-next-line react/no-children-prop
                  children={(
                    <span style={{ cursor: 'pointer' }}>
                      {prettyCutOutputByCellWidth(`${credit?.toFixed(2)} ${currencySymbol}`, 'credit')}
                    </span>
                )}
                />
              )
              : (
                <span>
                  {`${credit?.toFixed(2)} ${currencySymbol}`}
                </span>
              )
          );
        }
        return <span className="cfdSccounts-row_fullName">-</span>;
      },
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('leverage')}>
          <div className="d-flex flex-row">
            <span>Leverage</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('leverage')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '120px',
      selector: ({ leverage }) => (leverage || '-'), 
      omit: omitColumn(columnConfig, 'Leverage'), 
      reorder: true,
      label: 'Leverage',
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('lotStep')}>
          <div className="d-flex flex-row">
            <span>Lot Step</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('lotStep')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '110px',
      selector: ({ lotStep }) => (lotStep || '-'), 
      omit: omitColumn(columnConfig, 'Lot Step'),
      reorder: true,
      label: 'Lot Step', 
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('lotSize')}>
          <div className="d-flex flex-row">
            <span>Lot Size</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('lotSize')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '110px',
      selector: ({ lotSize }) => (lotSize || '-'),
      omit: omitColumn(columnConfig, 'Lot Size'),
      reorder: true,
      label: 'Lot Size',  
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('commission')}>
          <div className="d-flex flex-row">
            <span>Commission%</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('commission')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '140px',
      selector: ({ commission }) => (commission || '-'), 
      omit: omitColumn(columnConfig, 'Commission'), 
      reorder: true,
      label: 'Commission',  
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('swapLong')}>
          <div className="d-flex flex-row">
            <span>Swap Long%</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('swapLong')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '140px',
      selector: ({ swapLong }) => (swapLong || '-'), 
      omit: omitColumn(columnConfig, 'Swap Long'), 
      reorder: true,
      label: 'Swap Long',  
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('swapShort')}>
          <div className="d-flex flex-row">
            <span>Swap Short%</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('swapShort')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '145px',
      selector: ({ swapShort }) => (swapShort || '-'), 
      omit: omitColumn(columnConfig, 'Swap Short'), 
      reorder: true,
      label: 'Swap Short',  
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('accountMargin')}>
          <div className="d-flex flex-row">
            <span>Margin</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('accountMargin')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '110px',
      selector: ({ orders }) => {
        const margin = calcMargin(orders);
        const spanStyle = margin !== 0 ? (margin > 0 ? styles.positive : styles.negative) : styles.default;
        return (
          margin?.toString()?.length >= valueOutputCutLength.margin 
            ? (
              <TooltipComponent 
                title={margin?.toString()}
                // eslint-disable-next-line react/no-children-prop
                children={(
                  <span style={spanStyle}>
                    {prettyCutOutputByCellWidth(margin, 'margin')}
                  </span>
                )}
              />
            )
            : (
              <span style={spanStyle}>
                {margin}
              </span>
            )
        );
      }, 
      omit: omitColumn(columnConfig, 'Margin'), 
      reorder: true,
      label: 'Margin',  
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('marginLevel')}>
          <div className="d-flex flex-row">
            <span>Margin Level</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('marginLevel')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '130px',
      selector: ({ orders, balance, credit }) => {
        const margin = calcMargin(orders);
        const pnl = calcPnL(orders);
        const equity = Number(balance) + pnl + Number(credit);
        const marginLevel = margin !== 0 ? (equity / margin) * 100 : 0;
        const spanStyle = marginLevel !== 0 ? (marginLevel > 0 ? styles.positive : styles.negative) : styles.default;
        return (
          toFixed(marginLevel, 2).toString().length >= valueOutputCutLength.margin 
            ? (
              <TooltipComponent 
                title={toFixed(marginLevel, 2).toString()}
              // eslint-disable-next-line react/no-children-prop
                children={(
                  <span style={spanStyle}>
                    {`${prettyCutOutputByCellWidth(toFixed(marginLevel, 2).toString(), 'margin')} %`}
                  </span>
          )}
              />
            )
            : (
              <span style={spanStyle}>
                {`${toFixed(marginLevel, 2).toString()} %`}
              </span>
            )
        );
      }, 
      omit: omitColumn(columnConfig, 'Margin Level'), 
      reorder: true,
      label: 'Margin Level', 
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('freeMargin')}>
          <div className="d-flex flex-row">
            <span>Free Margin</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('freeMargin')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '130px',
      selector: ({ orders, balance, credit }) => {
        const margin = calcMargin(orders);
        const pnl = calcPnL(orders);
        const equity = Number(balance) + pnl + Number(credit);
        const freeMargin = equity - margin;
        
        const spanStyle = freeMargin !== 0 ? (freeMargin > 0 ? styles.positive : styles.negative) : styles.default;
        return (
          toFixed(freeMargin, 2).toString().length >= valueOutputCutLength.margin 
            ? (
              <TooltipComponent 
                title={toFixed(freeMargin, 2).toString()}
                // eslint-disable-next-line react/no-children-prop
                children={(
                  <span style={spanStyle}>
                    {prettyCutOutputByCellWidth(toFixed(freeMargin, 2).toString(), 'margin')}
                  </span>
                )}
              />
            )
            : (
              <span style={spanStyle}>
                {toFixed(freeMargin, 2)}
              </span>
            )
        );
      }, 
      omit: omitColumn(columnConfig, 'Free Margin'),
      reorder: true,
      label: 'Free Margin',  
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('equity')}>
          <div className="d-flex flex-row">
            <span>Equity</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('equity')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '130px',
      selector: ({ orders, balance, credit }) => {
        const pnl = calcPnL(orders);
        const equity = Number(balance) + pnl + Number(credit);
        
        const spanStyle = equity !== 0 ? (equity > 0 ? styles.positive : styles.negative) : styles.default;
        return (
          toFixed(equity, 2).toString().length >= valueOutputCutLength.margin 
            ? (
              <TooltipComponent 
                title={toFixed(equity, 2).toString()}
                // eslint-disable-next-line react/no-children-prop
                children={(
                  <span style={spanStyle}>
                    {prettyCutOutputByCellWidth(toFixed(equity, 2).toString(), 'margin')}
                  </span>
                )}
              />
            )
            : (
              <span style={spanStyle}>
                {toFixed(equity, 2)}
              </span>
            )
        );
      }, 
      omit: omitColumn(columnConfig, 'Equity'), 
      reorder: true,
      label: 'Equity',  
    },
    {
      name: (
        <SortWrapper handleSort={() => sortFields('accountPnl')}>
          <div className="d-flex flex-row">
            <span>Pnl</span>
            <button type="button" className="sort-btn">
              <SortIcon image={getSortIcon('accountPnl')} />
            </button>
          </div>
        </SortWrapper>
      ),
      minWidth: '130px',
      selector: ({ orders }) => {
        const pnl = calcPnL(orders);
        
        const spanStyle = pnl !== 0 ? (pnl > 0 ? styles.positive : styles.negative) : styles.default;
        return (
          toFixed(pnl, 2).toString().length >= valueOutputCutLength.margin 
            ? (
              <TooltipComponent 
                title={toFixed(pnl, 2).toString()}
                // eslint-disable-next-line react/no-children-prop
                children={(
                  <span style={spanStyle}>
                    {prettyCutOutputByCellWidth(toFixed(pnl, 2).toString(), 'margin')}
                  </span>
                )}
              />
            )
            : (
              <span style={spanStyle}>
                {toFixed(pnl, 2)}
              </span>
            )
        );
      }, 
      omit: omitColumn(columnConfig, 'PnL'), 
      reorder: true,
      label: 'PnL',  
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('createdAt')}>
            <div className="d-flex flex-row">
              <span>Created</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('createdAt')} />
              </button>
            </div>
          </SortWrapper>
          <ModalFilters
            tabName="Created" 
            onClick={(e) => e.stopPropagation()}
            data={dateOptions} 
            filters={createdAtFilter} 
            setFilters={setCreatedAtFilter} 
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={cfdQuery}
            setUserFilters={setCfdQuery}
            isDateOptions
          />
        </div>
      ),
      minWidth: '154px',
      omit: omitColumn(columnConfig, 'Created'),
      reorder: true,
      label: 'Created',  
      selector: ({ createdAt }) => (
        <span>{formatDate(new Date(createdAt))}</span>
      ),
    },
    {
      name: (
        <div className="d-flex flex-row">
          <SortWrapper handleSort={() => sortFields('isEnabled')}>
            <div className="d-flex flex-row">
              <span>Enabled</span>
              <button type="button" className="sort-btn">
                <SortIcon image={getSortIcon('isEnabled')} />
              </button>
            </div>
          </SortWrapper>
        </div>
      ),
      minWidth: '154px',
      omit: omitColumn(columnConfig, 'Enabled'),
      reorder: true,
      label: 'Enabled',  
      selector: ({ isEnabled = true }) => (
        <span>{isEnabled ? 'Yes' : 'No'}</span>
      ),
    },
    {
      name: 'Action(s)',
      minWidth: '180px',
      omit: omitColumn(columnConfig, 'Actions'), 
      reorder: true,
      label: 'Actions',  
      cell: (row) => ((
        <>
          {
            isUserCanEditCFDAccount && (
              <button
                type="button"
                className="btn btn-success btn-sm me-1 p-1"
                onClick={() => showModal({
                  headerContent: <h3>{`Edit Account #${row.customId}`}</h3>,
                  bodyContent: <EditAccountModal row={row} />,
                })}
              >
                <FontAwesomeIcon icon={faPencil} className="header-icon" />
              </button>
            )
          }
          {
            isUserCanDeleteCFDAccount && (
              <button
                type="button"
                className="btn btn-danger btn-sm me-1 p-1"
                onClick={() => handleDeleteCfdAccounts(row)}
              >
                <FontAwesomeIcon icon={faTrash} className="header-icon" />
              </button>
            )
          }
        </>

      )),
    },
  ];

  return sortColumnByOrderConfig(columns, columnConfig);
};
