import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import ReactFlagsSelect from 'react-flags-select';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ENV } from '../../../config/config';
import { getSalesStatuses } from '../../../redux/salesStatus/salesStatusActions';
import { getSubCRMUsers } from '../../../redux/crmUser/crmUserActions';
import { displayClientRoles } from '../../../redux/roles/roleActions';
import { getBrands } from '../../../redux/brand/brandActions';
import { RegisterUser, updateAuthState } from '../../../redux/auth/authActions';
import './add-user-to-modal.css';
import { SALES_ROLES, SALES_STATUSES } from '../../AffiliatesDashboard/utils/optionsHelper';

export function AddUserModal({ hideModal, userType, refresh }) {
  const dispatch = useDispatch();
  const registered = useSelector((state) => state.auth?.registered);
  const error = useSelector((state) => state.auth?.error);
  const crmUsers = useSelector((state) => state.crmUser?.crmUsers);
  const brands = useSelector((state) => state.brands);
  const [selectedFlagCountry, setSelectedFlagCountry] = useState('');
  const [brandId, setBrandId] = useState('');
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const salesStatuses = useSelector((state) => state?.salesStatus?.salesStatuses);
  const allAffiliates = useSelector((state) => state.affiliate.affiliates);
  const [salesStatusType, setSalesStatusType] = useState({ value: '', color: '#fff' });
  const [selectedAffiliate, setAffiliate] = useState({ _id: '' });

  const userSalesStatuses = userType === SALES_ROLES.RETENTION ? [SALES_STATUSES.RETENTION, SALES_STATUSES.BOTH] : [SALES_STATUSES.CONVERSION, SALES_STATUSES.BOTH];

  const filteredSalesStatuses = useMemo(
    () => salesStatuses.filter((status) => userSalesStatuses.includes(status.type) && status.brandId === brandId),
    [userSalesStatuses, brandId],
  );

  useEffect(() => {
    if (brands.length !== 0 && brandId === '') {
      setBrandId(brands[0]._id);
    }
  }, [brands]);

  const [defaultManager, setDefaultManager] = useState(null);
  const [managers, setManagers] = useState([]);

  const { register, handleSubmit, formState: { errors } } = useForm();

  useEffect(() => {
    const intiEvent = async () => {
      const loginData = localStorage.getItem('user');
      const data = JSON.parse(loginData);
      const id = data?.roleId;
      const uid = data?._id;

      await dispatch(getSalesStatuses());
      await dispatch(getSubCRMUsers(id, uid));
      await dispatch(displayClientRoles());
      dispatch(getBrands());
    };
    intiEvent();
  }, []);

  useEffect(() => {
    const initManagetData = async () => {
      if (crmUsers?.length > 0) {
        const allManagers = await crmUsers?.map((manage) => ({ label: `${manage.firstName} ${manage.lastName}`, value: manage._id }));
        setManagers(allManagers);
      }
    };
    initManagetData();
  }, [crmUsers]);

  const addUser = {
    firstname: {
      required: 'First name is required',
    },
    lastname: {
      required: 'Last name is required',
    },
    email: {
      required: 'Email is required',
      pattern: {
        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'Please enter a valid email',
      },
    },
    phone: {
      required: 'Phone number is required',
      pattern: {
        value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
        message: 'Please enter a valid contact number',
      },
    },
    password: {
      required: 'Password is required',
      minLength: {
        value: 6,
        message: 'Password must have at least 6 characters',
      },
    },
    dateOfBirth: {
      required: 'Date of birth is required',
    },
  };

  const handleAddUser = (formData) => {
    const selectedAffiliateId = selectedAffiliate?._id ? selectedAffiliate?._id : null;
    const selectedAffiliateData = allAffiliates.find((aff) => selectedAffiliateId === aff._id);

    setLoader(true);
    const data = {
      firstName: formData.firstname,
      lastName: formData.lastname,
      username: formData.username,
      email: formData.email,
      phone: formData.phone,
      password: formData.password,
      dateOfBirth: formData.dateOfBirth,
      clientType: userType,
      countryCode: selectedFlagCountry,
      salesStatusId: salesStatusType?.value ? salesStatusType?.value : null,
      affialiateId: selectedAffiliateId,
      affiliateFullName: `${selectedAffiliateData?.firstName} ${selectedAffiliateData?.lastName}`,
      isVerified: true,
      status: true,
      assignedTo: defaultManager?.value,
      brandId: brandId || null,
    };
    dispatch(RegisterUser(data));
  };

  useEffect(async () => {
    if (registered) {
      setLoader(false);
      await refresh();
      hideModal();
      // history.goBack();
    }
    dispatch(updateAuthState());
  }, [registered]);

  useEffect(() => {
    if (error) {
      setLoader(false);
      dispatch(updateAuthState());
    }
  }, [error]);

  const handleUserStatusChange2 = (e) => {
    setSalesStatusType({ value: e.target.value, color: e.target[e.target.selectedIndex].getAttribute('color') });
  };

  const handleUserAffiliate = (e) => {
    setAffiliate({ _id: e.target.value });
  };

  const handleDefaultManagerChange = (selectedDefaultManager) => {
    setDefaultManager(selectedDefaultManager);
  };

  const colourStyles = {
    control: (styles, { isSelected }) => ({
      ...styles,
      background: '#374057',
      color: '#fff',
      border: '1px solid #374057',
      boxShadow: isSelected ? 'none' : 'none',
      borderColor: isSelected ? '#374057' : '#374057',
      '&:hover': {
        boxShadow: 'none',
      },
    }),
    input: (styles) => ({
      ...styles,
      color: '#fff',
    }),
    singleValue: (styles) => ({
      ...styles,
      color: '#fff',
    }),
    menuList: (styles) => ({
      ...styles,
      background: '#374057',
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? '#16202e'
        : isSelected
          ? '#16202e'
          : undefined,
      color: '#fff',
      cursor: 'pointer',
      zIndex: 1,
      '&:hover': {
        background: '#16202e',
      },
    }),
  };

  return (
    <>
      <div className="row mb-3">
        <div className="form-group col-md-12">
          <label className="control-label">First Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter First name"
            {...register('firstname', addUser.firstname)}
            name="firstname"
          />
          {errors?.firstname && <span className="errMsg">{errors.firstname.message}</span>}
        </div>
        <div className="form-group col-md-12 pt-2">
          <label className="control-label">Last Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Last name"
            {...register('lastname', addUser.lastname)}
            name="lastname"
          />
          {errors?.lastname && <span className="errMsg">{errors.lastname.message}</span>}
        </div>
        <div className="select-row">
          {/* <div className="form-group col-md-6 pt-2">
            <label className="control-label">Select User Type</label>
            <Select
              value={selectedUserType}
              onChange={handleUserTypeChange}
              options={userTypeOptions}
              styles={colourStyles}
            />
          </div> */}
          <div className="form-group col-md-6 pt-2">
            <label className="control-label">Select User Status</label>
            <select
              className="form-control user-status-select"
              name="type"
              value={salesStatusType?.value}
              onChange={handleUserStatusChange2}
              style={{ color: salesStatusType?.color ? salesStatusType?.color : '#fff' }}
            >
              <option value="" style={{ color: '#fff' }} color="#fff">Select Status</option>
              {filteredSalesStatuses?.map((currentStatus) => (
                <option
                  value={currentStatus?._id}
                  key={currentStatus?._id}
                  style={{ color: currentStatus?.color }}
                  color={currentStatus?.color}
                >
                  {currentStatus?.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="select-row">
          <div className="form-group col-md-6 pt-2">
            <label className="control-label">Select User affiliate</label>
            <select
              className="form-control user-status-select"
              name="type"
              value={selectedAffiliate?._id}
              onChange={handleUserAffiliate}
            >
              <option value="">Select Affiliate</option>
              {allAffiliates?.length > 0 && allAffiliates?.map((aff) => (
                <option key={aff?._id} value={aff?._id}>
                  {`${aff?.firstName} ${aff?.lastName}`}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="select-row">
          <div className="form-group col-md-6 pt-2">
            <label className="control-label">Select Brand</label>
            <select
              className="form-control user-status-select"
              name="brand"
              value={brandId}
              onChange={({ target: { value } }) => setBrandId(value)}
            >
              {brands?.length && brands.map((brand) => (
                <option value={brand._id} key={brand._id}>
                  {brand.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group col-md-6 pt-2">
            <label htmlFor="exampleFormControlInput1" className="form-label">Default Managers</label>
            <Select
              value={defaultManager}
              onChange={handleDefaultManagerChange}
              options={managers}
              styles={colourStyles}
            />
          </div>
        </div>
        <div className="form-group col-md-12 pt-2">
          <label className="control-label">Email</label>
          <input
            type="email"
            className="form-control"
            placeholder="Enter Email"
            {...register('email', addUser.email)}
            name="email"
          />
          {errors?.email && <span className="errMsg">{errors.email.message}</span>}
        </div>
        <div className="form-group col-md-12 pt-2">
          <label className="control-label">Country</label>
          <ReactFlagsSelect
            selected={selectedFlagCountry}
            onSelect={(code) => setSelectedFlagCountry(code)}
            searchable
            searchPlaceholder="Search for a country"
            className="admin-country-react-flags-select"
          />
        </div>
        <div className="form-group col-md-12 pt-2  dob">
          <label className="control-label">Select Date Of Birth</label>
          <input
            type="date"
            className="form-control"
            max={new Date().toISOString().split('T')[0]}
            min="1800-01-01"
            placeholder="Type date of birth..."
            name="dateOfBirth"
            {...register('dateOfBirth', addUser.dateOfBirth)}
          />
          {errors?.dateOfBirth && <span className="errMsg">{errors.dateOfBirth.message}</span>}
        </div>
        <div className="form-group col-md-12 pt-2">
          <label className="control-label">Phone Number</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Phone number"
            name="phone"
            {...register('phone', addUser.phone)}
          />
          {errors?.phone && <span className="errMsg">{errors.phone.message}</span>}
        </div>
        <div className="form-group col-md-12 pt-2">
          <label className="control-label">Password</label>
          <input
            type="password"
            className="form-control"
            placeholder="Enter Password"
            {...register('password', addUser.password)}
            name="password"
          />
          {errors?.password && <span className="errMsg">{errors.password.message}</span>}
        </div>
      </div>
      <div>
        <button type="button" className="btn btn-default me-2" onClick={handleSubmit(handleAddUser)}>
          { !loader ? 'Save' : <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" /> }
        </button>
        <button type="button" className="btn btn-danger" onClick={hideModal}>Cancel</button>
      </div>
    </>
  );
}
